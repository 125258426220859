import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import {withRouter} from 'react-router-dom'
import * as $ from 'jquery';

import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';
import _ from 'lodash';
import Datepicker from 'react-datepicker';

import moment from 'moment';
//import dateFormat from "dateformat";

import FeatureArea from '../../../components/FeatureArea/FeatureArea';
import FooterNavigation from '../../../components/FooterNavigation/FooterNavigation';

//import TenantAddrTypeAutoSuggest from "../../../components/TenantAutoSuggest/TenantAddrTypeAutoSuggest";
import TenantCompanyTypeAutoSuggest from '../../../components/TenantAutoSuggest/TenantCompanyTypeAutoSuggest';

import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/Button/ButtonGroup';
import InputNewComponent from '../../../components/Input/InputNew';

//import DetailListWithOverlay from "../../../components/DetailList/DetailListWithOverlay";

import * as serviceModulesActions from '../../../store/ServiceModules/actions';

import * as moduleSelectors from '../../../store/ServiceModules/reducer';

//import * as meetingAction from "../../../store/Meetings/actions";
//import * as meetingSelector from "../../../store/Meetings/reducer";

import * as visitorSelector from '../../../store/Visitors/reducer';

import '../../../sass/containers/visitors/index.css';
import * as configHelpers from '../../../helpers/configHelper';
import * as visitorActions from '../../../store/Visitors/actions';
import * as meetingActions from '../../../store/Meetings/actions';
import * as meetingSelectors from '../../../store/Meetings/reducer';
import * as settingSelector from '../../../store/ConciergeSetting/reducer';

function generateOptions(length, excludedOptions) {
	const arr = [];
	for (let value = 0; value < length; value++) {
		if (excludedOptions.indexOf(value) < 0) {
			arr.push(value);
		}
	}
	return arr;
}
function disabledMinutes(h) {
	var min_arr = [ 0 ];
	for (let value = 0; value < 60; value++) {
		let curr_min = value + 5;
		if (curr_min < 60 && curr_min % 5 === 0) {
			min_arr.push(curr_min);
		}
	}
	switch (h) {
		default:
			return generateOptions(60, min_arr);
	}
}

const PortalTypes = serviceModulesActions.getPortalTypes();

const StatusMessages = {
	Booked: 'Visitors successfully booked',
	Updated: 'Changes Successfully Saved',
	UpdatedList: 'Visitor updated'
};

const ActionButtonText = {
	CheckIn: 'Check-in',
	CheckOut: 'Check-out'
};


class VisitorProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			timeFrom: moment(),
			timeTo: moment().add(1, 'hours'),
			checkedInDate: moment(),
			checkedInTime: moment(),
			checkedOutTime: moment(),
			now: moment().format('LLLL'),
			type: 'Visitors',
			name: '',
			suggestions: [],
			meetingId: '',
			tenantId: undefined,
			tenantCompany: '',
			tenantName: '',
			tenantAddress: '',
			tenantType: '',
			addVisitorFormUsed: false,
			visitorName: '',
			visitorCompany: '',
			visitorEmail: '',
			specialInstruction: '',
			visitorStatus: '',
			visitorActionText: 'Check-in',
			commercial: false,
			portalType:
				this.props.setting && !_.isUndefined(this.props.setting.portalType)
					? this.props.setting.portalType
					: '',
			IsFormEditable: true,
			IsOverlayAppear: false,
			message: ''
		};

		autoBind(this);
	}

	handleEditInfoClick() {
		console.log();
		if (this.state.IsFormEditable === true) {
			this.setState({ IsFormEditable: !this.state.IsFormEditable, message: '' });
		} else {
			if (this.state.addVisitorFormValid && this.state.addVisitorFormValid === true)
				this.setState({ IsFormEditable: !this.state.IsFormEditable, message: '' });
		}
	}

	componentDidMount() {
		//redirect to management page if page load data is invalid or not available
		if (
			_.isUndefined(this.props.location.state) ||
			_.isUndefined(this.props.location.state.meetingId) ||
			_.isUndefined(this.props.location.state.meeting)
		) {
			this.props.history.push({
				pathname: '/concierge/visitor-management'
			});
		}
		//initial propfile data initialized from page redirected here
		//state values would be maped to view component
		if (!_.isUndefined(this.props.location.state) && !_.isUndefined(this.props.location.state.meeting)) {
			let meeting = this.props.location.state.meeting;
			let actionText = '';
			if (meeting.visitor.status === configHelpers.VisitorStatuses.Notified) {
				actionText = ActionButtonText.CheckIn;
			} else if (meeting.visitor.status === configHelpers.VisitorStatuses.CheckedIn) {
				actionText = ActionButtonText.CheckOut;
			}

			let data = {
				meetingId: meeting.id,
				tenantId: meeting.tenant.id,
				tenantCompany: meeting.tenant.companyName,
				visitorName: `${meeting.visitor.firstName} ${meeting.visitor.surname}`,
				visitorCompany: meeting.visitor.companyName ? meeting.visitor.companyName : '',
				visitorEmail: meeting.visitor.email ? meeting.visitor.email : '',
				specialInstruction: meeting.specialInstructions ? meeting.specialInstructions : '',
				startDate: moment(meeting.date),
				timeFrom: moment(meeting.start),
				timeTo: moment(meeting.end),
				checkedInDate: moment(meeting.visitor.dueDate),
				checkedInTime: moment(meeting.visitor.dueDate),
				checkedOutTime: moment(meeting.visitor.checkedOutDate),
				visitorStatus: meeting.visitor.status,
				visitorActionText: actionText
			};

			this.setState({ ...data });

			let searchData = { ...this.props.searchResult, meetingId: data.meetingId };
			searchData.searchText = this.props.location.state.searchText;
			this.props.dispatch(visitorActions.storeVisitorSearch(searchData));
		}

		this.addValidation();
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.props.setting !== this.props.setting && !_.isUndefined(this.props.setting)) {
			this.setState({
				portalType: this.props.setting.portalType
			});
		}
		if (this.props.meeting !== prevProps.meeting && !_.isUndefined(this.props.meeting)) {
			this.MapDataToControls(this.props.meeting);
			if (this.props.searResult) {
				let updateData = _.map(this.props.searchResult.data, (res) => {
					if (res.id === this.props.meeting.id) {
						return this.props.meeting;
					}

					return res;
				});

				let data = {
					search_date: this.props.searchResult.search_date,
					data: updateData,
					meetingId: this.state.meetingId
				};

				this.props.dispatch(visitorActions.storeVisitorSearch(data));
			}

			/**send checked/arrived email notification */
			if (
				this.props.meeting.visitor &&
				this.props.meeting.visitor.status === configHelpers.VisitorStatuses.CheckedIn
			) {
				this.props.dispatch(
					meetingActions.notifyCheckedIn([ this.props.meeting ], (status) => {
						if (status === true) {
						}
					})
				);
			}
		}
		
	}

	MapDataToControls(meeting) {
		//let meeting = this.props.location.state.meeting;
		let actionText = '';
		if (meeting.visitor.status === configHelpers.VisitorStatuses.Notified) {
			actionText = ActionButtonText.CheckIn;
		} else if (meeting.visitor.status === configHelpers.VisitorStatuses.CheckedIn) {
			actionText = ActionButtonText.CheckOut;
		}

		let data = {
			meetingId: meeting.id,
			tenantId: meeting.tenant.id,
			tenantCompany: meeting.tenant.companyName,
			visitorName: `${meeting.visitor.firstName} ${meeting.visitor.surname}`,
			visitorCompany: meeting.visitor.companyName ? meeting.visitor.companyName : '',
			visitorEmail: meeting.visitor.email,
			specialInstruction: meeting.specialInstructions,
			startDate: moment(meeting.date),
			timeFrom: moment(meeting.start),
			timeTo: moment(meeting.end),
			checkedInDate: moment(meeting.visitor.dueDate),
			checkedInTime: moment(meeting.visitor.dueDate),
			checkedOutTime: moment(meeting.visitor.checkedOutDate),
			visitorStatus: meeting.visitor.status,
			visitorActionText: actionText
		};

		this.setState({ ...data });

		this.props.history.push({
			pathname: '/concierge/visitor-profile',
			state: {
				meetingId: meeting.id,
				meeting: meeting
			}
		});
	}
	handleVisitorAction() {
		const { visitorStatus, visitorActionText, meetingId } = this.state;
		let data = [ { id: meetingId } ];
		if (
			visitorActionText === ActionButtonText.CheckIn &&
			visitorStatus === configHelpers.VisitorStatuses.Notified
		) {
			this.props.dispatch(
				visitorActions.checkinVisitor(data, (success) => {
					if (success === true) {
						this.props.dispatch(meetingActions.getMeetingById(meetingId));
					}
				})
			);
		} else if (
			visitorActionText === ActionButtonText.CheckOut &&
			visitorStatus === configHelpers.VisitorStatuses.CheckedIn
		) {
			this.props.dispatch(
				visitorActions.checkOutVisitor(data, (success) => {
					if (success === true) {
						this.props.dispatch(meetingActions.getMeetingById(meetingId));
					}
				})
			);
		}
		this.setState({
			IsOverlayAppear: false
		});
	}

	validate(fromSubmit = false) {
		this.addVisitorValidation.validate({ force: true });

		const valid = this.addVisitorValidation.isValid();
		this.setState({
			addVisitorFormValid: valid,
			addVisitorFormUsed: true
		});

		if (valid === true && fromSubmit === true) {
			let meeting = this.props.location.state.meeting;

			const visitorName = this.state.visitorName;
			const element = visitorName.split(' ');

			let visitorFirstName = '',
				visitorSurName = '';
			if (element.length > 1) {
				for (var i = 0; i < element.length; i++) {
					if (element[i] != '') {
						if (i == 0) visitorFirstName = element[i];
						else visitorSurName += element[i] + ' ';
					}
				}
			}

			let data = {
				id: meeting.id,
				visitorId: meeting.visitorId,
				memberId: this.state.tenantId,
				date: this.state.startDate,
				start: this.state.timeFrom,
				end: this.state.timeTo,
				specialInstructions: this.state.specialInstruction,
				visitor: {
					id: meeting.visitor.id,
					firstName: element.length > 1 ? visitorFirstName : '',
					surname: element.length > 1 ? visitorSurName : '',
					email: this.state.visitorEmail,
					companyName: this.state.visitorCompany,
					meetingId: meeting.id
				}
			};

			this.props.dispatch(
				meetingActions.updateMeeting(data, (success) => {
					if (success === true) {
						//this.props.dispatch(meetingActions.setMettingsQueue([]));
						// this.formReset();
						this.setState({
							message: StatusMessages.Updated,
							IsFormEditable: true
						});
					}
				})
			);
		}
	}

	addValidation() {
		this.addVisitorValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});

		// this.addDatepickerValidation = $(this.refs.validate).parsley({
		//   classHandler: function(el) {
		//     return el.$element.closest("input");
		//   }
		// });

		// this.addVisitorValidation = $("pickeryDate").parsley({
		//   classHandler: function(el) {
		//     return el.$element.closest("input");
		//   }
		// });
	}

	handleCancelClick() {
		const { meetingId } = this.state;
		if (!this.state.IsFormEditable) {
			this.MapDataToControls(this.props.location.state.meeting);
			this.setState({
				IsFormEditable: true
			});
		} else {
			this.props.dispatch(visitorActions.visitorSearchRedirected(true));
			let orderdMeetingsBySearch = _.filter(this.props.searchResult.data, (orderdMeetingsByTime) => {
				return orderdMeetingsByTime.id === meetingId;
			});

			let updatedResults, data;
			if (
				moment(this.props.searchResult.search_date).format('YYYY-MM-DD') !==
				moment(this.state.startDate).format('YYYY-MM-DD')
			) {
				updatedResults = _.filter(this.props.searchResult.data, (orderdMeetingsByTime) => {
					return orderdMeetingsByTime.id !== meetingId;
				});
				data = {
					search_date: this.props.searchResult.search_date,
					data: updatedResults,
					searchText: '',
					meetingId: meetingId
				};
				this.props.dispatch(visitorActions.storeVisitorSearch(data));
			} else data = this.props.searchResult;
			this.props.history.push({
				pathname: '/concierge/visitor-management',
				// state: { module: "Visitors" }
				state: {
					startDate: this.props.searchResult
						? moment(this.props.searchResult.search_date).format('YYYY-MM-DD')
						: moment(),
					searchResult: data,
					meetingId: meetingId
				}
			});
			window.scrollTo(0, 0);
		}
	}

	handleInputChange(e) {
		const field = e.target.name;
		const value = e.target.value;
		this.setState({
			[field]: value,
			message: '',
			isSuccessfull: undefined
		});
	}

	handleTimeFromChange(val) {
		let checkMins = 23 * 60 + 59;

		let toTime = moment(val).add(1, 'hours');

		if (toTime.hours() === 0) {
			let today = moment(val).toDate();
			let maxEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate());

			maxEnd.setMinutes(checkMins);

			toTime = moment(maxEnd);
		}

		this.setState({
			timeFrom: val,
			timeTo: toTime
		});
	}

	handleTimeToChange(val) {
		this.setState({
			timeTo: val
		});
	}

	handleDateChange(date) {
		const { timeFrom, timeTo, meetingId } = this.state;
		const { searchResult } = this.props;

		let time_from = moment(date.format('YYYY-MM-DD' + ' ' + timeFrom.format('HH:mm')));
		let time_to = moment(date.format('YYYY-MM-DD' + ' ' + timeTo.format('HH:mm')));

		if (searchResult) {
			let updateMeeting = _.map(searchResult.data, (o) => {
				if (o.id === meetingId) {
					return { ...o, date: date.toDate(), start: time_from.toDate(), end: time_to.toDate() };
				}
				return o;
			});

			let data = {
				search_date: searchResult.search_date,
				data: updateMeeting,
				searchText: searchResult.searchText,
				meetingId: meetingId
			};
			this.props.dispatch(visitorActions.storeVisitorSearch(data));
		}

		this.setState({
			startDate: date,
			timeFrom: time_from,
			timeTo: time_to
		});
	}

	handleCheckin() {
		this.setState({
			IsOverlayAppear: true
		});
	}

	handleNoClick() {
		this.setState({
			IsOverlayAppear: false
		});
	}

	render() {
		const {
			tenantCompany,
			startDate,
			tenant,
			timeFrom,
			timeTo,
			visitorName,
			visitorEmail,
			visitorCompany,
			tenantId,
			visitorActionText,
			specialInstruction,
			checkedInDate,
			checkedInTime,
			checkedOutTime
		} = this.state;
		const { tenants } = this.props;
		const currentModule = _.find(this.props.modules, (o) => o.title === 'Visitors');

		const overlayColor = currentModule && currentModule.imageOverlayTextColour;
		// const visitor_style = {
		//     color: overlayColor,
		//     fontSize: "80px",
		//     textTransform: "capitalize"
		// }

		var now = new Date();
		const EditButton_style_checkout = {
			display: 'none'
		};

		const EditButton_style_others = {
			display: 'block'
		};

		return (
			<div>
				<div className="container type-concierge-desktop management">
					<FeatureArea
						type={this.state.type}
						areaTitle="Visitors"
						areaTagline="Welcome, select a service"
						pageHeader="Visitor Info"
						overlayColor={currentModule && currentModule.imageOverlayTextColour}
						landing={false}
						activeMenu={true}
					/>

					<form
						id="form-content"
						className="visitor-wrapper vistior-profile"
						ref="validate"
						onSubmit={(e) => {
							e.preventDefault();
						}}
						data-parsley-errors-container="#validation-messages"
						data-parsley-focus="none"
						data-parsley-validate-if-empty
						//data-parsley-inputs
					>
						<div className="talent">
							<div className="tenant_elements">
								<label className="dateLabel visitor-label">Visitor Name</label>
								<InputNewComponent
									type="text"
									name="visitorName"
									placeHolder="Enter visitor name"
									value={visitorName}
									handleChange={(e) => this.handleInputChange(e)}
									parsley={{
										'data-parsley-pattern': "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
										'data-parsley-minlength': 2,
										'data-parsley-required': true,
										'data-parsley-minwords': '2',
										'data-parsley-error-message': 'Please enter visitor first and the last name'
									}}
									disabled={this.state.IsFormEditable}
								/>
							</div>
						</div>

						<div className="talent">
							<div className="tenant_elements">
								<label className="dateLabel visitor-label">Visitor Company</label>
								<InputNewComponent
									type="text"
									name="visitorCompany"
									placeHolder={
										this.state.IsFormEditable ? '' : 'Enter visitor company name if applicable'
									}
									value={visitorCompany}
									handleChange={(e) => this.handleInputChange(e)}
									disabled={this.state.IsFormEditable}
									// parsley={{
									//     "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
									//    // "data-parsley-minlength": 2,
									//     // "data-parsley-required": true,
									//     "data-parsley-minwords": "2",
									//     "data-parsley-error-message":
									//         "Please enter company name in correct format"
									// }}
								/>
							</div>
						</div>

						<div className="visitorEmail">
							<label className="visitor-label"> Visitor Email</label>
							<InputNewComponent
								type="text"
								name="visitorEmail"
								placeHolder={this.state.IsFormEditable ? '' : 'Enter visitor email if known'}
								value={visitorEmail}
								handleChange={(e) => this.handleInputChange(e)}
								disabled={this.state.IsFormEditable}
								parsley={{
									'data-parsley-type': 'email',
									'data-parsley-validate-if-empty': false,
									'data-parsley-error-message': 'Please enter a valid email'
								}}
								// parsley={{
								//     "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
								//    // "data-parsley-minlength": 2,
								//     //"data-parsley-required": true,
								//     "data-parsley-minwords": "2",
								//     "data-parsley-error-message":
								//         "Please enter email in the correct format"
								// }}
							/>
						</div>

						{this.state.portalType === PortalTypes.Commercial ? (
							<div className="names">
								<div className="tenant_elements">
									<TenantCompanyTypeAutoSuggest
										ref="tenant-auto-suggest"
										label="Tenant Name"
										labelText="Tenant"
										disabled={this.state.IsFormEditable}
										arrowHide={this.state.IsFormEditable}
										defaultTenantId={Number(tenantId)}
										onSelected={(data) => {
											this.setState({
												tenantId: data.memberId,
												tenantName: data.tenant,
												tenantCompany: data.companyName,
												tenantType: data.memberType
											});

											/* Timeout added otherwise validation not fired because autosuggest still updating DOM */
											setTimeout(() => {
												this.state.addVisitorFormUsed && this.validate();
											}, 0);
										}}
									/>
								</div>

								<div className="company_elements">
									<div className="company-div">
										<InputNewComponent
											type="text"
											name="tenantCompany"
											label="Tenant Company"
											placeHolder="Company"
											value={tenantCompany}
											handleChange={(e) => this.handleInputChange(e)}
											parsley={{
												//"data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
												//"data-parsley-minlength": 2,
												'data-parsley-minwords': '1',
												'data-parsley-required': true,
												'data-parsley-error-message': 'Please enter a valid company name'
											}}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="talent">
								<div className="tenant_elements">
									<TenantCompanyTypeAutoSuggest
										ref="tenant-auto-suggest"
										label="Tenant Name"
										labelText="Tenant"
										disabled={this.state.IsFormEditable}
										arrowHide={this.state.IsFormEditable}
										defaultTenantId={Number(tenantId)}
										onSelected={(data) => {
											this.setState({
												tenantId: data.memberId,
												tenantName: data.tenant,
												tenantCompany: data.companyName,
												tenantType: data.memberType
											});

											/* Timeout added otherwise validation not fired because autosuggest still updating DOM */
											setTimeout(() => {
												this.state.addVisitorFormUsed && this.validate();
											}, 0);
										}}
									/>
								</div>
							</div>
						)}
						{this.state.visitorStatus !== configHelpers.VisitorStatuses.CheckedOut && (
							<div
								className="checkin-visitor-button"
								style={
									this.state.visitorStatus === configHelpers.VisitorStatuses.Notified &&
									!(moment(now).utc().format('L') === this.state.startDate.utc().format('L')) ? (
										EditButton_style_checkout
									) : (
										EditButton_style_others
									)
								}
							>
								<div className="">
									<Button
										text={visitorActionText}
										size="medium"
										isDisabled={
											!this.state.IsFormEditable ||
											moment(this.state.startDate.utc()).format('YYYY-MM-DD') !==
												moment().utc().format('YYYY-MM-DD')
										}
										handleClick={this.handleCheckin}
									/>
								</div>
							</div>
						)}

						{this.state.IsOverlayAppear ? (
							<div className="firstSlide ">
								<div className="visitor">
									<span className="title">Do you wish to {visitorActionText} this visitor?</span>
									<span className="header">
										{this.state.visitorActionText === 'Check-out' ? '' : 'Special Instruction'}
									</span>
									<span className="action-btn">
										<Button
											size="small"
											text="Yes"
											handleClick={() => this.handleVisitorAction()}
										/>
									</span>
								</div>
								<div className="specials">
									<span className="title">{visitorName}</span>
									<span className="detail">
										{' '}
										{this.state.visitorActionText === 'Check-out' ? '' : specialInstruction}{' '}
									</span>
									<span className="action-btn">
										<Button size="small" text="No" handleClick={this.handleNoClick} />
									</span>
								</div>
							</div>
						) : (
							''
						)}

						<div className="date_elements">
							<div className="dates">
								<label className="dateLabel visitor-label">Expected Date</label>
								{/* <InputNewComponent
                                    type="datePicker"
                                    ref="datePickery"
                                    selected={startDate}
                                    minDate={moment()}
                                    dateFormat="DD/MM/YYYY"
                                    placeholder="Date "
                                    dateValue={
                                        _.isUndefined(startDate) || startDate === null
                                            ? moment().format("DD/MM/YYYY")
                                            : startDate.format("DD/MM/YYYY")
                                    }
                                    name={this.state.IsFormEditable ? "datePickCheckin" : ""}
                                    disabled={this.state.IsFormEditable}
                                // {...this.props.parsley}
                                /> */}

								<Datepicker
									type="datePicker"
									ref="datePickery"
									fixedHeight={true}
									selected={startDate}
									minDate={moment()}
									onChange={this.handleDateChange}
									dateFormat="DD/MM/YYYY"
									placeholder="Date "
									dateValue={
										_.isUndefined(startDate) || startDate === null ? (
											moment().utc().format('DD/MM/YYYY')
										) : (
											startDate.utc().format('DD/MM/YYYY')
										)
									}
									name="datePick"
									disabled={this.state.IsFormEditable}
								/>
							</div>
							<div className="times">
								<div>
									{
										<InputNewComponent
											id="timeFrom"
											type="timePicker"
											icon={this.state.IsFormEditable ? '' : 'ion-chevron-down'}
											defaultValue={startDate}
											name={this.state.IsFormEditable ? 'timeFromCheckin' : ''}
											handleTimeChange={this.handleTimeFromChange}
											placeholder="From"
											disabledMinutes={disabledMinutes}
											label="From"
											dateValue={
												_.isUndefined(timeFrom) || timeFrom === null ? (
													moment().utc()
												) : (
													moment(timeFrom).utc()
												)
											}
											parsley={{
												'data-parsley-pattern': '/^[A-Za-z ]+$/',
												'data-parsley-minlength': 5,
												'data-parsley-required': true,
												'data-parsley-minwords': '1',
												'data-parsley-error-message': 'Please enter a valid time'
											}}
											disabled={this.state.IsFormEditable}
										/>
									}
								</div>

								<div>
									<InputNewComponent
										type="timePicker"
										icon={this.state.IsFormEditable ? '' : 'ion-chevron-down'}
										defaultValue={startDate}
										handleTimeChange={this.handleTimeToChange}
										name="timeTo"
										placeholder="To"
										disabledMinutes={disabledMinutes}
										label="To"
										disabledHours={this.disabledHours}
										dateValue={
											_.isUndefined(timeTo) || timeTo === null || timeTo < timeFrom ? (
												moment(timeFrom).utc()
											) : (
												moment(timeTo).utc()
											)
										}
										disabled={this.state.IsFormEditable}
										// parsley={{
										//   "data-parsley-pattern": "/^[A-Za-z ]+$/",
										//   "data-parsley-minlength": 1,
										//   "data-parsley-required": true,
										//   "data-parsley-minwords": "1",
										//   "data-parsley-gte": 100000000000,
										//   "data-parsley-error-message": "Please enter a valid time"
										// }}
									/>
								</div>
							</div>
						</div>

						{this.state.visitorActionText === 'Check-out' ||
						this.state.visitorStatus === configHelpers.VisitorStatuses.CheckedOut ? (
							<div className="date_elements checkIn-time">
								<div className="dates">
									<label className="dateLabel visitor-label">Arrival Date</label>
									<Datepicker
										type="datePicker"
										ref="datePickery"
										selected={checkedInDate}
										minDate={moment()}
										onChange={this.handleDateChange}
										dateFormat="DD/MM/YYYY"
										placeholder="Date "
										dateValue={
											_.isUndefined(checkedInDate) || checkedInDate === null ? (
												moment().format('DD/MM/YYYY')
											) : (
												checkedInDate.format('DD/MM/YYYY')
											)
										}
										name="datePick"
										disabled={true}
									/>
								</div>
								<div className="times">
									<div>
										{
											<InputNewComponent
												id="timeFrom"
												type="timePicker"
												defaultValue={checkedInTime}
												name="timeFromCheckin"
												placeholder="From"
												disabledMinutes={disabledMinutes}
												label="Check-in Time"
												dateValue={
													_.isUndefined(checkedInTime) || checkedInTime === null ? (
														moment()
													) : (
														moment(checkedInTime).utc()
													)
												}
												parsley={{
													'data-parsley-pattern': '/^[A-Za-z ]+$/',
													'data-parsley-minlength': 5,
													'data-parsley-required': true,
													'data-parsley-minwords': '1',
													'data-parsley-error-message': 'Please enter a valid time'
												}}
												disabled={true}
											/>
										}
									</div>

									<div>
										{moment(checkedOutTime).format('YYYY-MM-DD') !== '0001-01-01' ? (
											<InputNewComponent
												type="timePicker"
												defaultValue={checkedOutTime}
												handleTimeChange={this.handleTimeToChange}
												name="timeTo"
												placeholder="To"
												disabledMinutes={disabledMinutes}
												label="Check-out Time"
												disabledHours={this.disabledHours}
												dateValue={
													this.state.visitorStatus ===
													configHelpers.VisitorStatuses.CheckedOut ? _.isUndefined(
														checkedOutTime
													) || checkedOutTime === null ? (
														moment()
													) : (
														moment(checkedOutTime).utc()
													) : (
														''
													)
												}
												disabled={true}
												// parsley={{
												//   "data-parsley-pattern": "/^[A-Za-z ]+$/",
												//   "data-parsley-minlength": 1,
												//   "data-parsley-required": true,
												//   "data-parsley-minwords": "1",
												//   "data-parsley-gte": 100000000000,
												//   "data-parsley-error-message": "Please enter a valid time"
												// }}
											/>
										) : (
											<InputNewComponent
												type="text"
												name="checkoutTime"
												placeHolder=""
												label={'Check-out Time'}
												value={''}
												handleChange={(e) => this.handleInputChange(e)}
												disabled={this.state.IsFormEditable}
											/>
										)}
									</div>
								</div>
							</div>
						) : (
							''
						)}
						<div className="InstructionText">
							<label className="visitor-label">Special Instructions</label>
							<InputNewComponent
								type="text"
								name="specialInstruction"
								placeHolder={
									this.state.IsFormEditable ? '' : 'Enter special instructions for visitor on arrival'
								}
								// placeHolder="Enter special instructions for visitor on arrival"
								value={specialInstruction}
								handleChange={(e) => this.handleInputChange(e)}
								disabled={this.state.IsFormEditable}
							/>
						</div>

						<div
							className={'validation-messages' + (this.state.addVisitorFormValid ? ' valid' : ' invalid')}
							id="validation-messages"
						>
							{this.state.addVisitorFormValid && (
								<div>{/* <p className="message type-success">Sending data To Server...</p> */}</div>
							)}
						</div>

						{this.state.message && (
							<div className="visitor-wrapper visitor-added-successful-txt">
								<label> {this.state.message} </label>
							</div>
						)}

						<div className="footer-btn-group visitor-wrapper">
							<ButtonGroup
								rightText={this.state.IsFormEditable === true ? 'Edit Information' : 'Save Changes'}
								leftText={this.state.IsFormEditable == true ? 'Back' : 'Cancel'}
								rightDisabled={
									this.state.visitorStatus !== configHelpers.VisitorStatuses.Notified ||
									this.state.IsOverlayAppear
								}
								icon_l=""
								icon_r=""
								//rightDisabled={this.state.IsOverlayAppear}
								rightClick={() => {
									if (this.state.IsFormEditable === true) this.handleEditInfoClick();

									if (!this.state.IsFormEditable) this.validate(true);
								}}
								leftClick={this.handleCancelClick}
								rightStyle={
									this.state.visitorStatus === configHelpers.VisitorStatuses.CheckedOut ||
									this.state.visitorStatus === configHelpers.VisitorStatuses.CheckedIn ? (
										EditButton_style_checkout
									) : this.state.visitorStatus === configHelpers.VisitorStatuses.Notified &&
									moment().isAfter(moment(this.state.startDate), 'day') ? (
										EditButton_style_checkout
									) : (
										EditButton_style_others
									)
								}
								otherClass={
									this.state.visitorStatus === configHelpers.VisitorStatuses.CheckedOut ||
									this.state.visitorStatus === configHelpers.VisitorStatuses.CheckedIn ? (
										'cancel-button-onchekout'
									) : (
										''
									)
								}
							/>
						</div>
					</form>

					<FooterNavigation />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		modules: moduleSelectors.loadModules(state),
		setting: settingSelector.loadGlobalSetting(state),
		meeting: meetingSelectors.getMeeting(state),
		searchResult: visitorSelector.getSearchResult(state)
	};
}
export default withRouter(connect(mapStateToProps)(VisitorProfile));
