import React, { Component } from "react";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../../components/FooterNavigation/FooterNavigation";
import Button from "../../components/Button/Button";
import { createBrowserHistory } from 'history';

import "../../sass/containers/ErrorScreen/index.css";

const browserHistory = createBrowserHistory();
class ErrorScreen500 extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {

        // Timeout to overwrite default call to setClass in App component
        setTimeout(() => {

            this.props.route.onInit.setClass(false, { location: { pathname: '-500-error-page' } });

        }, 0);
    }

    render() {

        return (
            <div >
                {
                    // <HeaderNavigation closed={true} isLoggedIn={true} />
                }

                <div className="container transparent type-plain-header-block">
                    <div style={{ width: 100 + "%", height: 272 + "px" }}>

                    </div>
                    <h1 className="align-center enlarged">#500</h1>

                </div>

                <div className="white-background">
                    <div className="container">

                        <div className="content-wrapper">

                            <h2>Unexpected Error</h2>

                            <h3 className="brownish-grey align-center">Seems like an error has occurred<br /> and your request couldn't be completed</h3>

                        </div>

                        <div className="align-center">
                            <Button
                                handleClick={() => { browserHistory.push("/"); }}
                                text="Back To Home"
                                size="medium" />
                        </div>




                    </div>

                </div>
                <FooterNavigation />
            </div>


        );
    }
}

export default ErrorScreen500;
