import './sass/index.css';
import './sass/iescss.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, Switch, BrowserRouter  } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import routes from './routes';
import {createBrowserHistory} from 'history';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import './utility/validators';
import * as reducers from './store/reducers';
import history from './history';
import LoginScreen from './containers/LoginScreen';
//let Router = require('react-router-dom').BrowserRouter

//const history = createBrowserHistory();
const currentHostname = document.location.hostname;
var composeEnhancers;

if (
	!process.env.NODE_ENV ||
	process.env.NODE_ENV === 'development' ||
	currentHostname.indexOf('ept-dev.co.uk') !== -1
) {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
	composeEnhancers = compose;
}

const store = createStore(combineReducers(reducers), composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
	
	<Provider store={store}>
	<BrowserRouter history={history} > 
			<Route children={routes} />
	 </BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

//ReactDom.render(<App />,document.getElementById("root"));
// registerServiceWorker();
