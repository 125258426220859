import React, { Component, useEffect } from "react";
//var PropTypes = require('prop-types');
import { connect } from "react-redux";
import autobind from "react-autobind";
import moment from "moment";
import {withRouter} from 'react-router-dom';
import * as pamentGatewaySelectors from "../../../store/PymentGateway/reducer";
import * as tenantActions from "../../../store/Tenants/actions";
import * as paymentGatewayActions from "../../../store/PymentGateway/actions";
import * as resourceActions from "../../../store/Resources/actions";
import * as resourceMonthlyActions from "../../../store/ResourcesMonthly/actions";
import * as tenantSeSelectors from "../../../store/Tenants/reducer";
import * as pageActions from "../../../store/PageEvents/actions";
//import * as resourceSelectors from "../../../store/Resources/reducer";
import * as bookingbugAuthSelector from "../../../store/BookingAuth/reducer";
import * as checkoutActions from "../../../store/Checkout/actions";
import * as checkoutSelectors from "../../../store/Checkout/reducer";

import FeatureArea from "../../../components/FeatureArea/FeatureArea";

//import scriptLoader from "react-async-script-loader";
import _ from "lodash";
import * as CyptoHelper from "../../../utility/cryptoUtility";
import bb_authService from "../../../services/BookingBugServices/LoginService";

import "../../../sass/containers/landingPage.css";
import "../../../sass/containers/Payment/index.css";
import featureBg from "../../../images/header-image.png";
import * as conciergeSetting from "../../../store/ConciergeSetting/reducer";

const queryString = require('query-string');

const PaymentGateWayProvider = {
    WorldPay: "WorldPay",
    SagePay : "SagePay"
};
const calendarViews = {
    Day: "day",
    Week: "week",
    WorkWeek: "work_week",
    Month: "month"
};

const ActionMessages = {
    success: "You have successfully paid for this booking. A confirmation email will be sent to the relevant tenant.",
    fail: "Sorry, your booking has been unsuccessfull"
};
const PaymentResponse = {
    initial: "You Are About To Make The Following Payment:",
    error: "Oops! It looks like there was a problem with the payment or it was cancelled. If you believe this to be an error, please wait and you will be redirected shortly",
    success: "Success! Thank you for making a payment, please wait and you will be redirected shortly"
};
const PaymentStage = {
    Payment: "payment"
};
const ResourcePager = {
    page: 1,
    per_page: 387
};

const ORDER_KEY = "order_key";



class Payments extends Component {

    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            itemQue: [],
            tenant: undefined,
            order: undefined,
            selectedResources: [],
            selectedResource: undefined,
            selectedService: undefined,
            calendarView: "",
            orderset: false,
            searchQuery: queryString.parse(this.props.history.location.search),
            paymentGatewaySettings: undefined,
            processing: true,
            showStatus: undefined,
            error: undefined,
            message: "",
            queryData: undefined,
            prefetch_calendar_state_enabled: false,
            bbauthToken: this.props.bbAuthToken,
            bgImage: featureBg,
            paymentResponseMsg: PaymentResponse.initial
        };


        autobind(this);

        this.props.dispatch(paymentGatewayActions.fetchgatewaySettings());
        this.props.dispatch(checkoutActions.loadBasketFromCache());
        this.props.dispatch(checkoutActions.SetActionType("Card"));
        this.props.dispatch(tenantActions.getAllTenants());
        this.props.dispatch(pageActions.setPageEvent("bookings"));
    }


    getOrderToken() {
        return localStorage.getItem(ORDER_KEY);
    }
    removerOrderToken() {
        localStorage.removeItem(ORDER_KEY);
    }

    componentDidMount() {
        this._isMounted = true;
        const { searchQuery } = this.state;

        if (_.isUndefined(this.props.bbAuthToken)) {
            let bbauth = bb_authService.getToken() ? bb_authService.getToken() : undefined;
            this.setState({
                bbauthToken: bbauth,
                error: _.isUndefined(bbauth) ? true : undefined,
                message: _.isUndefined(bbauth) ? "Not authorized" : "",
                
            });
        }

        let order_crypt = this.getOrderToken();


        if (order_crypt) {
            let dataObj = CyptoHelper.decryptAES(order_crypt);
           
            //from modal redirect
            
            if (dataObj && _.isUndefined(searchQuery.bbid)) {

                this.setState({
                    itemQue: !_.isUndefined(dataObj.itemQue) ? dataObj.itemQue : undefined,
                    tenant: !_.isUndefined(dataObj.tenant) ? dataObj.tenant : undefined,
                    selectedResources: !_.isUndefined(dataObj.selectedEvents) ? dataObj.selectedEvents : [],
                    selectedResource: !_.isUndefined(dataObj.selectedResource) ? dataObj.selectedResource : undefined,
                    selectedService: !_.isUndefined(dataObj.selectedService) ? dataObj.selectedService : undefined,
                    calendarView: !_.isUndefined(dataObj.carlendarView) ? dataObj.carlendarView : ""
                });



                //this.servicesSlots(dataObj.selectedService, start_date, end_date, dataObj.carlendarView);
            }
        }
        //triggered if redirection data or location query data found
        if (!_.isUndefined(searchQuery) && !_.isUndefined(searchQuery.bbid)) {

            let statusMsg = this.state.paymentResponseMsg;
            if (searchQuery.transStatus === "Y") {
                statusMsg = PaymentResponse.success
            } else if (searchQuery.transStatus === "C") {
                statusMsg = PaymentResponse.error;

            } else {
                statusMsg = PaymentResponse.error;
            }


            //clear order token from local storage
            this.removerOrderToken();

            this.setState({
                processing: true,
                error: true,
                message: "",
                paymentResponseMsg: statusMsg
            });

            var dataToDecrypt = decodeURI(searchQuery.bbid);
            const data = CyptoHelper.decryptAES(dataToDecrypt);
           
            if (data && data !== "") {

               
                this.props.dispatch(resourceActions.setServiceUnit(data.order.units));
               

                if (data.calendarView === calendarViews.Day) {
                    let start_date = moment(data.order.start)
                    let end_date = moment(data.order.end)

                    this.servicesSlots(data.selectedService, start_date, end_date, data.calendarView)
                } else if (data.calendarView === calendarViews.Week || data.calendarView === calendarViews.WorkWeek) {
                    let start_date = moment(data.order.start).startOf("week");
                    let end_date = moment(data.order.start).endOf("week");

                    this.servicesSlots(data.selectedService, start_date, end_date, data.calendarView)

                } else if (data.calendarView === calendarViews.Month) {
                    let start_date = moment(data.order.start).startOf("month");


                    var date_ = start_date.toDate(), y = date_.getFullYear(), m = date_.getMonth();
                    var startDay = new Date(y, m, 1);
                    var endDay = new Date(y, m + 1, 0);

                    startDay = moment(startDay).subtract(7, 'd');
                    endDay = moment(endDay).add(7, 'd');



                    this.fetchResources(ResourcePager.page, ResourcePager.per_page, data.selectedService, startDay, endDay);
                }


                this.setState({
                    order: data.order,
                    carlendarView: data.calendarView,
                    queryData: data,
                    selectedService: data.selectedService,
                    selectedResource: data.selectedResource,
                    selectedResources: data.selectedResources,
                    prefetch_calendar_state_enabled: true
                });

            }


        } else {

            this.setState({
                processing: true
            });
        }

      
        this.setState({
            showStatus: this.props.history.location.search && this.props.history.location.search !== "" ? true : false
        });

       if(this.props.history.location.search && this._isMounted === true){
       // this.handlePaymentReturn(false);
       }
    }
    
    componentWillUnmount(){
        this._isMounted = false;
    }
    componentDidUpdate(prevProps, prevState) {
        const { prefetch_calendar_state_enabled,
            searchQuery, queryData, selectedResource,
            selectedService, itemQue, tenant,
            order, orderSet } = this.state;
        const { basketCache } = this.props;

        if (this.props.paymentGatewaySettings !== prevProps.paymentGatewaySettings) {
            this.setState({
                paymentGatewaySettings: this.props.paymentGatewaySettings
            });
        }

        if (this.props.tenants !== prevProps.tenants &&
            this.props.tenants.length > 0 &&
            !_.isUndefined(searchQuery) &&
            !_.isUndefined(queryData) &&
            prefetch_calendar_state_enabled === true) {

            let tenant = _.find(this.props.tenants, { bookingBugMemberId: queryData.bbid });

            if (tenant){
                this.props.dispatch(tenantActions.getSelectedTenant(tenant.id));
                this.setState({
                    tenant : tenant
                });
              
            }
        }

        //craete order from basket item from basket cache, this is bookingbug basket item
        //step 1
        if (prevProps.basketCache !== basketCache &&
            !_.isUndefined(basketCache) &&
            basketCache.length > 0 &&
            !_.isUndefined(selectedResource) &&
            !_.isUndefined(selectedService) &&
            !_.isUndefined(itemQue) &&
            itemQue.length > 0 &&
            !_.isUndefined(tenant)) {

            let endDate, order;

            let basketItem;

            //check basket item is the selected item que form modal
            if (selectedService.duration_unit === "day") {
                basketItem = _.filter(basketCache, item => {
                    return moment(item.date).format("YYYY-MM-DD") === moment(itemQue[0].start).format("YYYY-MM-DD") && item.duration === itemQue[0].duration && item.member_id === tenant.bookingBugMemberId;
                });
            } else {
                basketItem = _.filter(basketCache, item => {
                    return moment(item.date).format("YYYY-MM-DD") === moment(itemQue[0].start).format("YYYY-MM-DD") && item.time === itemQue[0].start_min && item.duration === itemQue[0].duration && item.member_id === tenant.bookingBugMemberId;
                });
            }


            if (basketItem && basketItem.length > 0) {

                if (basketItem.length === 1) {

                    if (selectedService.duration_unit === "day") {

                        endDate = moment(basketItem[0].date, "YYYY-MM-DD").add(basketItem[0].duration, 'days');
                        order = {
                            id: basketItem[0].id,
                            start: basketItem[0].date,
                            end: endDate.format("YYYY-MM-DD"),
                            duration: basketItem[0].duration,
                            units: this.state.selectedService.duration_unit,
                            price: this.amountFormat(basketItem[0].price),
                            totalprice: basketItem[0].total_price,
                            eventId: basketItem[0].event_id
                        };

                    } else if (selectedService.duration_unit === "minute") {

                        let start_time = Math.trunc(basketItem[0].time / 60) + ":" + (basketItem[0].time % 60);
                        let sdate = moment(basketItem[0].date + "T" + start_time, "YYYY-MM-DDTHH:mm").toDate();
                        let etime = Math.trunc((basketItem[0].time + basketItem[0].duration) / 60) + ":" + ((basketItem[0].time + basketItem[0].duration) % 60);
                        let edate = moment(basketItem[0].date + "T" + etime, "YYYY-MM-DDTHH:mm").toDate();


                        order = {
                            id: basketItem[0].id,
                            start: sdate,
                            end: edate,
                            duration: basketItem[0].duration,
                            units: this.state.selectedService.duration_unit,
                            price: this.amountFormat(basketItem[0].price),
                            totalprice: basketItem[0].total_price,
                            eventId: basketItem[0].event_id,
                            time: basketItem[0].time
                        };

                    }


                    if (order) {
                       
                        let request = {
                            amount : order.price,
                            currency : this.state.paymentGatewaySettings.currency,
                            cartid : this.state.paymentGatewaySettings.paymenttype,
                        };

                      
                       

                        if(this.state.paymentGatewaySettings.type === PaymentGateWayProvider.SagePay){ 

                            let dataForCrypto = {
                                bbid: this.state.tenant.bookingBugMemberId,
                                selectedResources: _.map(this.state.selectedResources, res => {
                                    return res.eventId
                                }),
                                selectedResource: this.state.selectedResource.eventId,
                                selectedService: this.state.selectedService.id,
                                order: order,
                                calendarView: this.state.calendarView
                            };

                            let cryptdata = CyptoHelper.encryptAES(dataForCrypto);

                            cryptdata = encodeURIComponent(cryptdata);

                            var formbasket_ = document.createElement('basket');

                            formbasket_.appendChild(
                                this.formBasket(this.state.paymentGatewaySettings.description, 1, Number(order.price), 
                                    0, Number(order.price), Number(order.price), "")
                            );
                           
                           request = { ...request,
                                memberid : tenant.id,
                                description : this.state.paymentGatewaySettings.description,
                                vendortxtcode : '',
                                basket : formbasket_.outerHTML,
                                paymenttype : this.state.paymentGatewaySettings.paymenttype  ,
                                cryptdata : cryptdata
                            };
                        }
                       
                       
                        this.props.dispatch(paymentGatewayActions.getPaymentCryptSignature(this.state.paymentGatewaySettings.type, request, success =>{
                           
                            if (success === true) {
                                this.setState({
                                    error: undefined,
                                    message: "",
                                    status: PaymentStage.Payment
                                });
                            }
                        }));

                    }

                } else {
                    this.setState({
                        error: true,
                        message: "Duplicate bookings found"
                    });
                }

            }

            this.setState({
                order: order,
                orderset: true,
                processing: undefined,
                // error: undefined,
                // message: ""
            });
        }

        if (orderSet && orderSet === true && !_.isUndefined(this.state.paymentGatewaySettings) && _.isUndefined(this.props.signature)) {

            if (order){
               
                let request = {
                    amount : order.price,
                    currency : this.state.paymentGatewaySettings.currency,
                    cartid : this.state.paymentGatewaySettings.paymenttype
                };
                

                if(this.state.paymentGatewaySettings.type === PaymentGateWayProvider.SagePay){ 

                    let dataForCrypto = {
                        bbid: this.state.tenant.bookingBugMemberId,
                        selectedResources: _.map(this.state.selectedResources, res => {
                            return res.eventId
                        }),
                        selectedResource: this.state.selectedResource.eventId,
                        selectedService: this.state.selectedService.id,
                        order: order,
                        calendarView: this.state.calendarView
                    };

                    let cryptdata = CyptoHelper.encryptAES(dataForCrypto);

                    cryptdata = encodeURIComponent(cryptdata);

                    var formbasket_ = document.createElement('basket');

                    formbasket_.appendChild(
                        this.formBasket(this.state.paymentGatewaySettings.description, 1, Number(order.price), 
                            0, Number(order.price), Number(order.price), "")
                    );
                   
                   request = { ...request,
                        memberid : tenant.id,
                        description : this.state.paymentGatewaySettings.description,
                        vendortxtcode : '',
                        basket : formbasket_.outerHTML,
                        paymenttype : this.state.paymentGatewaySettings.paymenttype  ,
                        cryptdata : cryptdata
                    };
                }


                this.props.dispatch(paymentGatewayActions.getPaymentCryptSignature(this.state.paymentGatewaySettings.type, request));
            }
        }

        //  if( prevProps.basketCache !== basketCache  && !_.isUndefined(basketCache) && !_.isUndefined(this.state.tenant)){
            
            
        //         this.handlePaymentReturn();
             
        //  }
    }

    
    //Preload services slot for date selection and email data
    servicesSlots(serviceId, startDate, endDate, view) {

        let start_date = startDate.format("YYYY-MM-DD"); // dateFormat(startDate._d, "isoDate");
        let end_date = endDate.format("YYYY-MM-DD"); // dateFormat(moment(endDate._d, "isoDate"));


        this.props.dispatch(
            resourceActions.fetchServicesSlots(
                serviceId,
                start_date, end_date, success => {

                    if (success === true) {

                        if (this._isMounted)
                            this.setState({
                                request_fail: undefined
                            });


                    } else {

                        this.setState({
                            request_fail: true
                        });
                    }

                }
            )
        );

    }

    fetchResources(page, perpage, serviceId, startDate, endDate) {
        this.props.dispatch(resourceMonthlyActions.fetchResources(page, perpage, success => {
            if (success === true) {

                this.servicesDayData(serviceId, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
            }
        }));
    }
    servicesDayData(serviceId, startDate, endDate) {

        this.props.dispatch(resourceMonthlyActions.fetchServiceDayData(serviceId, startDate, endDate, success => {
            if (success === true) {
                // this.setState({
                //     processingResource: undefined
                // });
                this.fetchMonthlyServicesSlots(serviceId, moment(startDate), null)
            }
        }));
    }
    fetchMonthlyServicesSlots(serviceId, startDate, endDate) {

        var date_ = startDate.toDate(), y = date_.getFullYear(), m = date_.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        firstDay = moment(firstDay);
        lastDay = moment(lastDay);

        this.props.dispatch(resourceMonthlyActions.fetchServicesSlots(serviceId, firstDay.format("YYYY-MM-DD"), lastDay.format("YYYY-MM-DD")));
    }

    //format pence to pound (decimal point 2)
    amountFormat(amount) {

        let amt = amount && amount > 0 ? amount / 100 : 0;

        return amt.toFixed(2);
    }

    initializeWorldPay(signature, bbAuthToken, tenant, order, calendarView, paymentGatewaySettings) {

        let dataForCrypto = {
            bbid: tenant.bookingBugMemberId,
            selectedResources: _.map(this.state.selectedResources, res => {
                return res.eventId
            }),
            selectedResource: this.state.selectedResource.eventId,
            selectedService: this.state.selectedService.id,
            order: order,
            calendarView: calendarView
        };


        let crypted;

        if (!_.isUndefined(order)) {

            crypted = CyptoHelper.encryptAES(dataForCrypto);

            crypted = encodeURIComponent(crypted);



        }
       

        if (!_.isUndefined(order) && !_.isUndefined(signature) && !_.isUndefined(bbAuthToken)) {

            return <form action={paymentGatewaySettings.formurl} id="paymentForm" method="post">

                <input type="hidden" name="instId" id="instId" value={paymentGatewaySettings.instanceid} />
                <input type="hidden" name="cartId" id="cartId" value={paymentGatewaySettings.paymenttype} />
                <input type="hidden" name="MC_QubeReference" value="0" />
                <input type="hidden" name="MC_finalAmount" id="MC_finalAmount" value={order.price} />
                <input type="hidden" name="currency" value={paymentGatewaySettings.currency} />
                <input type="hidden" name="amount" id="worldPayAmount" value={order.price} />
                <input type="hidden" name="name" value={`${tenant.firstName} ${tenant.lastName}`} id="name" />
                <input type="hidden" name="desc" value={paymentGatewaySettings.description} id="desc" />
                <input type="hidden" name="email" value={tenant.email} id="email" />
                <input type="hidden" name="country" value={paymentGatewaySettings.country} />
                <input type="hidden" name="testMode" value={paymentGatewaySettings.mode} />
                <input type="hidden" name="MC_callback" value={paymentGatewaySettings.callbackurl} />
                <input type="hidden" name="MC_BBID" id="MC_BBID" value={crypted} />
                <input type="hidden" name="MC_BBAT" id="MC_BBAT" value={bbAuthToken} />
                <input type="hidden" name="signature" id="Signature" value={signature.signature} />


                <div>
                    <input type="submit" className="makePaymentButton" value="Make Payment" />
                </div>
            </form>

        } else {

            return ""; // _.isUndefined(this.state.processing) ? <p className="message type-error"> No orders selected </p> : ""
        }


    }

    formBasket(description, quantity, unitNetAmount, 
        unitTaxAmount, unitGrossAmount, totalGrossAmount, qubetransactionid){

        var newItem = document.createElement('item');
        var descriptionEL = document.createElement('description');
        var quantityEl = document.createElement('quantity');
        var netAmountEl = document.createElement('unitNetAmount');
        var taxAMountEl = document.createElement('unitTaxAmount');
        var grossAmountEl = document.createElement('unitGrossAmount');
        var totalAmountEl = document.createElement('totalGrossAmount');

        descriptionEL.innerHTML = description + " - " + qubetransactionid;
        quantityEl.innerHTML = quantity;
        netAmountEl.innerHTML = unitNetAmount;
        taxAMountEl.innerHTML = unitTaxAmount;
        grossAmountEl.innerHTML = unitGrossAmount;
        totalAmountEl.innerHTML = totalGrossAmount;


        newItem.appendChild(descriptionEL);
        newItem.appendChild(quantityEl);
        newItem.appendChild(netAmountEl);
        newItem.appendChild(taxAMountEl);
        newItem.appendChild(grossAmountEl);
        newItem.appendChild(totalAmountEl);

        return newItem;
    }
    initializeSagePay(signature, bbAuthToken, tenant, order, calendarView, paymentGatewaySettings){
      
        let crypted = signature.signature;

       
        return <form action={paymentGatewaySettings.formurl} id="paymentForm" method="post">
        <input type="hidden" name="VPSProtocol" value={paymentGatewaySettings.vpsprotocol} />
        <input type="hidden" name="TxType" value={paymentGatewaySettings.transactiontype.toUpperCase()} />
        <input type="hidden" name="Vendor" value={paymentGatewaySettings.vendorname} />
        <input type="hidden" name="Crypt" value={crypted} />
        
        <div>
            <input type="submit" className="makePaymentButton" value="Make Payment" />
        </div>
        </form>;
    }
    initializeDefault() {
        return (<span> Payment gateway not availaible </span>);
    }

    SetGateWay(type, signature, bbAuthToken, tenant, order, calendarView, paymentGatewaySettings) {

       
     
        //check for previously selected basket items for the tenant selected tenant not
        //including the current order, then remove from basket
        if (order && tenant) {
            let basketData = _.filter(this.props.basketCache, item => item.id !== order.id && item.member_id === tenant.bookingBugMemberId);

            if (basketData && basketData.length > 0) {

                for (var i = 0; i < basketData.length; i++) {

                    this.props.dispatch(checkoutActions.removeItemFromBasket(basketData[i].id));
                }

            }
        }

        if (type === PaymentGateWayProvider.WorldPay) {
            return this.initializeWorldPay(signature, bbAuthToken, tenant, order, calendarView, paymentGatewaySettings);
        }else if(type === PaymentGateWayProvider.SagePay){ 
         return this.initializeSagePay(signature, bbAuthToken, tenant, order, calendarView, paymentGatewaySettings);
        }else {
            return this.initializeDefault();
        }
    }

    //method to start the booking process from redirect return on resi and payment gataway
    handlePaymentReturn() {
        const { searchQuery, prefetch_calendar_state_enabled } = this.state;

        if (searchQuery) {
            
            var dataToDecrypt = decodeURI(searchQuery.bbid);

            const data = CyptoHelper.decryptAES(dataToDecrypt);
            
            if (data && !_.isUndefined(data.order)) {
                let booking = {};
                if (data.order.units === "day") {
                    booking = { ...data.order, start: moment(data.order.start).toDate(), end: moment(data.order.end).toDate(), bookingBugMemberId: data.bbid };
                } else if (data.order.units === "minute") {

                    booking = { ...data.order, bookingBugMemberId: data.bbid };
                }


                this.props.dispatch(resourceActions.setBooking(booking));
            }
            
            if (this.props.basketCache.length === 0 ) {
                this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.fail));
                this.props.dispatch(checkoutActions.SetActionStatus(false));
                this.props.dispatch(resourceActions.setPaymentAction(false));
                this.props.history.push({
                    pathname: "/",
                    state: {
                        module: "Bookings",
                        fromRedirect: true,
                        serviceId: data.selectedService,
                        selectedResourceId: data.selectedResource,
                        selectedResourcesId: data.selectedResources,
                        paymentStatus: false,
                        date: data.order.start
                    }
                });
            } else if (this.props.basketCache.length > 0 && data) {



                var status;

                if (searchQuery.transStatus === "Y") {
                    status = true;
                } else if (searchQuery.transStatus === "C") {
                    status = false;

                } else {
                    status = false;
                }

              
                if (status === true && prefetch_calendar_state_enabled === true) {

                    // this.setState({
                    //     prefetch_calendar_state_enabled: false,
                    //     showStatus: undefined
                    // });


                    this.props.dispatch(
                        checkoutActions.completeCheckout(data.bbid, success => {
                            if (success === true) {

                                this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.success));
                                this.props.dispatch(checkoutActions.SetActionStatus(true));
                               
                                this.props.history.push({
                                    pathname: "/",
                                    state: {
                                        module: "Bookings",
                                        fromRedirect: true,
                                        serviceId: data.selectedService,
                                        selectedResourceId: data.selectedResource,
                                        selectedResourcesId: data.selectedResources,
                                        paymentStatus: status,
                                        date: data.order.start
                                    }
                                });
                            } else {
                                this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.fail));
                                this.props.dispatch(checkoutActions.SetActionStatus(false));
                            }
                        }));
                } else if (status === false) {
                    let basketItem = undefined;

                    this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.fail));
                    this.props.dispatch(checkoutActions.SetActionStatus(false));


                    if (!_.isUndefined(data.order))
                        basketItem = _.find(this.props.basketCache, { id: data.order.id });


                    if (basketItem) {
                        this.props.dispatch(checkoutActions.removeItemFromBasket(basketItem.id, success => {
                            if (success === true) {
                                this.props.history.push({
                                    pathname: "/",
                                    state: {
                                        module: "Bookings",
                                        fromRedirect: true,
                                        serviceId: data.selectedService,
                                        selectedResourceId: data.selectedResource,
                                        selectedResourcesId: data.selectedResources,
                                        paymentStatus: status,
                                        date: data.order.start
                                    }
                                });
                            } else {
                                this.props.history.push({
                                    pathname: "/",
                                    state: {
                                        module: "Bookings",
                                        fromRedirect: true,
                                        serviceId: data.selectedService,
                                        selectedResourceId: data.selectedResource,
                                        selectedResourcesId: data.selectedResources,
                                        paymentStatus: status,
                                        date: data.order.start
                                    }
                                });
                            }
                        }));

                    } else {
                        this.props.history.push({
                            pathname: "/",
                            state: {
                                module: "Bookings",
                                fromRedirect: true,
                                serviceId: data.selectedService,
                                selectedResourceId: data.selectedResource,
                                selectedResourcesId: data.selectedResources,
                                paymentStatus: status,
                                date: data.order.start
                            }
                        });
                    }


                }


            }

        } else {

        }


        return "";
    }


    findAndReplace(string, target, replacement) {

        var i = 0, length = string.length;

        for (i; i < length; i++) {

            string = string.replace(target, replacement);

        }

        return string;

    }
    render() {

        const { order, tenant, paymentResponseMsg } = this.state;

        // fields: ["resource", "tenant", "duration", "price", "id"],
        let orderList = [];
        if (order && tenant) {
            let list = {};

            //pupulate Datalist for order table
            if (order.units === "day") {
                list = {
                    id: order.id,
                    resource: !_.isUndefined(this.state.selectedResource) ? this.state.selectedResource.eventName : "",
                    tenant: `${tenant.firstName} ${tenant.lastName}`,
                    duration: `${moment(order.start).format("DD/MM/YY")} - ${moment(order.end).format("DD/MM/YY")}`, //duration: `${order.start} - ${order.end} (${order.duration} ${order.duration === 1 ? "day" : "days"})`,
                    price: `£${order.price}`
                };
            } else if (order.units === "minute") {

                list = {
                    id: order.id,
                    resource: !_.isUndefined(this.state.selectedResource) ? this.state.selectedResource.eventName : "",
                    tenant: `${tenant.firstName} ${tenant.lastName}`,
                    duration: `${moment(order.start).format("DD/MM/YY")} (${moment(order.start).format("HH:mm A")} - ${moment(order.end).format("HH:mm A")} )`,
                    price: `£${order.price}`
                };
            }

            orderList.push(list);

           
        }
     
      
        return <div id="container" className="landing ">
            <FeatureArea
                type={"rooms"}
                pageHeader=""
                areaTitle={"Bookings"}
                imageBG={this.state.bgImage}
                landing={true}
                overlayColor={"#f2f2f3"}
            />
            <div className="paymentConfirmation" >

                <label className="paymentHeading">Payment</label>
                <span className="paymentConfirmationHead" >{paymentResponseMsg}</span>


                <div className="paymentTotal">
                    <div>
                        <label className="costHead">Total Cost</label>
                        <label className="totalCost">{this.state.order ? `£${this.state.order.price}` : "£0.00"}</label>
                    </div>

                    {
                        this.state.paymentGatewaySettings &&
                        this.state.tenant &&
                        this.state.order &&
                        this.state.calendarView &&
                        this.state.status === PaymentStage.Payment &&
                        this.state.bbauthToken &&
                        <div>
                            {
                                this.SetGateWay(this.state.paymentGatewaySettings.type, this.props.signature, this.state.bbauthToken, this.state.tenant, this.state.order, this.state.calendarView, this.state.paymentGatewaySettings)
                            }
                        </div>

                    }
                    {
                        _.isUndefined(this.state.status) &&
                        <div>
                            <input type="submit" className="makePaymentButton disabled" value="Make Payment" disabled={true} />
                        </div>
                    }

                </div>

                <div className="separaterLine"></div>

                {
                    this.state.paymentGatewaySettings &&
                    !_.isUndefined(this.state.paymentGatewaySettings) &&
                    this.props.signature &&
                    this.state.bbauthToken &&
                    this.state.tenant &&
                    this.state.order &&
                    this.state.calendarView &&

                    <div className="payableResourceList">
                        <label className="resourceListHead">Resource</label>
                        <label className="periodListHead">Period</label>
                        <label className="costListHead">cost</label>
                    </div>
                }
                {
                    this.state.processing ?


                        this.state.order ?

                            <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div> : ""

                        :


                        this.state.paymentGatewaySettings &&
                        !_.isUndefined(this.state.paymentGatewaySettings) &&
                        this.props.signature &&
                        this.state.bbauthToken &&
                        this.state.tenant &&
                        this.state.order &&
                        this.state.calendarView &&
                        _.map(orderList, (orderItem, index) => {

                            return <div className="resourceContentList" key={index}>
                                <label className="resourceContent">{orderItem.resource}</label>
                                <label className="periodContent">{orderItem.duration}</label>
                                <label className="costContent">{orderItem.price}</label>
                            </div>;
                        })


                }
                {

                    this.props.location.search !== "" && !_.isUndefined(this.state.queryData) ?

                        this.state.showStatus === true ?
                            <div>

                                <p className="message type-success payment"> Processing your booking..... </p>
                                {
                                    //process moved in to componentDidUpdate
                                    //start the booking process based on the showStatus value
                                   this.state.prefetch_calendar_state_enabled === true && this.props.selectedTenant && this.handlePaymentReturn()
                                }
                            </div> : ""

                        : ""
                }

            </div>

            <div className="container content-wrapper type-payment">


                <div>
                    {
                        this.props.errors && _.map(this.props.errors, (error, idx) => {
                            return <p className="message type-error" key={idx}> {error} </p>;
                        })
                    }
                    {
                        this.state.error &&
                        <p className="message type-error"> {this.state.message}</p>

                    }
                </div>


                {this.state.processing && !_.isUndefined(this.props.location.state) && !_.isUndefined(this.props.location.state.data) ?


                    <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div>

                    :

                    <div>

                        {
                            this.state.paymentGatewaySettings &&
                            !_.isUndefined(this.state.paymentGatewaySettings) &&
                            this.props.signature &&
                            this.props.bbAuthToken &&
                            this.state.tenant &&
                            this.state.order &&
                            this.state.calendarView &&

                            <div>
                               
                            </div>


                        }

                      

                    </div>

                }


               





            </div>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        paymentGatewaySettings: pamentGatewaySelectors.getGatewaySettings(state),
        errors: pamentGatewaySelectors.getGatewayErrors(state),
        signature: pamentGatewaySelectors.getPaymentSignature(state),
        bbAuthToken: bookingbugAuthSelector.getAuthToken(state),
        basketCache: checkoutSelectors.getBasket(state),
        tenants: tenantSeSelectors.getAllTenants(state),
        selectedTenant: tenantSeSelectors.getSelectedTenant(state),
        conciergeSettings: conciergeSetting.loadGlobalSetting(state)
    };
}


export default withRouter(connect(mapStateToProps)(Payments));
