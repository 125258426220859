import _ from 'lodash';
import * as types from './actionTypes';
import moment from 'moment';
import dateFormat from 'dateformat';
import * as resourcesSelectors from './reducer';
import resourcesService from '../../services/BookingBugServices/ResourceService';
import serviceServices from '../../services/BookingBugServices/ServicesService.js';
import engageBookingServices from '../../services/EngageBookingBugServices/EngageBookingServices';

import meetingServices from '../../services/MeetingServices';
import checkoutService from '../../services/BookingBugServices/CheckoutService';
import * as checkoutTypes from '../Checkout/actionTypes';
import * as tenantSelector from '../../store/Tenants/reducer';
import * as meetingsSelector from '../../store/Meetings/reducer';
import * as eventsSelector from '../../store/PageEvents/reducer';
import * as serviceModuleSelectors from '../../store/ServiceModules/reducer';
import * as resourceMonthlySelectors from '../../store/ResourcesMonthly/reducer';

import * as config from '../../services/config';

export function getServices(callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			// const result = await resourcesService.getServices();

			const result = await engageBookingServices.getServices();

			dispatch({ type: types.SERVICES_FETCHED, services: result });

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setServiceUnit(unit, callback = () => {}) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.SERVICE_UNIT_CHANGED, unit: unit });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
//Get details for service
export function fetchServiceDetail(serviceId, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const services = resourcesSelectors.getServices(getState());

			// const services = await serviceServices.getServices(serviceId);

			// const selectedService = _.filter(services, s => Number(s.id) === Number(serviceId));

			// const serviceData = selectedService.map(serv => {
			//   return {
			//     time_step: serv.booking_time_step,
			//     service_name: serv.name,
			//     detail_group_id: serv.detail_group_id
			//   };
			// });
			// const service = serviceData[0];

			const selectedService = _.find(services, { id: Number(serviceId) });

			if (selectedService) {
				dispatch({
					type: types.SERVICE_DETAIL_FETCHED,
					service: selectedService
				});

				callback(true);
			}

			callback(false);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
export function getResourceQuery() {
	const query = {
		roomsServiceHrId: `${config.MEETING_ROOM_SERVICE_HR_ID}`,
		parkingServiceHrId: `${config.PARKING_SERVICE_HR_ID}`
	};
	return {
		type: types.RESOURCES_QUERY,
		query
	};
}

export function fetchServicesSlots(serviceId, startDate, endDate, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			// const servicesSlots = await resourcesService.getResourceServices(
			//   serviceId,
			//   startDate,
			//   endDate
			// );

			const servicesSlots = await engageBookingServices.getResourceTimeData(serviceId, startDate, endDate);

			dispatch({
				type: types.SERVICES_SLOTS_FETCHED,
				servicesSlots
			});

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function fetchBookingQuestions(group_id, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			if (!_.isUndefined(group_id)) {
				// const booking_questions = await resourcesService.getBookingQuestions(group_id);
				const booking_questions = await engageBookingServices.getBookingQuestions(group_id);
				dispatch({ type: types.BOOKING_QUESTIONS_LOAD_SUCCESS, questions: booking_questions });
				callback(true);
			} else {
				dispatch({ type: types.BOOKING_QUESTIONS_LOAD_FAIL, status: 'error' });
				callback(false);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setResourceEventsSelected(events, callback = () => {}) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.RESOURCE_EVENT_SELECTED, events: events });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
export function setEventSelected(eventId) {
	return async (dispatch, getState) => {
		try {
			dispatch({
				type: types.RESOURCE_EVENT_SET,
				eventId
			});
		} catch (error) {
			console.error(error);
		}
	};
}
export function getSelectedEventSlot(eventId, startDate, serviceId, eventName, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const bookings = await resourcesService.getBookings(
				dateFormat(startDate, 'isoDate'),
				dateFormat(startDate, 'isoDate')
			);

			const serviceBooking = _.filter(
				bookings,
				(booking) => booking.service_id === serviceId && booking.resource_name === eventName
			);
			const tenants = tenantSelector.getAllTenants(getState());
			const meetings = meetingsSelector.getMeetings(getState());
			const pageEvent = eventsSelector.getPageEvent(getState());

			const basket = await checkoutService.getBasket();
			// const services = await serviceServices.getServices(serviceId);
			const servicesSlots = getState().resources.servicesSlots;

			//const selectedService = _.find(services, s => s.id == serviceId);
			const service = resourcesSelectors.getService(getState());

			if (!_.isUndefined(service)) {
				var time_step = service.time_step;

				const serviceSlot = _.filter(servicesSlots, (o) => o.event_id === eventId);

				const daySlots = serviceSlot.map((day) => day.times);

				var slots = [];

				const timeSlots = daySlots.map((daySlot) => {
					_.map(daySlot, (s, i) => {
						let today = startDate;

						if (startDate.getDate() === new Date().getDate()) {
							today.setHours(new Date().getHours());
						}

						let start = new Date(today.getFullYear(), today.getMonth(), today.getDate());

						start.setMinutes(start.getMinutes() + s.time);

						let end = new Date(today.getFullYear(), today.getMonth(), today.getDate());

						end.setMinutes(end.getMinutes() + s.time + time_step);

						let start_min = s.time;
						let end_min = end.getHours() * 60 + end.getMinutes();

						if (s.avail === 1) {
							let availSlot = {
								isAvailable: false,
								start: new Date(start),
								end: new Date(end),
								price: s.price,
								id: i,
								start_min: start_min,
								end_min: end_min
							};

							if (today.getDate() === new Date().getDate() && s.time / 60 <= today.getHours()) {
								availSlot.isAvailable = false;
								slots.push(availSlot);
							} else {
								availSlot.isAvailable = true;
								slots.push(availSlot);
							}
						} else {
							let inBasket = false;
							for (var index = 0; index < basket.length; index++) {
								var el = basket[index];
								if (el.event_id === Number(eventId) && el.time === s.time) {
									inBasket = true;
								}
							}

							let unavailSlot = {
								isAvailable: false,
								start: new Date(start),
								end: new Date(end),
								inBasket: inBasket,
								id: i,
								time: s.time,
								start_min: start_min,
								end_min: end_min
							};

							slots.push(unavailSlot);
						}
					});
				});

				let lastId = slots.length ? slots[slots.length - 1].id : 0;

				const bookedSlot = _.map(serviceBooking, (booked, i) => {
					let bookedDate = new Date(booked.datetime);
					let bookedEndDate = new Date(booked.end_datetime);
					const tenant = _.find(tenants, (member) => member.email === booked.client_email);

					let parkingDetails = _.find(
						meetings,
						(meeting) =>
							meeting.tenant.email === tenant.email &&
							new Date(meeting.start).getHours() === new Date(booked.datetime).getHours()
					);

					let slotDetail = tenant
						? `${tenant.firstName} ${' '} ${tenant.lastName} - ${tenant.companyName}  ${pageEvent ===
								'parking' &&
							parkingDetails &&
							parkingDetails.visitor.parkingReg
								? ' - Visitor Details: ' +
									parkingDetails.visitor.firstName +
									' ' +
									parkingDetails.visitor.surname +
									' ' +
									parkingDetails.visitor.parkingReg
								: ''}`
						: '';

					let bookedAvailSlot = {
						isAvailable: false,
						start: new Date(bookedDate),
						end: new Date(bookedEndDate),
						price: booked.price,
						id: lastId > 0 ? lastId + 1 + i : i,
						desc: slotDetail,
						booked: true
					};

					slots.push(bookedAvailSlot);
					return bookedAvailSlot;
				});

				const slotsData = {
					slots: slots,
					eventId: serviceSlot[0].event_id
				};

				const eventDate = startDate;
				dispatch({
					type: types.SLOTS_FETCHED,
					slotsData
				});
				dispatch({
					type: types.EVENT_DATE_SELECTED,
					eventDate
				});

				dispatch({
					type: types.SELECTED_SERVICE_SLOT,
					selectedServiceSlot: slots
				});
			}

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function getSelectedEventsSlots(events, startDate, endDate, serviceId, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const tenants = tenantSelector.getAllTenants(getState());
			const meetings = meetingsSelector.getMeetings(getState());
			//  const pageEvent = eventsSelector.getPageEvent(getState());

			const basket = await checkoutService.getBasket();

			const servicesSlots = getState().resources.servicesSlots;

			const service = resourcesSelectors.getService(getState());

			const dateRange = getDatesRange(startDate, endDate);

			// const bookings_ = resourcesSelectors.getBookings(getState());
			// const bookings = _.map(bookings_, item => item);
			//var servicesSlots_grp = _.groupBy(servicesSlots, serv => serv.resource_id);

			var resourceIds = _.filter(
				_.map(events, (eventData) => {
					let resourceData = _.find(servicesSlots, { event_id: eventData.eventId });
					if (resourceData) {
						return { resourceId: resourceData.resource_id };
					}
					return undefined;
				}),
				(x) => !_.isUndefined(x)
			);

			const resourceObj = {
				events: resourceIds
			};

			let bookings = [];
			let bookings_range = [];

			if (events && events.length > 0) {
				bookings = await engageBookingServices.getResourceBookings(
					resourceObj,
					moment(startDate).format('YYYY-MM-DD'),
					moment(endDate).format('YYYY-MM-DD'),
					1,
					397
				);
			}

			var bookedBookings = _.get(bookings, 'booked._embedded.bookings');
			var blockedBookings = _.get(bookings, 'blocked._embedded.bookings');

			//const resourceBookings = await resourcesService.getBookings(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
			//const resourceBookings = await engageBookingServices.getBookings(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));

			// if (resourceBookings);
			// bookings = resourceBookings;

			if (!_.isUndefined(service)) {
				var slots = [];
				var bookedSlots = [];
				let card = 0;

				const eventsSlots = _.map(events, (event, index) => {
					card = index + 1;

					// const serviceBooking = _.filter(
					//   bookings,
					//   booking =>
					//     booking.service_id == serviceId && booking.resource_name === event.eventName && !_.isUndefined(booking.client_id)
					// );

					//const blockedResourceBooking = _.filter(bookings, resource => resource.resource_name === event.eventName && _.isUndefined(resource.client_id));

					const serviceBooking = _.filter(
						bookedBookings,
						(booking) => booking.service_id === serviceId && booking.resource_name === event.eventName
					);

					const blockedResourceBooking = _.filter(
						blockedBookings,
						(resource) => resource.resource_name === event.eventName
					);

					var time_step = service.booking_time_step;

					const serviceSlot = _.filter(servicesSlots, (o) => o.event_id === event.eventId);

					_.map(serviceSlot, (slotService) => {
						let daySlot = slotService.times;

						_.map(dateRange, (dteRange, index) => {
							_.map(daySlot, (s, i) => {
								if (moment(s.datetime).format('YYYY-MM-DD') === moment(dteRange).format('YYYY-MM-DD')) {
									let today = dteRange;

									if (dteRange.getDate() === new Date().getDate()) {
										today.setHours(new Date().getHours());
									}

									let start = new Date(today.getFullYear(), today.getMonth(), today.getDate());

									start.setMinutes(start.getMinutes() + s.time);

									let end = new Date(today.getFullYear(), today.getMonth(), today.getDate());

									end.setMinutes(end.getMinutes() + s.time + time_step);

									let start_min = s.time;
									let end_min = end.getHours() * 60 + end.getMinutes();

									let lastEntryId = slots.length ? slots[slots.length - 1].id + 1 : 0;

									// let dupl = _.find(slots, { id: i });
									// if (!_.isUndefined(dupl))
									//   i = lastEntryId + 1;

									if (s.avail === 1) {
										let availSlot = {
											isAvailable: false,
											start: new Date(start),
											end: new Date(end),
											price: s.price,
											id: lastEntryId,
											start_min: start_min,
											end_min: end_min,
											eventId: event.eventId
										};

										if (
											today.getDate() === new Date().getDate() &&
											s.time / 60 <= today.getHours()
										) {
											availSlot.isAvailable = false;
											slots.push(availSlot);
										} else {
											availSlot.isAvailable = true;

											//check past dates are not available
											let now = new Date();
											if (start < now) availSlot.isAvailable = false;

											slots.push(availSlot);
										}
									} else {
										let inBasket = false;
										for (var index = 0; index < basket.length; index++) {
											var el = basket[index];
											if (el.event_id === Number(event.eventId) && el.time === s.time) {
												inBasket = true;
											}
										}

										let unavailSlot = {
											isAvailable: false,
											start: new Date(start),
											end: new Date(end),
											inBasket: inBasket,
											id: i,
											time: s.time,
											start_min: start_min,
											end_min: end_min,
											eventId: event.eventId
										};

										slots.push(unavailSlot);
									}
								}
							});
						}); //end daySlotArray
					}); //end service slot

					// const daySlots = serviceSlot.map(day => day.times);

					// // const timeSlots = daySlots.map(daySlot => {

					// // });
					// console.log(bookings);
					// console.log(blockedResourceBooking);

					const blockedSlot = _.map(blockedResourceBooking, (blocked, index) => {
						if (blocked.resource_name === event.eventName) {
							let last_id = slots.length ? slots[slots.length - 1].id : 0;
							let blockedDate = new Date(blocked.datetime);
							let blockedEndDate = new Date(blocked.end_datetime);
							let blockedDate_start_min = blockedDate.getHours() * 60 + blockedDate.getMinutes();
							let blockedDate_end_min = blockedEndDate.getHours() * 60 + blockedEndDate.getMinutes();
							let today = new Date();
							let today_min = today.getHours() * 60 + today.getMinutes();
							let status;

							if (blockedDate < today) {
								status = true;
							} else if (
								moment(blockedDate).format('YYYY/MM/DD') === moment(today).format('YYYY/MM/DD') &&
								blockedDate_start_min < today_min
							) {
								status = true;
							} else {
								status = false;
							}
							let blockedAvailSlot = {
								isAvailable: false,
								start: new Date(blockedDate),
								end: new Date(blockedEndDate),
								price: blocked.price,
								id: blocked.id, // last_id > 0 ? last_id + 1 : index,
								desc: blocked.full_describe,
								blocked: true,
								eventId: event.eventId,
								eventName: event.eventName,
								resourceId: blocked.resource_id,
								past: status,
								start_min: blockedDate_start_min,
								end_min: blockedDate_end_min,
								title: event.eventName,
								card: 0,
								blocked_card: card,
								duration: blocked.duration,
								type: service.duration_unit
							};

							slots.push(blockedAvailSlot);

							return blockedAvailSlot;
						}

						return blocked;
					});
					const bookedSlot = _.map(serviceBooking, (booked, i) => {
						// let lastId = slots.length ? slots[slots.length - 1].id : 0;
						let today = new Date();
						let today_min = today.getHours() * 60 + today.getMinutes();
						let status;
						let bookedDate = new Date(booked.datetime);
						let bookedEndDate = new Date(booked.end_datetime);
						let bookedDate_start_min = bookedDate.getHours() * 60 + bookedDate.getMinutes();
						let bookedDate_end_min = bookedEndDate.getHours() * 60 + bookedEndDate.getMinutes();

						const tenant = _.find(tenants, (member) => member.email === booked.client_email);
						let slotDetail = tenant ? `${tenant.firstName} ${' '} ${tenant.lastName}` : '';

						if (bookedDate < today) {
							status = true;
						} else if (
							moment(bookedDate).format('YYYY/MM/DD') === moment(today).format('YYYY/MM/DD') &&
							bookedDate_start_min < today_min
						) {
							status = true;
						} else {
							status = false;
						}

						let bookedAvailSlot = {
							isAvailable: false,
							start: new Date(bookedDate),
							end: new Date(bookedEndDate),
							price: booked.price,
							id: booked.id, // lastId > 0 ? lastId + 1 : i,
							desc: slotDetail,
							booked: true,
							eventId: event.eventId,
							eventName: event.eventName,
							past: status,
							start_min: bookedDate_start_min,
							end_min: bookedDate_end_min,
							card: card,
							tenant: tenant,
							title: event.eventName,
							questions: booked.questions,
							answers: booked.answers_summary,
							service_name: booked.service_name,
							duration: booked.duration,
							type: service.duration_unit
						};

						slots.push(bookedAvailSlot);
						return bookedAvailSlot;
					});

					const eventsSlotsData = {
						slots: slots
					};

					return eventsSlotsData;
				});

				let slotsData = [];

				let booked_taged = 0;
				const eventsSlot = _.map(slots, (slot) => {
					if (!_.isUndefined(slot.booked) && slot.booked === true) {
						booked_taged = booked_taged + 1;
						let data = {
							id: slot.id,
							isAvailable: slot.isAvailable,
							booked: slot.booked,
							desc: slot.desc,
							end: slot.end,
							start: slot.start,
							start_min: slot.start_min,
							end_min: slot.end_min,
							eventId: slot.eventId,
							eventName: slot.eventName,
							tenant: slot.tenant,
							tag: booked_taged,
							past: slot.past,
							card: slot.card,
							title: slot.eventName,
							questions: slot.questions,
							answers: slot.answers,
							price: slot.price,
							service_name: slot.service_name,
							duration: slot.duration,
							type: service.duration_unit
						};

						slotsData.push(data);
					} else {
						//end if
						//uncoment to remove duplicate from slots list
						// let slot_dupl = _.find(slotsData, { start: slot.start, end: slot.end, start_min: slot.start_min, end_min: slot.end_min });

						// if (_.isUndefined(slot_dupl))
						//   slotsData.push(slot);

						slotsData.push(slot);
					}
				}); //end eventsSlot

				let bookingList = _.map(_.union(bookedBookings, blockedBookings), (o) => {
					return {
						id: o.id,
						email: o.client_email,
						serviceId: o.service_id,
						name: o.client_name,
						resourceName: o.resource_name,
						startDate: dateFormat(o.datetime, 'dd/mm/yyyy'),
						endDate: o.end_datetime,
						duration: o.duration,
						timeFrom: dateFormat(o.datetime, 'shortTime'),
						timeTo: dateFormat(o.end_datetime, 'shortTime'),
						questions: o.questions,
						answers: o.answers_summary,
						tenant: _.find(tenants, {
							email: o.client_email
						}),
						price: o.price
					};
				});

				var slotData = {
					events: events,
					slots: _.sortBy(slotsData, [ 'start' ])
				};

				dispatch({ type: types.EVENTS_SLOTS_FETCHED, slotsData: slotData });
				dispatch({ type: types.RESOURCE_BOOKINGS_FETCHED, bookings: bookingList });
				callback(true);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setSelectedSlot(slot, serviceId, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const basket = await checkoutService.getBasket();

			const selectedEventsSlots = resourcesSelectors.getSelectedServiceSlot(getState());

			const slotSelected = _.find(selectedEventsSlots, {
				id: slot.id
			});

			if (slot && slot.type === 'click')
				dispatch({
					type: types.SLOT_SELECTED,
					slot: slot
				});

			if (slot && slot.type === 'select')
				dispatch({
					type: types.BLOCK_SLOT_SELECTED,
					slot: slot
				});

			dispatch(addToBasketQueue(slot));

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setBlockSelectedSlot(slots, serviceId) {
	return async (dispatch, getState) => {
		try {
			const selectedServiceSlots = resourcesSelectors.getSelectedServiceSlot(getState());

			let start = new Date(slots.start);
			let start_min = start.getHours() * 60 + start.getMinutes();
			let end = new Date(slots.end);
			let end_min = end.getHours() * 60 + end.getMinutes();

			let selectedSlots = {
				start: slots.start,
				end: slots.end,
				slots: slots.slots,
				start_min: start_min,
				end_min: end_min,
				slotsTime: _.map(
					_.filter(slots.slots, (s, i) => {
						let slotTime = new Date(s);
						let time = slotTime.getHours() * 60 + slotTime.getMinutes();

						return time >= start_min && time < end_min;
					}),
					(time_slot) => {
						let slot_Time = new Date(time_slot);
						let tme = slot_Time.getHours() * 60 + slot_Time.getMinutes();
						return tme;
					}
				)
			};

			let prep_slots = _.map(selectedSlots.slotsTime, (timeSlot) => {
				let selectedSlot = _.find(selectedServiceSlots, (servSlot) => servSlot.start_min === timeSlot);
				return selectedSlot;
			});

			dispatch({
				type: types.BLOCK_SLOT_SELECTED,
				slotsinfo: selectedSlots
			});
			for (var index = 0; index < prep_slots.length; index++) {
				dispatch(addToBasketQueue(prep_slots[index]));
			}
		} catch (error) {
			console.error(error);
		}
	};
}

export function deSelectSlot(slot) {
	return async (dispatch, getState) => {
		try {
			const eventId = getState().resources.eventId;

			let block_item = [];
			let blockSelected = resourcesSelectors.getBlockSlotSelected(getState());

			block_item = _.find(blockSelected, (o) => {
				return slot.start_min >= o.start_min && slot.end_min <= o.end_min;
			});

			if (slot.type === 'click')
				dispatch({
					type: types.SLOT_DESELECTED,
					slot
				});

			if (slot.type === 'select')
				dispatch({
					type: types.BLOCK_SLOT_DESELECTED,
					slot: block_item
				});
			// const basket = await checkoutService.getBasket();

			// const itemToRemove = _.find(
			//   basket.items,
			//   o => o.time == slot.start.getHours() * 60 && o.event_id === eventId
			// );

			// if (itemToRemove) {
			//   //propcess remove item from basket queue here
			//   dispatch(checkoutActions.removeItemFromBasket(itemToRemove.id));
			// }

			dispatch({
				type: types.REMOVE_FROM_BASKET_QUEUE,
				slot
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function deselectBlockSlot(slot) {
	return async (dispatch, getState) => {
		try {
			let blockSelected = resourcesSelectors.getBlockSlotSelected(getState());
			let current_basket = resourcesSelectors.getBasketQueue(getState());

			let new_basket = _.filter(current_basket, (item) => {
				let obj = _.find(blockSelected, (block_item) => {
					return _.isUndefined(_.find(blockSelected.slotsTime, (o) => o === block_item.start_min));
				});

				return _.isUndefined(
					_.find(obj.slotsTime, (s) => {
						return s === item.start_min;
					})
				);
			});

			dispatch({
				type: types.BLOCK_SLOT_DESELECTED,
				slot
			});
		} catch (error) {
			console.error(error);
		}
	};
}
export function addToBasketQueue(slot) {
	return {
		type: types.ADD_TO_BASKET_QUEUE,
		slot
	};
}

export function updateBasketQueue(slot, callback = () => {}) {
	return (dispatch, getState) => {
		try {
			if (slot) {
				dispatch({ type: types.BASKET_QUEUE_UPDATED_SUCCESSFULLY, data: slot });
				callback(true);
			} else {
				callback(false);
			}
		} catch (error) {
			callback(false);
		}
	};
}

export function removeFromBasketQueue(slot) {
	return {
		type: types.REMOVE_FROM_BASKET_QUEUE,
		slot
	};
}

export function setServiceId(serviceId) {
	return async (dispatch, getState) => {
		dispatch({
			type: types.SERVICE_ID_SELECTED,
			serviceId
		});
	};
}
export function setRoomsServiceHr() {
	return async (dispatch, getState) => {
		const serviceId = config.MEETING_ROOM_SERVICE_HR_ID;

		dispatch({
			type: types.SERVICE_ID_SELECTED,
			serviceId
		});
	};
}

export function getRoomsServiceId() {
	return config.MEETING_ROOM_SERVICE_HR_ID;
}

export function setParkingServiceHr() {
	return async (dispatch, getState) => {
		const serviceId = config.PARKING_SERVICE_HR_ID;

		dispatch({
			type: types.SERVICE_ID_SELECTED,
			serviceId
		});
	};
}

export function getParkingServiceId() {
	return config.PARKING_SERVICE_HR_ID;
}
export function getEventDate() {
	return async (dispatch, getState) => {
		const eventDate = resourcesSelectors.getEventDate(getState());

		dispatch({
			type: types.EVENT_DATE_SELECTED,
			eventDate
		});
	};
}

export function clearBasketQueue() {
	return async (dispatch, getState) => {
		let clear = [];
		dispatch({
			type: types.CLEAR_BASKET_QUEUE,
			clear: clear
		});
	};
}

export function getServiceBookings(serviceId, date, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await engageBookingServices.getBookings(date, date);

			const list = _.filter(response, (o) => o.service_id === Number(`${serviceId}`));

			const tenants = tenantSelector.getAllTenants(getState());

			let bookings = _.map(list, (o) => {
				return {
					id: o.id,
					email: o.client_email,
					serviceId: o.service_id,
					name: o.client_name,
					resourceName: o.resource_name,
					startDate: dateFormat(o.datetime, 'dd/mm/yyyy'),
					endDate: o.end_datetime,
					duration: o.duration,
					timeFrom: dateFormat(o.datetime, 'shortTime'),
					timeTo: dateFormat(o.end_datetime, 'shortTime'),
					tenant: _.find(tenants, {
						email: o.client_email
					})
				};
			});

			dispatch({
				type: types.RESOURCE_BOOKINGS_FETCHED,
				bookings: bookings
			});

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function getServiceBookings_range(serviceId, startDate, endDate, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const dateRange = getDatesRange(startDate, endDate);

			let bookingsList = [];

			let bookings_range = [];

			//const booking = await resourcesService.getBookings(startDate, endDate);

			const booking = await engageBookingServices.getBookings(startDate, endDate);

			if (booking) bookings_range = booking;

			// for (var b = 0; b < dateRange.length; b++) {

			//   const booking = await resourcesService.getBookings(dateFormat(dateRange[b], "isoDate"), dateFormat(dateRange[b], "isoDate"));

			//   // const booking = await engageBookingServices.getBookings(dateFormat(dateRange[b], "isoDate"), dateFormat(dateRange[b], "isoDate"));
			//   if (booking) {
			//     bookings_range.push(booking);
			//   }
			// }

			_.map(bookings_range, (booking_item) => {
				_.map(booking_item, (item) => {
					bookingsList.push(item);
				});
			});

			// const response = await resourcesService.getBookings(date, date);
			const list = _.filter(bookingsList, (o) => {
				return o !== null && o.service_id === Number(`${serviceId}`);
			});

			const tenants = tenantSelector.getAllTenants(getState());

			let bookings = _.map(list, (o) => {
				return {
					id: o.id,
					email: o.client_email,
					serviceId: o.service_id,
					name: o.client_name,
					resourceName: o.resource_name,
					startDate: dateFormat(o.datetime, 'dd/mm/yyyy'),
					endDate: o.end_datetime,
					duration: o.duration,
					timeFrom: dateFormat(o.datetime, 'shortTime'),
					timeTo: dateFormat(o.end_datetime, 'shortTime'),
					tenant: _.find(tenants, {
						email: o.client_email
					})
				};
			});

			dispatch({
				type: types.RESOURCE_BOOKINGS_FETCHED,
				bookings: bookings
			});

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function addToBookingsSelectionQueue(booking) {
	return {
		type: types.ADD_TO_BOOKINGS_SELECTION_QUEUE,
		booking
	};
}

export function getBookingsCancelReasons() {
	return async (dispatch, getState) => {
		try {
			const cancelReasons = await resourcesService.getBookingCancelReasons();
			dispatch({
				type: types.CANCEL_BOOKINGS_REASONS,
				cancelReasons
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function cancelBooking(reason, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const bookings = resourcesSelectors.getBookingsSelectionQueue(getState());
			let cancelled = [];
			for (var index = 0; index < bookings.length; index++) {
				const cancelBooking = await resourcesService.cancelBooking(bookings[index].id, reason);

				cancelled.push({
					id: cancelBooking.id,
					cancelled: cancelBooking.is_cancelled,
					cancelReason: cancelBooking.settings.cancel_reason
				});
			}

			let bookingsCancelled = _.map(cancelled, (item, index) => {
				let o = _.find(bookings, (booking) => booking.id === item.id && item.cancelled === true);
				return {
					booking: o,
					cancel_reason: item.cancelReason
				};
			});

			dispatch({
				type: types.BOOKINGS_CANCELED_SUCCESS,
				bookingsCancelled
			});
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setBookingsSelectionQueue(booking) {
	return {
		type: types.RESOURCE_BOOKINGS_FETCHED,
		booking
	};
}

export function getServiceAvailability(service_id, date, eventId) {
	return async (dispatch, getState) => {
		try {
			const timesAvailable = await resourcesService.timeAvailability(service_id, date);
			let resource_availability = [];

			if (eventId && !_.isUndefined(eventId) && timesAvailable) {
				resource_availability = _.find(timesAvailable, {
					event_id: Number(eventId)
				});

				let times_avail = _.map(resource_availability.times, (o) => {
					return {
						date: resource_availability.date,
						time: o.time,
						available: o.avail === 1 ? true : false,
						price: o.price
					};
				});

				let resource_time = {
					name: resource_availability.name,
					date: resource_availability.date,
					event_id: resource_availability.event_id,
					resource_id: resource_availability.resource_id,
					times: times_avail
				};
				dispatch({
					type: types.RESOURCE_TIME_AVAILABLE_FETCHED,
					timesAvail: resource_time
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
}

export function upDateBooking(booking, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const bookings = resourcesSelectors.getBookings(getState());

			const status = await resourcesService.updateBooking(
				booking.id,
				booking.date,
				booking.time,
				booking.duration
			);

			if (status) {
				let dateFrom = moment(status.datetime);
				let dateTo = moment(status.end_datetime);
				let from_min = dateFrom.hours() * 60 + dateFrom.minutes();
				let to_min = dateTo.hours() * 60 + dateTo.minutes();
				var updatedBooking = {
					id: status.id,
					date: status.datetime,
					end_date: status.end_datetime,
					duration: to_min - from_min,
					resourceName: status.resourceName,
					timeFrom: dateFrom.format('HH:mm A'),
					timeTo: dateTo.format('HH:mm A')
				};

				let updated = _.map(bookings, (o) => {
					if (o.id === updatedBooking.id) {
						return {
							id: o.id,
							email: o.email,
							serviceId: o.serviceId,
							name: o.name,
							resourceName: updatedBooking.resourceName,
							startDate: moment(updatedBooking.date).format('DD/MM/YYYY'),
							endDate: updatedBooking.end_date,
							timeFrom: updatedBooking.timeFrom,
							timeTo: updatedBooking.timeTo,
							tenant: o.tenant
						};
					}
					return null;
				});
				let bookingUpdated = _.filter(updated, (b) => {
					return b !== null;
				});

				dispatch({
					type: types.UPDATE_BOOKING_SUCCESS,
					bookingStatus: {
						booking: bookingUpdated,
						status: bookingUpdated.length > 0
					}
				});
				callback(true);
			}
		} catch (error) {
			callback(false);
			//console.error(error);
		}
	};
}

export function updateBookingResource(booking, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const bookings = resourcesSelectors.getBookings(getState());
			let data = {
				questions: booking.questions
			};
			const status = await engageBookingServices.updateBookingResource(booking.id, data);

			console.log(status);
			console.log(booking);
			// const status = await resourcesService.updateBookingResource(booking.id, data);

			if (status) {
				let dateFrom = moment(status.datetime);
				let dateTo = moment(status.end_datetime);
				let from_min = dateFrom.hours() * 60 + dateFrom.minutes();
				let to_min = dateTo.hours() * 60 + dateTo.minutes();
				var updatedBooking = {
					id: status.id,
					date: status.datetime,
					end_date: status.end_datetime,
					duration: to_min - from_min,
					resourceName: status.resource_name,
					timeFrom: dateFrom.format('HH:mm A'),
					timeTo: dateTo.format('HH:mm A')
				};

				let updated = _.map(bookings, (o) => {
					if (o.id === updatedBooking.id) {
						return {
							id: o.id,
							email: o.email,
							serviceId: o.serviceId,
							name: o.name,
							resourceName: updatedBooking.resourceName,
							startDate: moment(updatedBooking.date).format('DD/MM/YYYY'),
							endDate: updatedBooking.end_date,
							timeFrom: updatedBooking.timeFrom,
							timeTo: updatedBooking.timeTo,
							tenant: o.tenant
						};
					}
					return null;
				});
				let bookingUpdated = _.filter(updated, (b) => {
					return b !== null;
				});

				dispatch({
					type: types.UPDATE_BOOKING_SUCCESS,
					bookingStatus: {
						booking: bookingUpdated,
						status: bookingUpdated.length > 0
					}
				});
				callback(true);
			} else {
				callback(false);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
export function cancelBookingSingle(id, reason, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const bookings = resourcesSelectors.getBookings(getState());

			// let cancelSingleBooking = await resourcesService.cancelBooking(
			//   id,
			//   reason
			// );
			let cancelSingleBooking = await engageBookingServices.cancelBooking(id, reason);

			let cancelled = [];

			if (cancelSingleBooking && !_.isUndefined(cancelSingleBooking)) {
				cancelled.push({
					id: cancelSingleBooking.id,
					cancelled: cancelSingleBooking.is_cancelled,
					cancelReason: cancelSingleBooking.settings.cancel_reason
				});

				let bookingsCancelled = _.find(bookings, (item) => item.id === cancelSingleBooking.id);

				let val = {
					booking: bookingsCancelled,
					cancel_reason: reason
				};
				dispatch({
					type: types.BOOKINGS_CANCELED_SUCCESS,
					bookingsCancelled: [ val ]
				});
			}

			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function getBookingErrors() {
	return async (dispatch, getState) => {
		try {
			const errors = await resourcesService.getBookingErrors();
			dispatch({
				type: types.BOOKING_ERRORS_LOAD_SUCCESS,
				errors
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function getBookingDetail(mem_id, booking_id, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const detail = await resourcesService.getBookingDetails(mem_id, booking_id);

			dispatch({ type: types.BOOKING_DETAIL_LOAD_SUCCESS, detail: detail });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function setBooking(booking, callback = () => {}) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.SET_BBOOKING_SUCCESS, booking: booking });
			callback(true);
		} catch (error) {
			callback(false);
			console.log(error);
		}
	};
}

export function nofifyCancelBooking(booking, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const portalSettings = serviceModuleSelectors.loadPortalSttings(getState());
			const resource_type = eventsSelector.getPageEvent(getState());
			const cancelation_period =
				!_.isUndefined(portalSettings) && !_.isUndefined(portalSettings.cancellation_period)
					? portalSettings.cancellation_period
					: 0;
			if (booking) {
				let date = moment.utc(booking.startDate + ' ' + booking.timeFrom, 'DD/MM/YYYY HH:mm A');

				let now = new Date();
				let cancel_time_exceded = false;

				if (moment(now).isBefore(date)) {
					let current_diff = moment.duration(date.diff(moment(now)));
					let diff_min = Number(current_diff.asMinutes().toFixed(0));

					//check if time in minutes is within cancellation span in minutes
					if (diff_min > cancelation_period) {
						//within calcellation period

						cancel_time_exceded = false;
					} else {
						cancel_time_exceded = true;
					}
				}

				let data = {
					bookings: [
						{
							id: booking.id,
							date: date,
							timeTo: booking.endDate,
							resourceName: booking.resourceName,
							timeFrom: date,
							price:
								Number(booking.price) > 0
									? parseFloat(Number(booking.price) / 100).toFixed(2)
									: booking.price
						}
					],
					type: resource_type,
					tenant: booking.tenant,
					cancelTimeExceeded: cancel_time_exceded
				};
				const notify = await meetingServices.resourceBookingCancelledNotification(data);

				dispatch({
					type: types.BOOKING_CANCELLED_NOTIFY_SUCCESS,
					status: notify
				});
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function notifyEditBooking(id, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const resource_type = eventsSelector.getPageEvent(getState());
			let booking_prev = resourcesSelectors.getBookings(getState());
			let booking_curr = resourcesSelectors.getBookingUpdated(getState());
			const prevBooking = _.find(booking_prev, (s) => s.id === id);
			const updatedBooking = _.find(booking_curr.booking, (o) => o.id === id);

			if (updatedBooking) {
				let date = moment(updatedBooking.startDate + ' ' + updatedBooking.timeFrom, 'DD/MM/YYYY HH:mm A');
				let prevDate = moment(prevBooking.startDate + ' ' + prevBooking.timeFrom, 'DD/MM/YYYY HH:mm A');
				let prevDateTo = moment(prevBooking.startDate + ' ' + prevBooking.timeTo, 'DD/MM/YYYY HH:mm A');
				let data = {
					bookings: [
						{
							id: updatedBooking.id,
							date: date,
							timeTo: updatedBooking.endDate,
							resourceName: updatedBooking.resourceName,
							timeFrom: date,
							prevTimeFrom: prevDate,
							prevTimeTo: prevDateTo
						}
					],
					type: resource_type,
					tenant: updatedBooking.tenant
				};

				const notify = await meetingServices.resourceBookingUpdatedNotification(data);

				dispatch({
					type: types.BOOKING_UPDATED_NOTIFY_SUCCESS,
					status: notify
				});
				callback(true);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function notifyCreateBooking(booking, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			if (booking) {
				var bookingDetails = [ booking ]; //resourcesSelectors.getBookingCreated(getState());
				const serviceUnit = resourcesSelectors.getServiceUnit(getState());
				const block_selection = resourcesSelectors.getBlockSlotSelected(getState());
				const resources = resourcesSelectors.getServicesSlots(getState());
				const tenants = tenantSelector.getAllTenants(getState());

				let bookings = [];
				let temp_booking = [];

				if (serviceUnit === 'day') {
					bookings = _.map(bookingDetails, (booking) => {
						return {
							resourceName: booking.resource_name,
							price: booking.price,
							date: booking.start,
							timeFrom: booking.start,
							timeTo: booking.end,
							serviceType: serviceUnit
						};
					});

					_.map(bookings, (item) => {
						temp_booking.push(item);
					});
				} else if (serviceUnit === 'minute') {
					var resource = _.find(resources, {
						event_id: booking.eventId,
						date: moment(booking.start).format('YYYY-MM-DD')
					});
					bookings = _.map(bookingDetails, (booking) => {
						let stime = new Date(booking.start);
						let etime = new Date(booking.end);
						let start_min = stime.getHours() * 60 + stime.getMinutes();
						let end_min = etime.getHours() * 60 + etime.getMinutes();
						return {
							resourceName: resource ? resource.name : '',
							price: booking.price,
							date: booking.start,
							timeFrom: booking.start,
							timeTo: booking.end,
							start_min: start_min,
							end_min: end_min,
							serviceType: serviceUnit
						};
					});

					_.map(bookings, (item) => {
						temp_booking.push(item);
					});
				} //end if minutes

				var tenant = _.find(tenants, { bookingBugMemberId: booking.bookingBugMemberId });

				var resourceBooked = {
					bookings: temp_booking,
					tenant: tenant,
					visitor: null,
					type: 'bookings'
				};

				const notify = await meetingServices.resourceBookingNotification(resourceBooked);

				if (notify)
					dispatch({
						type: checkoutTypes.NOTIFICATION_SENT_SUCCESS,
						sendNotification: notify
					});
				else
					dispatch({
						type: checkoutTypes.NOTIFICATION_SENT_FAIL,
						sendNotification: notify
					});

				callback(true);
			} else {
				callback(false);
			}
			// dispatch({ type: checkoutTypes.CHECKOUT_COMPLETED, checkoutData });
			//dispatch({ type: checkoutTypes.BOOKING_COMPLETED, details });
		} catch (error) {
			console.error(error);
		}
	};
}

export function setBookingCreated(booking, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.RESOURCE_BOOKED_SUCCESS, booking });
			callback(true);
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function updateSlotsEventSelected(event, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.BOOKED_EVENT_SLOT_IS_SELECTED, event });
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

export function blockResource(eventId, startDate, endDate, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const unit_type = resourcesSelectors.getServiceUnit(getState());
			let servicesSlots = [];
			let resource = undefined;

			if (unit_type === 'minute') {
				servicesSlots = getState().resources.servicesSlots;
			} else if (unit_type === 'day') {
				servicesSlots = resourceMonthlySelectors.getServicesSlots(getState());
			}
			resource = _.find(servicesSlots, (res) => res.event_id === eventId);

			if (!_.isUndefined(resource)) {
				// const result = await resourcesService.blockResource(resource.resource_id, startDate, endDate);
				const result = await engageBookingServices.blockResource(resource.resource_id, startDate, endDate);
				dispatch({ type: types.RESOURCE_BLOCKED_SUCCESS, resource: result });
				callback(true);
			} else {
				callback(false);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}

function getDatesRange(startDate, endDate) {
	var dateRange = [];
	var currentDate = new Date(moment(startDate).format('YYYY-MM-DD'));
	var end = new Date(moment(endDate).format('YYYY-MM-DD'));

	let current = currentDate;
	for (var d = current; d <= end; d.setDate(d.getDate() + 1)) {
		dateRange.push(new Date(d));
	}

	return dateRange;
}

export function emptyServicesSlots() {
	return async (dispatch, getState) => {
		try {
			let empty = [];
			var slotData = {
				events: [],
				slots: empty,
				bookings: empty
			};
			dispatch({ type: types.RESOURCE_EVENTS_CLEARED, slotsData: slotData });
		} catch (error) {
			console.error(error);
		}
	};
}

export function setActionRedirect(val) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.ACTION_REDIRCECT_SUCCESS, status: val });
		} catch (error) {}
	};
}

export function setPaymentAction(val) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.PAYMENT_ACTION_SUCCESS, paymentStatus: val });
		} catch (error) {}
	};
}

export function setCarlendarSettings(data) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.CALENDAR_SETTINGS_SET, settings: data });
		} catch (error) {
			console.error(error);
		}
	};
}

export function resourceProcessingStatus(status) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.PROCESSING_RESOURCE_REQUEST, status: status });
		} catch (error) {}
	};
}
