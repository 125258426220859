import React, { Component } from "react";

import autobind from "react-autobind";
import { connect } from "react-redux";

import _ from "lodash";
import "../../sass/components/disabledModule/disabledModule.css";

var PropTypes = require('prop-types');

const sub_menu_styles = {
  menu_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 5 + "px",
    width: 100 + "%",
    height: 145 + "px"
  },
  menu_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 100 + "%",
    height: 64 + "px",
    textAlign: "center",
    border: "1px solid #abadb0",
    flex: 1
  },
  non_landed_item: {
    backgroundColor: "#ffffff"
  }
};

class ModuleMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHover: [],
      portalType: ""
    };

    autobind(this);

  }

  componentDidUpdate(prevProps, prevState){
    if (
      prevProps.modules !== this.props.modules &&
      !_.isUndefined(this.props.modules)
    ) {
      this.setState({
        isHover: _.map(this.props.modules, item => {
          return false;
        })
      });
    }
  }
 

  hoveredStyle(color, i) {
    if (this.state.isHover[i] === true) {
      return {
        backgroundColor: color,
        color: "#ffffff"
      };
    } else {
      return { backgroundColor: "#ffffff" };
    }
  }
  onMouseOver(i) {
    return () => {
      if (this.state.isHover[i] === true) {
        return this.state;
      }
      let isHover = [...this.state.isHover];
      isHover[i] = true;
      this.setState({ ...this.state, isHover });
    };
  }
  onMouseOut(i) {
    if (this.state.isHover[i] === false) {
      return this.state;
    }
    let isHover = [...this.state.isHover];
      isHover[i] = false;
    this.setState({ ...this.state, isHover: isHover });
  }
  render() {
   
    return (
      <div style={sub_menu_styles.menu_container} className="middleNav">

        {
          _.map(this.props.modules, (item, index) => {
            return (
              <div
                key={index}
                onMouseOver={this.onMouseOver(index)}
                onMouseOut={()=>this.onMouseOut(index)}
                style={_.assign(
                  this.hoveredStyle(item.imageOverlayTextColour, index),
                  this.props.selectedModule === item.title
                    ? _.assign(
                      { backgroundColor: item.imageOverlayTextColour },
                      sub_menu_styles.menu_item
                    )
                    : sub_menu_styles.menu_item
                )}
                className={item.title + "-button"}
                onClick={e => this.props.handleModuleMenuSelected(item.title)}
              >
                <span style={{ color: this.props.selectedModule && this.props.selectedModule === item.title ? "#ffffff" : "" }}> {item.title}</span>
              </div>
            );
          })


        }

      </div>
    );
  }
}

ModuleMenu.propTypes = {
  modules: PropTypes.array.isRequired,
  handleModuleMenuSelected: PropTypes.func.isRequired,
  selectedModule: PropTypes.string
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(ModuleMenu);
