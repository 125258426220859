import React, { Component } from "react";
import { connect } from "react-redux";
import * as authActions from "../store/auth/actions";
import * as authSelectors from "../store/auth/reducer";
import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../components/FooterNavigation/FooterNavigation";
import * as settingActions from "../store/ConciergeSetting/actions";
import * as settingSelector from "../store/ConciergeSetting/reducer";
import autoBind from "react-autobind";
import LoginForm from "../components/LoginForm/LoginForm";
import { createBrowserHistory } from 'history';

import "../sass/static/logout.css";
import "../sass/static/forgotPassword.css";

const browserHistory = createBrowserHistory();
const location = browserHistory.location;
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      emailFormSuccesssfull: null,
      emailSuccessfull: null
    };
    autoBind(this);
    this.props.dispatch(settingActions.getGlobalSetting());
  }

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      browserHistory.push("/");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.emailVerify !== nextProps.emailVerify) {
      this.setState({
        emailFormSuccesssfull: nextProps.emailVerify
      });
    }
  }
  handleEmailChange(e) {
    this.props.dispatch(authActions.setEmail(e.target.value));
  }

  handlePasswordChange(e) {
    e.target.value.length < 8
      ? this.setState({ error: true })
      : this.setState({ error: false });

    this.props.dispatch(authActions.setPassword(e.target.value));
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.dispatch(
      authActions.ResetPasswordEmail(this.props.email, success => {
        if (success) {
          this.setState({
            emailSuccessfull: success
          });
          setTimeout(() => {
            browserHistory.push("/passwordrecoverysent");
          }, 0);
        } else {
          this.setState({
            emailSuccessfull: false
          });
          this.props.dispatch(authActions.setRequestStatus(false));
        }
      })
    );
  }
  render() {

    const { settings } = this.props;
    let bgImage = "";
    if (settings) {
      bgImage = settings.mainBackgroundImage;
    }
    const bgStyle = {
      backgroundImage: "url('" + bgImage + "')",
      backgroundRepeatY: "no-repeat",
      backgroundSize: "cover"
    };


    return (
      <div className="loginScreen forgotPass">
        <HeaderNavigation closed={true} isLoggedIn={false} setting={settings} />

        <div className="loginWrap" style={bgStyle}>
          <h2 className="loginScreen">Password Recovery</h2>
        </div>

        <div className="main-wrp-login">
          <div className="login-form-wrapper">
            <LoginForm
              handleEmailChange={this.handleEmailChange}
              handlePasswordChange={this.handlePasswordChange}
              password={this.props.password}
              username={this.props.password2}
              handleSubmit={this.handleSubmit}
              actionSuccess={this.state.emailSuccessfull}
              mode={"email"}
            />
          </div>
        </div>

        <FooterNavigation />
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    password: authSelectors.getPassword(state),
    email: authSelectors.getEmail(state),
    emailVerify: authSelectors.emailVerifyStatus(state),
    settings: settingSelector.loadGlobalSetting(state)
  };
}

export default connect(mapStateToProps)(ForgotPassword);
