import React, { Component } from "react";

import autoBind from "react-autobind";
import _ from "lodash";

import "../../sass/components/Parcel/index.css";

var PropTypes = require('prop-types');

class ParcelResultComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {

      btnStatus: "unchecked hidden",
      btnText: "Check-out",
      parcelResultComponentHeight: "144",
      toggleHideParcelResult: "show",
      toggleHideParcelResultOverlay: "hide"

    };
    autoBind(this);
  }



  handleClick(e) {
    this.setState({
      toggleHideParcelResult: "hide",
      toggleHideParcelResultOverlay: "show"
    });

  }

  handleYesButtonClick() {


    this.setState({
      toggleHideParcelResult: "show",
      toggleHideParcelResultOverlay: "hide"
    });

  }

  handleNoButtonClick(e) {

    this.setState({
      toggleHideParcelResult: "show",
      toggleHideParcelResultOverlay: "hide"
    });

  }


  componentDidMount() {


    const height = this.parcelElement.offsetHeight;

    if (this.parcelOverlayElement) {
      if (height > 144) {
        this.parcelOverlayElement.style.alignItems = "center";
      }
      else {
        this.parcelOverlayElement.style.alignItems = "none";
      }

    }


    this.setState({
      parcelResultComponentHeight: height,
    });
  }


  render() {
    const {

      tenantName,
      tenantAddress,
      barcodeId,
      parcelReceivedDate,
      location,
      moduleColor,
      showoverlay,
      id
    } = this.props;



    return (
      <div>
        <div className={"parcel-search-result " + this.state.toggleHideParcelResult} ref={parcelElement => { this.parcelElement = parcelElement }}>
          <div className="parcel-row">
            <div className="parcel-heading">Tenant</div>
            <div className="parcel-detail">
              <span style={{ color: moduleColor }} className="name">{tenantName}</span>
              <span className="address">{tenantAddress}</span>
              {/* {portalType === PortalTypes.Commercial ?
                <span className="address">{companyName}</span>
                : <span className="address">{tenantAddress}</span>} */}
            </div>
            <div className="parcel-lastsection">
              <span className="location">Location</span>
              <span style={{ color: moduleColor }} className="time">{location}</span>
            </div>
          </div>

          <div className="parcel-row">
            <div className="parcel-heading">Barcode</div>
            <div className="parcel-detail">
              <span className="barcode-name">{(barcodeId !== null && barcodeId !== "" && (!_.isUndefined(barcodeId))) ? barcodeId : "Not Available"}</span>
              <span className="address">{parcelReceivedDate}</span>

            </div>
            <div className="parcel-lastsection">
              <button className="checkOut-btn" onClick={e => {
                if (showoverlay === true) {
                  this.handleClick(e);
                } else {
                  this.props.handleItemClick && this.props.handleItemClick(id);
                }
              }}>{this.props.btnText}</button>
            </div>
          </div>

        </div>
        {
          showoverlay && showoverlay === true &&
          <div className={"parcel-search-overlay " + this.state.toggleHideParcelResultOverlay} style={{ height: this.state.parcelResultComponentHeight + "px" }} ref={parcelOverlayElement => { this.parcelOverlayElement = parcelOverlayElement }}>
            <div className="parcel-search-overlay-section">
              <div className="overlay-first-section">
                <span className="card-title">Do you wish to check-out this parcel?</span>
                <span style={{ color: moduleColor }} className="overlay-barcode">{(barcodeId !== null && barcodeId !== "" && (!_.isUndefined(barcodeId))) ? barcodeId.length > 60 ? (barcodeId.substring(0, 60) + '....') : barcodeId : "Not Available"}</span>
              </div>
              <div className="overlay-second-section">
                <span className="card-title">Tenant</span>
                <span style={{ color: moduleColor }} className="address">{tenantName} {tenantAddress ? " - " + tenantAddress : ""}</span>
              </div>
              <div className="overlay-third-section">
                <button className="yes-btn" onClick={() => {
                  this.props.handleSubmit(id);
                  this.handleYesButtonClick();
                }}>Yes</button>


                <button className="no-btn" onClick={this.handleNoButtonClick}>No</button>
              </div>
            </div>
          </div>
        }

      </div>
    );

  }
}

ParcelResultComponent.propType = {

  /** Text displayed on the Component to display the tenant Name*/
  tenantName: PropTypes.string,

  /** Text to display the Address*/
  tenantAddress: PropTypes.string,

  /** Text to display the barcode Id*/
  barcodeId: PropTypes.string,

  /** Text to display the Parcel received on Date*/
  parcelReceivedDate: PropTypes.string,

  /** Text to display the parcel location */
  location: PropTypes.string,

  /** Text to display the Button Text */
  btnText: PropTypes.string,

  /** Text to apply the module color*/
  moduleColor: PropTypes.string,

  /** Text to display the tenant address line 1*/
  tenantAddressLine1: PropTypes.string,

};



export default ParcelResultComponent;