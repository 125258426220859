import React, { Component } from "react";
import InputComponent from "../../components/Input/Input";
import Button from "../../components/Button/Button";
//import { browserHistory, Link } from "react-router";
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import * as $ from "jquery";
import autoBind from "react-autobind";
import _ from "lodash";

import "../../sass/components/LoginForm/index.css";

// import Button from "../Button/Button";
let history = createBrowserHistory();
class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.loginFormValidation = null;
    this.loginFormUsed = null;

    this.state = {};

    autoBind(this);
  }

  componentDidMount() {
    this.addValidation();
  }

  // componentWillUnmount() {
  // // this.loginFormValidation.destroy();
  // }

  addValidation() {
    this.loginFormValidation = $(this.refs.validate).parsley({
      classHandler: function(el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }
  validate(fromSubmit = false, e = null) {
    this.loginFormValidation.validate({ force: true });

    const valid = this.loginFormValidation.isValid();

    this.setState({
      loginFormValid: valid,
      loginFormUsed: true
    });

    if (valid === true && fromSubmit === true) {
      this.props.handleSubmit && this.props.handleSubmit(e);

      this.setState({
        loginFormUsed: false
      });
    }
  }

  render() {
    let buttonText = "";

    switch (this.props.mode) {
      case "login":
        buttonText = "Login";

        break;
      case "newPwd":
        buttonText = "Save New Password";

        break;
      case "email":
        buttonText = "Reset Password";

        break;
      default:
        break;
    }
    return (
      <div className="login-form">
        <form
          ref="validate"
          onKeyPress={e => {
            e.key === "Enter" && this.validate(true, e);
          }}
          data-parsley-errors-container="#validation-messages"
          data-parsley-focus="none"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div
            className={
              this.props.actionSuccess === null &&
              this.state.loginFormUsed !== true
                ? "inputs"
                : "inputs bold"
            }
          >
            {this.props.mode === "login" && (
              <InputComponent
                label="Username"
                className="test"
                placeHolder={
                  this.props.actionSuccess === null &&
                  this.state.loginFormUsed !== true
                    ? "User Name"
                    : "Enter a valid user name"
                }
                name="name"
                value={this.props.email}
                handleChange={e => this.props.handleEmailChange(e)}
                parsley={{
                  "data-parsley-minlength": "2",
                  //"data-parsley-maxlength": "100",
                  "data-parsley-required": true,
                  "data-parsley-error-message": "Enter a valid user name"
                }}
              />
            )}

            {this.props.mode === "email" && (
              <InputComponent
                label="Email"
                type="email"
                placeHolder="Enter your email"
                name="name"
                value={this.props.email}
                handleChange={e => this.props.handleEmailChange(e)}
                parsley={{
                  "data-parsley-required": true,
                  "data-parsley-error-message": "Enter your email",
                  "data-parsley-type": "email"
                }}
              />
            )}

            {this.props.mode !== "email" && (
              <InputComponent
                label={
                  this.props.mode === "login"
                    ? "Password"
                    : "Enter new password"
                }
                placeHolder={
                  this.props.actionSuccess === null &&
                  this.state.loginFormUsed !== true
                    ? "Your Password"
                    : "Enter a valid password"
                }
                name="password"
                type="password"
                handleChange={e => this.props.handlePasswordChange(e)}
                parsley={{
                  "data-parsley-minlength": "2",
                  "data-parsley-maxlength": "15",
                  "data-parsley-required": true,
                  "data-parsley-error-message": ""
                }}
              />
            )}
            {this.props.mode === "newPwd" && (
              <div>
                {
                  <InputComponent
                    label="Confirm new password"
                    placeHolder="Confirm new password"
                    name="password2"
                    type="password"
                    parsley={{
                      "data-parsley-required": true,
                      "data-parsley-error-message":
                        "Please enter a matching password",
                      "data-parsley-equalto": "#form-input-password"
                    }}
                  />
                }
              </div>
            )}
          </div>

          <div
            className={
              "validation-messages" +
              (this.state.loginFormValid ? " valid" : " invalid")
            }
            id="validation-messages"
          >
            <div>
              {/*this.props.actionSuccess === null && this.state.loginFormUsed ? (
                <p className="message type-error">
                  Oops, wrong username or password
                </p>
              ) : (
                ""
              )*/}

              {this.props.mode === "login" &&
                (_.isUndefined(this.props.actionSuccess) === false &&
                  this.props.actionSuccess === false) && (
                  <p className="message type-error">
                    Oops, wrong username or password
                  </p>
                )}

              {/* {this.props.mode === "login" &&
                (_.isUndefined(this.props.actionSuccess) === true &&
                  this.props.actionSuccess === true) && (
                  <p className="message type-success">
                    Login successful. Loading homepage…
                  </p>
                )} */}

              {this.props.mode === "login" &&
                (!_.isUndefined(this.props.actionSuccess) === false &&
                  this.props.actionSuccess === true) && (
                  <p className="message type-success">
                    Login successful. Loading homepage…
                  </p>
                )}

              {this.props.mode === "email" &&
                (!_.isUndefined(this.props.actionSuccess) === true &&
                  this.props.actionSuccess === true) && (
                  <p className="message type-success">
                    Please check your inbox
                  </p>
                )}

              {this.props.mode === "email" &&
                (!_.isUndefined(this.props.actionSuccess) &&
                  this.props.actionSuccess === false) && (
                  <p className="message type-success">
                    An email will be dispatched providing it is registered on
                    our system
                  </p>
                )}
            </div>
          </div>

          <div className="btnHolder">
            <Button
              className="button"
              handleClick={e => this.validate(true, e)}
              text={buttonText}
              size="medium"
            />
            <p>
              <Link
                to=""
                onClick={() => {
                  history.push("/forgotpassword");
                }}
              >
                <small>Forgot password?</small>
              </Link>
            </p>
          </div>

          {
            //   <div className="form-group">
            //   <label htmlFor="email">Email address</label>
            //   <input
            //     type="text"
            //     name="email"
            //     value={this.props.email}
            //     placeholder="name@domain.com"
            //     onChange={e => this.props.handleEmailChange(e)}
            //   />
            // </div>
            // <div className="form-group">
            //   <label htmlFor="password">Password</label>
            //   <input
            //     className={this.props.error ? "error" : ""}
            //     type="password"
            //     name="password"
            //     value={this.props.password}
            //     placeholder="Your password"
            //     onChange={e => this.props.handlePasswordChange(e)}
            //   />
            //   {this.props.invalidPassword ? (
            //     <span className="error error-message">Invalid password</span>
            //   ) : null}
            // </div>
            // <div className="form-group center">
            //   <button
            //     className="button"
            //     type="formButton"
            //     style={{ backgroundColor: "#FF5F2E" }}
            //   >
            //     LOGIN
            //   </button>
            // </div>
          }
        </form>
      </div>
    );
  }
}

export default LoginForm;
