import _ from "lodash";
import * as config from "../config";
import decode from "jwt-decode";
//import { browserHistory } from "react-router";
const TOKEN_KEY = "bbug_authToken";
const PROFILE_KEY = "profile";

class LoginService {

    async login(accountEmail, accountPassword, companyId) {
        const url = `${config.ENGAGE__BOOKINGBUG_LOGIN_ENDPOINT}`;

        const reqBody = {
            email: accountEmail,
            password: accountPassword,
            id: companyId
        };

        var reqHeaders = new Headers();
        reqHeaders.append("Content-Type", "application/json");
        //   reqHeaders.append("App-Key", config.APP_KEY);
        //   reqHeaders.append("App-Id", config.APP_ID);
        //   reqHeaders.append("Cache-Control", "no-cache");

        const response = await fetch(url, {
            method: "POST",
            headers: reqHeaders,
            body: JSON.stringify(reqBody)
        });

        if (!response.ok) {
            config.errorPageRedirect(response);
            throw new Error(
                `LoginService login failed, HTTP status ${response.status}`
            );
        }

        const data = await response.json();
        this.setToken(_.get(data, "auth_token"));
        this.setProfile(PROFILE_KEY, companyId);
        return {
            authToken: _.get(data, "auth_token")
        };
    }

    async getCompanyId() {
        // const url = config.COMPANY_ID_ENDPOINT;

        // const response = await fetch(url, {
        //   method: "GET"
        // });

        // if (!response.ok) {
        //   config.errorPageRedirect(response);
        //   throw new Error(
        //     `LoginService getStoreId failed, HTTP status ${response.status}`
        //   );
        // }

        //const data = await response.json();
        this.setProfile(PROFILE_KEY, config.COMPANY_ID);
        return config.COMPANY_ID;
    }


    _validateUrl(url = "") {
        return url.startsWith("http") ? url : undefined;
    }

    setToken(token) {
        localStorage.setItem(TOKEN_KEY, token);
    }

    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    }

    getProfile() {
        return localStorage.getItem(PROFILE_KEY);
    }

    setProfile(key, data) {
        localStorage.setItem(key, data);
    }

    getTokenExpirationDate(encodedToken) {
        const token = decode(encodedToken);

        if (!token.date_expires) {
            return null;
        }
        const date = new Date(Date.parse(token.date_expires));

        return date;
    }

    logout() {
        localStorage.removeItem(TOKEN_KEY);
    }
}

export default new LoginService();
