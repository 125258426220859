import React, { Component } from "react";
import { connect } from "react-redux";
import autoBind from "react-autobind";
import { createBrowserHistory } from 'history';
import {withRouter} from 'react-router-dom';
import * as $ from "jquery";

import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import _ from "lodash";
//import Datepicker from "react-datepicker";

import moment from "moment";
import dateFormat from "dateformat";

import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";

import TenantAddrTypeAutoSuggest from "../../../components/TenantAutoSuggest/TenantAddrTypeAutoSuggest";
import TenantCompanyTypeAutoSuggest from "../../../components/TenantAutoSuggest/TenantCompanyTypeAutoSuggest";

import Button from "../../../components/Button/Button";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputNewComponent from "../../../components/Input/InputNew";


import DetailListWithOverlay from "../../../components/DetailList/DetailListWithOverlay";

import * as serviceModulesActions from "../../../store/ServiceModules/actions";
import * as settingSelector from "../../../store/ConciergeSetting/reducer";

import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import * as tenantActions from "../../../store/Tenants/actions";
import * as tenantSelector from "../../../store/Tenants/reducer";
import * as meetingAction from "../../../store/Meetings/actions";
import * as meetingSelector from "../../../store/Meetings/reducer";

import * as visitorSelector from "../../../store/Visitors/reducer";
import * as visitorActions from "../../../store/Visitors/actions";

import "../../../sass/containers/visitors/index.css";


function generateOptions(length, excludedOptions) {
    const arr = [];
    for (let value = 0; value < length; value++) {
        if (excludedOptions.indexOf(value) < 0) {
            arr.push(value);
        }
    }
    return arr;
}
function disabledMinutes(h) {
    var min_arr = [0];
    for (let value = 0; value < 60; value++) {
        let curr_min = value + 5;
        if (curr_min < 60 && (curr_min % 5 === 0)) {
            min_arr.push(curr_min);
        }
    }
    switch (h) {
        default:
            return generateOptions(60, min_arr);
    }
}

const PortalTypes = serviceModulesActions.getPortalTypes();

const StatusMessages = {
    Booked: "Visitors successfully booked",
    Updated: "Visitors successfully updated",
    UpdatedList: "Visitor updated"
};

const browserHistory = createBrowserHistory();
const location = browserHistory.location;
class VisitorBooking extends Component {


    constructor(props) {
        super(props);
        this.state = {
            value: "",
            name: "",
            suggestions: [],
            tenantId: "",
            tenantCompany: "",
            tenantName: "",
            tenantAddress: "",
            tenantType: "",
            startDate: moment(),
            timeFrom: moment(),
            timeTo: moment().add(1, 'hours'),
            now: moment().format("LLLL"),
            addVisitorFormUsed: false,
            visitorName: "",
            visitorCompany: "",
            visitorEmail: "",
            rows: [0],
            type: "Visitors",
            specialInstruction: "",
            commercial: false,
            portalType: this.props.setting && !_.isUndefined(this.props.setting.portalType) ? this.props.setting.portalType : "",
            isSucessfull: undefined,
            IsAddMutipleEnable: false,
            isCheckboxChecked: false,
            isMeetindAdded: false,
            message: ""
        };

        this.addVisitorValidation = null;
        // this.addDatepickerValidation = null;
        autoBind(this);
    }


    componentDidMount() {


        this.props.dispatch(meetingAction.clearMeetings());
        const { specialInstruction } = this.state;

        /** Just to get it all working */

        this.addValidation();
        this.props.dispatch(tenantActions.getAllTenants());

        if (specialInstruction === "" && this.props.instruction) {
            this.setState({
                specialInstruction: this.props.instruction
            });
        }


    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (this.props.setting !== nextProps.setting && !_.isUndefined(nextProps.setting)) {
            this.setState({
                portalType: nextProps.setting.portalType
            });
        }

        //triggered when new meetings record are added and checkedin, send email notifications.
        if(this.props.visitorCheckedIn !== nextProps.visitorCheckedIn && nextProps.visitorCheckedIn && nextProps.visitorCheckedIn.length > 0){

            this.props.dispatch(
                meetingAction.notifyCheckedIn(nextProps.visitorCheckedIn, (status) => {
                    if (status === true) {
                    }
                })
            );
        }
        

    }

    componentWillUnmount() {
        this.addVisitorValidation.destroy();
        this.props.dispatch(meetingAction.clearMeetings());
        this.props.dispatch(meetingAction.setMettingsQueue([]));
        this.props.dispatch(visitorActions.resetVisitorCheckedIn());
        // this.addDatepickerValidation.destroy();
    }




    UNSAFE_componentWillUpdate(nextProps, nextState) {

        if ((this.props.meetings !== nextProps.meetings) && (this.state.addSingleMeeting !== nextState.addSingleMeeting) && nextState.addSingleMeeting === true) {

            this.props.dispatch(meetingAction.createNewMeetings(nextProps.meetings, success => {
                if (success === true) {
                    //this.props.dispatch(meetingAction.setMettingsQueue([]));
                    this.setState({
                        addSingleMeeting: undefined,
                        isVistorSave: true,
                        isMeetindAdded: true
                    })
                }
            }));
        }

        // After the meetings are added listened to newly created meetings and checked them In

        if (this.props.meetingsAdded !== nextProps.meetingsAdded && nextProps.meetingsAdded.length > 0) {

            this.props.dispatch(visitorActions.checkinVisitor(nextProps.meetingsAdded, success => {
                if (success === true) {
                    this.setState({
                        visitorName: "",
                        visitorEmail: "",
                        visitorCompany: "",
                        selectedStatus: undefined,
                        selected: undefined,
                        isSucessfull: true,
                        message: "Visitors Successfully Checked-in",
                        timeFrom: moment(),
                        timeTo: moment().add(1, 'hours')

                    });

                    this.resetAutoSelect();
                    this.props.dispatch(meetingAction.setMettingsQueue([]));

                }
            }));


        }
    }


    addValidation() {
        this.addVisitorValidation = $(this.refs.validate).parsley({
            classHandler: function (el) {
                return el.$element.closest("div:not(.input-wrapper):not(.Select)");
            }
        });

        // this.addDatepickerValidation = $(this.refs.validate).parsley({
        //   classHandler: function(el) {
        //     return el.$element.closest("input");
        //   }
        // });

        // this.addVisitorValidation = $("pickeryDate").parsley({
        //   classHandler: function(el) {
        //     return el.$element.closest("input");
        //   }
        // });

    }

    resetValidation() {
		$(this.refs.validate).get(0).reset();
		this.addVisitorValidation.destroy();
    }
    
    validate(fromSubmit = false) {
        this.addVisitorValidation.validate({ force: true });
        // this.addDatepickerValidation.validate({ force: true });
        const valid = this.addVisitorValidation.isValid();
        // this.addVisitorValidation.isValid() &&
        // this.addDatepickerValidation.isValid();

        this.setState({
            addVisitorFormValid: valid,
            addVisitorFormUsed: true,
            rows: this.state.rows.concat([this.state.rows.length]),
            isVistorSave: false,

        });

        if (valid === true && fromSubmit === true) {
            this.formReset();
            const {

                timeFrom,
                startDate,
                timeTo,
                tenantId,
                visitorName,
                visitorCompany,
                tenantCompany,
                tenantName,
                tenantType,
                visitorEmail,
                rows,
                specialInstruction
            } = this.state;

            const timeFromExpected = dateFormat(timeFrom, "isoTime");
            const timeToExpected = dateFormat(timeTo, "isoTime");
            const dateExpected = dateFormat(startDate._d, "isoDate");

            let dueDT = moment(dateExpected + " " + timeFromExpected);
            let toDT = moment(dateExpected + " " + timeToExpected);

            if (this.state.selectedStatus === true) {
                const { meetings } = this.props;

                var data = {
                    tenantId: tenantId,
                    tenantName: tenantName,
                    tenantCompany: tenantCompany,
                    tenantType: tenantType,
                    name: visitorName,
                    companyName: visitorCompany,
                    dueDate: dueDT.format("YYYY-MM-DD HH:mm:ss"),
                    endDate: toDT.format("YYYY-MM-DD HH:mm:ss"),
                    status: "notified",
                    email: visitorEmail,
                    specialInstruction: specialInstruction,

                };


                let setMeetings = _.map(meetings, (meeting, index) => {
                    if (meeting.index === this.state.selected) {
                        return {
                            ...data, index: meeting.index
                        }
                    }
                    return meeting
                });

                this.props.dispatch(meetingAction.setMettingsQueue(setMeetings));
                this.setState({
                    isSucessfull: true,
                    message: StatusMessages.UpdatedList
                });
            } else {
                this.setState({
                    rows: this.state.rows.concat([this.state.rows.length])
                });


                var data_ = {
                    tenantId: tenantId,
                    tenantName: tenantName,
                    tenantCompany: tenantCompany,
                    tenantType: tenantType,
                    name: visitorName,
                    companyName: visitorCompany,
                    dueDate: dueDT.format("YYYY-MM-DD HH:mm:ss"),
                    endDate: toDT.format("YYYY-MM-DD HH:mm:ss"),
                    status: "notified",
                    email: visitorEmail,
                    specialInstruction: specialInstruction,
                    index: rows[rows.length - 1]
                };


                this.props.dispatch(meetingAction.addMeetingToQueue([data_]));

                this.setState({
                    isMeetindAdded: true,
                    isSucessfull: false
                });

            }
        }
    }

    MultipleVisitors() {

        this.props.dispatch(meetingAction.clearMeetings());
        this.props.dispatch(meetingAction.setMettingsQueue([]));
        this.setState({
            IsAddMutipleEnable: !this.state.IsAddMutipleEnable,
            isSucessfull: false,
            message: "",
            selectedStatus:undefined
        })


        //   this.setState({
        //     visitorCompany: "",
        //     isCheckboxChecked: true
        // });
    }


    addMeetingToStore() {

        this.addVisitorValidation.validate({ force: true });
        const valid = this.addVisitorValidation.isValid();
        if (valid === true) {
            this.formReset();
            const {

                timeFrom,
                startDate,
                timeTo,
                tenantId,
                visitorName,
                visitorCompany,
                tenantCompany,
                tenantName,
                tenantType,
                visitorEmail,
                rows,
                specialInstruction
            } = this.state;

            const timeFromExpected = dateFormat(timeFrom, "isoTime");
            const timeToExpected = dateFormat(timeTo, "isoTime");
            const dateExpected = dateFormat(startDate._d, "isoDate");

            let dueDT = moment(dateExpected + " " + timeFromExpected);
            let toDT = moment(dateExpected + " " + timeToExpected);

            var data = {
                tenantId: tenantId,
                tenantName: tenantName,
                tenantCompany: tenantCompany,
                tenantType: tenantType,
                name: visitorName,
                companyName: visitorCompany,
                dueDate: dueDT.format("YYYY-MM-DD HH:mm:ss"),
                endDate: toDT.format("YYYY-MM-DD HH:mm:ss"),
                status: "notified",
                email: visitorEmail,
                specialInstruction: specialInstruction,
                index: rows[rows.length - 1]
            };


            this.props.dispatch(meetingAction.addMeetingToQueue([data], success => {

                this.setState({ addSingleMeeting: true });

            }));


            // this.setState({
            //   visitorCompany:"",
            //   isCheckboxChecked:true,
            //   isMeetindAdded:true,
            // })
        }
    }



    formReset() {
        // Doesnt seem to be working....
        // this.addParcelValidation.reset();


        this.setState({
            visitorName: "",
            visitorEmail: "",
            visitorCompany: "",
            selectedStatus: undefined,
            selected: undefined,
            message: "",
            timeFrom: moment()
        });
        // this.refs["tenant-auto-suggest"].getWrappedInstance().reset();

        $(this.refs.validate)
            .get(0)
            .reset();

        this.addVisitorValidation.destroy();
        //this.addDatePickerValidation.destroy();

        // Reset all the select input components to the placeholder value
        for (let key in this.refs) {
            if (key.indexOf("formSelect") === 0) {
                this.refs[key].reset();
            }
        }

        setTimeout(() => {
            this.addValidation();
        }, 0);
    }

    resetAutoSelect() {

        this.setState({
            tenantCompany: "",
            tenantAddress: ""
        })
        this.refs["tenant-auto-suggest"].getWrappedInstance().reset();

        setTimeout(() => {
            this.addValidation();
        }, 0);
    }

    handleSubmit(e) {



        if (this.state.IsAddMutipleEnable) {
            // if (specialInstruction) {
            //   this.props.dispatch(
            //     meetingAction.addSpectialInstruction(specialInstruction)
            //   );
            // }

            const { meetings } = this.props;
            this.props.dispatch(meetingAction.createNewMeetings(meetings, success => {
                if (success === true) {
                    this.resetAutoSelect();
                    //this.props.dispatch(meetingAction.setMettingsQueue([]));
                    this.setState({
                        message: "",
                        isVistorSave: true,
                        isMeetindAdded: true,
                        tenantId: "",
                        tenantName: "",
                        tenantCompany: "",
                        tenantType: "",
                        //startDate: moment(),
                        timeFrom: moment(),
                        timeTo: moment().add(1, 'hours')

                    })
                }
            }));
        }
        else {
            this.addMeetingToStore();

        }


    }


    removeFromQueue(index) {
        //index
        this.props.dispatch(meetingAction.removeMeetingFromQueue(index));

    }



    IsCheckinVisitorsDisabled() {

        if (this.state.isCheckboxChecked) {
            if (this.state.tenantId !== "" && this.state.visitorName !== "") {
                if (this.state.isMeetindAdded) {
                    return false;
                }
                else {
                    return true;
                }

            }
            else {

                return true;
            }
        }
        // else{
        //   if(this.state.tenantId !== "" && this.state.visitorName !== "")
        //   {
        //       return false;
        //   }
        //   else
        //   {
        //       return true;
        //   }
        // }

    }



    handleCancel() {


        //moved to componentWillUnmount
        // this.props.dispatch(meetingAction.createNewMeetings([], success => {
        //   if(success === true){
        //     this.props.dispatch(meetingAction.setMettingsQueue([]));
        //     this.setState({
        //       isSucessfull:true,
        //       isVistorSave:true,
        //       isMeetindAdded:true
        //     })
        //   }
        // }));

        this.setState({
            isSucessfull: false,
            message: ""
        });


        this.props.history.push({
            pathname: "/",
            state: { module: "Visitors" }
        });
        window.scrollTo(0, 0)

    }



    handleInputChange(e) {
        const field = e.target.name;
        const value = e.target.value;
        this.setState({
            [field]: value,
            message: "",
            isSuccessfull: undefined
        });
    }



    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }
    handleTimeFromChange(val) {
        let checkMins = 23 * 60 + 59;

        let toTime = moment(val).add(1, 'hours');

        if (toTime.hours() === 0) {
            let today = moment(val).toDate();
            let maxEnd = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );


            maxEnd.setMinutes(checkMins);

            toTime = moment(maxEnd);
        }

        this.setState({
            timeFrom: val,
            timeTo: toTime
        });
    }
    handleTimeToChange(val) {
        this.setState({
            timeTo: val
        });
    }

    disabledHours() {
        const { timeFrom } = this.state;
        let time = [];
        for (let i = 0; i < 24; i++) {
            time.push(i);
        }
        if (!_.isUndefined(timeFrom) || timeFrom !== null)
            return _.filter(time, t => {
                if (timeFrom !== null) {
                    return t < timeFrom.hour();
                } else {
                    return t;
                }
            });
    }

    handleEdit(val) {
        const { meetings } = this.props;
        this.setState({ message: "" });
        if (!_.isUndefined(val)) {

            let selectedItem = _.find(meetings, { index: val })
            let data = {
                tenantId: selectedItem.tenantId,
                status: selectedItem.status,
                visitorName: selectedItem.name,
                visitorEmail: selectedItem.email,
                specialInstruction: selectedItem.specialInstruction,
                visitorCompany: selectedItem.companyName,
                timeFrom: moment(selectedItem.dueDate),
                timeTo: moment(selectedItem.endDate),
            }
            this.setState({ ...data, selectedStatus: true, selected: val });
        } else {
            this.formReset();
        }
    }


    render() {

        const {
            tenantCompany,
            startDate,

            timeFrom,
            timeTo,
            visitorName,
            visitorEmail,
            visitorCompany,
            tenantId

        } = this.state;
        const currentModule = _.find(
            this.props.modules,
            o => o.title === "Visitors"
        );


        const overlayColor = currentModule && currentModule.imageOverlayTextColour;
        const visitor_style = {
            color: overlayColor,
            fontSize: "80px",
            textTransform: "capitalize"
        }




        return (
            <div>
                <div className="container type-concierge-desktop management">
                    <FeatureArea
                        type={this.state.type}
                        areaTitle="Visitors"
                        areaTagline="Please enter information below"
                        pageHeader="Check-in"
                        overlayColor={currentModule && currentModule.imageOverlayTextColour}
                        landing={false}
                        activeMenu={true}
                    />

                    <form
                        id="form-content"
                        className="visitor-wrapper visitor-booking"
                        ref="validate"
                        onSubmit={e => {
                            e.preventDefault();
                        }}
                        data-parsley-errors-container="#validation-messages"
                        data-parsley-focus="none"
                        data-parsley-validate-if-empty
                    //data-parsley-inputs
                    >

                        {this.state.portalType === PortalTypes.Commercial ?
                            <div className="names">
                                <div className="tenant_elements">
                                    <TenantCompanyTypeAutoSuggest
                                        ref="tenant-auto-suggest"
                                        label="Tenant Name*"
                                        disabled={
                                            this.props.meetings.length > 0 && this.state.tenantId !== ""
                                        }
                                        value={this.state.tenant}
                                        clearableIfValid={false}
                                        onSelected={data => {
                                            this.setState({
                                                tenantId: data.memberId,
                                                tenantName: data.tenant,
                                                tenantCompany: data.companyName,
                                                tenantType: data.memberType
                                            });

                                            /* Timeout added otherwise validation not fired because autosuggest still updating DOM */
                                            setTimeout(() => {
                                                this.state.addVisitorFormUsed && this.validate();
                                            }, 0);
                                        }}
                                    />
                                </div>
                                <br />
                                <br />
                                <div className="company_elements">
                                    <div className="company-div">
                                        <InputNewComponent
                                            type="text"
                                            name="tenantCompany"
                                            label="Company"
                                            placeHolder="Company"
                                            handleChange={this.handleInputChange}
                                            value={tenantCompany}
                                            parsley={{
                                                //"data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
                                                //"data-parsley-minlength": 2,
                                                "data-parsley-minwords": "1",
                                                "data-parsley-required": true,
                                                "data-parsley-error-message":
                                                    "Please enter a valid company name"
                                            }}
                                            disabled={
                                                true
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="talent">
                                <div className="tenant_elements">
                                    <TenantAddrTypeAutoSuggest
                                        ref="tenant-auto-suggest"
                                        label="Tenant Name*"
                                        labelText="Tenant"
                                        placeHolder="Select Tenant"
                                        clearableIfValid={false}
                                        onSelected={data => {

                                            this.setState({
                                                tenantId: data.memberId,
                                                tenantAddress: data.address,
                                                tenantName: data.tenant,

                                            });

                                            // setTimeout(() => {
                                            //     this.state.addVisitorFormUsed && this.validate();
                                            // }, 0);
                                        }}
                                        defaultText={dataVal => { }}
                                        disabled={
                                            this.props.meetings.length > 0 && this.state.tenantId !== ""
                                        }
                                    />




                                </div>


                                {
                                    /** <div className="company_elements">
                                     <InputComponent
                                       type="text"
                                       name="tenantAddress"
                                       label="Address"
                                       placeHolder="Address"
                                       handleChange={this.handleInputChange}
                                       value={tenantAddress}
                                       parsley={{
                                         //"data-parsley-pattern": "/^[A-Za-z ]+$/", // alpha chars only https://stackoverflow.com/questions/23415795/
                                         //"data-parsley-minlength": 2,
                                         // "data-parsley-minwords": "1",
                                         // "data-parsley-required": true,
                                         // "data-parsley-error-message":
                                         //   "Please enter a valid company name"
                                       }}
                                       disabled={
                                         this.props.meetings.length > 0 && this.state.tenantId !== ""
                                       }
                                     />
                                   </div>
                                     */
                                }
                            </div>
                        }


                        <div className="multiple-visitor">
                            <div className="multiple-visitor-button">
                                <label className="checkbox-section">
                                    <input type="checkbox" name="multi-action" checked={this.state.IsAddMutipleEnable} onChange={this.MultipleVisitors} />
                                    <span className="checkmark ion-checkmark" style={{ cursor: 'pointer' }}></span>
                                </label>

                                <span className="multiple-visitor-text">Multiple visitors</span>
                            </div>
                        </div>
                        <div className="date_elements">
                            <div className="dates">
                                <label className="dateLabel visitor-label">Date*</label>
                                <InputNewComponent
                                    type="datePicker"
                                    ref="datePickery"
                                    selected={startDate}
                                    minDate={moment()}
                                    onChange={this.handleDateChange}
                                    dateFormat="DD/MM/YYYY"
                                    placeholder="Date "
                                    dateValue={
                                        _.isUndefined(startDate) || startDate === null
                                            ? moment().format("DD/MM/YYYY")
                                            : startDate.format("DD/MM/YYYY")
                                    }
                                    name="datePickCheckin"
                                    isDateDisabled={true}
                                // {...this.props.parsley}
                                />

                            </div>
                            <div className="times">
                                <div>
                                    {
                                        <InputNewComponent
                                            id="timeFrom"
                                            type="timePicker"
                                            defaultValue={startDate}
                                            handleTimeChange={this.handleTimeFromChange}
                                            name="timeFromCheckin"
                                            placeholder="From"
                                            disabledMinutes={disabledMinutes}
                                            label="From*"
                                            dateValue={
                                                _.isUndefined(timeFrom) || timeFrom === null
                                                    ? moment()
                                                    : timeFrom
                                            }
                                            parsley={{
                                                "data-parsley-pattern": "/^[A-Za-z ]+$/",
                                                "data-parsley-minlength": 5,
                                                "data-parsley-required": true,
                                                "data-parsley-minwords": "1",
                                                "data-parsley-error-message": "Please enter a valid time"
                                            }}
                                            isDisabled={true}
                                        />

                                    }
                                </div>


                                <div>
                                    <InputNewComponent
                                        type="timePicker"
                                        icon="ion-chevron-down"
                                        defaultValue={startDate}
                                        handleTimeChange={this.handleTimeToChange}
                                        name="timeTo"
                                        placeholder="To"
                                        disabledMinutes={disabledMinutes}
                                        label="To"
                                        disabledHours={this.disabledHours}
                                        dateValue={
                                            _.isUndefined(timeTo) ||
                                                timeTo === null ||
                                                timeTo < timeFrom
                                                ? timeFrom
                                                : timeTo
                                        }
                                    // parsley={{
                                    //   "data-parsley-pattern": "/^[A-Za-z ]+$/",
                                    //   "data-parsley-minlength": 1,
                                    //   "data-parsley-required": true,
                                    //   "data-parsley-minwords": "1",
                                    //   "data-parsley-gte": 100000000000,
                                    //   "data-parsley-error-message": "Please enter a valid time"
                                    // }}
                                    />

                                </div>
                            </div>
                        </div>



                        <div className="visitor_elements">
                            <label className="visitor-label">Visitor Name*</label>
                            <InputNewComponent
                                type="text"
                                name="visitorName"
                                placeHolder="Enter visitor name"
                                handleChange={this.handleInputChange}
                                value={visitorName}
                                parsley={{
                                    "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
                                    "data-parsley-minlength": 2,
                                    "data-parsley-required": true,
                                    "data-parsley-minwords": "2",
                                    "data-parsley-error-message":
                                        "Please enter visitor first and the last name"
                                }}
                            />
                        </div>



                        <div className="company_elements">

                            <label className="visitor-label">Visitor Company</label>
                            <InputNewComponent
                                type="text"
                                name="visitorCompany"
                                placeHolder="Enter visitor company name if applicable"
                                value={visitorCompany}
                                handleChange={this.handleInputChange}
                            />
                        </div>

                        <div className="visitorEmail">
                            <label className="visitor-label"> Visitor Email</label>
                            <InputNewComponent
                                type="text"
                                name="visitorEmail"
                                placeHolder="Enter visitor email if known"
                                handleChange={this.handleInputChange}
                                value={visitorEmail}
                            parsley={{
                              "data-parsley-type": "email",
                              "data-parsley-validate-if-empty": false,
                              "data-parsley-error-message": "Please enter a valid email"
                            }}
                            />
                        </div>

                        <div
                            className={
                                "validation-messages" +
                                (this.state.addVisitorFormValid ? " valid" : " invalid")
                            }
                            id="validation-messages"
                        >
                            {this.state.addVisitorFormValid && (
                                <div>
                                    {/* <p className="message type-success">Sending data To Server...</p> */}
                                </div>
                            )}
                        </div>

                        {
                            this.state.IsAddMutipleEnable ?
                                <div className="align-center middleBtn">
                                    {this.state.selectedStatus === true ?
                                        <Button
                                            className="hidden"
                                            size="cta-smedium add"
                                            text="Update Visitor"
                                            handleClick={() => { this.validate(true) }}

                                        />
                                        :
                                        <Button
                                            className="hidden"
                                            size="cta-smedium add"
                                            text="Add to List"
                                            handleClick={() => this.validate(true)}
                                            isDisabled={(this.state.tenantId !== "" ? (this.state.visitorName !== "" ? false : true) : true)}
                                        />
                                    }
                                </div>
                                : ""
                        }
                    </form>

                    {this.state.IsAddMutipleEnable ?
                        <div className="visitor-wrapper">
                            <DetailListWithOverlay

                                listType="visitor-add-meeting"
                                data={this.props.meetings}
                                headingsAlone={true}
                                emptyMessage={this.state.message !== "" ? "" : "You have not added any visitors yet"}
                                actionClick={index => {
                                    this.formReset();
                                    this.removeFromQueue(index);
                                }}
                                selectedDetail={val => { 
                                    this.resetValidation();
                                    this.handleEdit(val); 
                                }}
                                selectDetailReset={this.state.selectedStatus}
                                resetForm={e => {
                                    if (e === true) {
                                        this.formReset();
                                    }
                                }}
                            />
                        </div> : ""
                    }



                    {
                        this.state.message && <div className="visitor-wrapper visitor-added-successful-txt" >
                            <label > {this.state.message} </label>
                        </div>
                    }


                    <div className="footer-btn-group visitor-wrapper">
                        <ButtonGroup
                            rightText="Check-in Visitors"
                            leftText="Cancel"
                            // rightDisabled={this.props.meetings.length === 0}
                            leftClick={this.handleCancel}
                            rightClick={this.handleSubmit}
                            icon_l=""
                            icon_r=""
                            rightDisabled={this.state.IsAddMutipleEnable ? (this.props.meetings.length === 0 ? true : false) : (this.state.tenantId !== "" && this.state.visitorName !== "") ? false : true}
                        />
                    </div>
                    <FooterNavigation />
                </div>

            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        modules: moduleSelectors.loadModules(state),
        tenants: tenantSelector.getAllTenants(state),
        meetings: meetingSelector.getMeetingsQueue(state),
        meetingsAdded: meetingSelector.getMeetings(state),
        visitors: visitorSelector.getVisitors(state),
        instruction: meetingSelector.getSpecialInstruction(state),
        setting: settingSelector.loadGlobalSetting(state),
        visitorCheckedIn : visitorSelector.visitorCheckedIn(state)

    };
}
export default  withRouter(connect(mapStateToProps)(VisitorBooking));
