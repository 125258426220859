import React, { Component } from "react";
import autobind from "react-autobind";
import { connect } from "react-redux";
import _ from "lodash";
import {withRouter} from 'react-router-dom';
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";

import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import ButtonGroup from "../../../components/Button/ButtonGroup";


//Test loading modules, this would be moved to when use signs in

import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";

import * as moduleSelectors from "../../../store/ServiceModules/reducer";

import "../../../sass/containers/residents.css";

import * as $ from "jquery";

class ResidentArchive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "residents",
      residentId: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.residentId
        : "",
      resident: undefined,
      fname: "",
      lname: "",
      email: "",
      phone: "",
      addresslookupId: "",
      address: "",
      residentCreated: false,
      isApproved: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.isApproved
        : null,
      residentApprovalUpdated: false
    };

    this.props.dispatch(residentActions.getAllResidents());
    this.addResidentValidation = null;

    autobind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (this.props.residents !== nextProps.residents) {
      let resi = _.find(
        nextProps.residents,
        res => res.id === this.state.residentId
      );

      this.setState({
        resident: resi,
        phone:
          (!_.isUndefined(resi) && !_.isUndefined(resi.mobileNumber)) ||
            !_.isEmpty(resi.mobileNumber)
            ? resi.mobileNumber
            : "",
        email: !_.isUndefined(resi) ? resi.email : "",
        fullName: !_.isUndefined(resi)
          ? resi.firstName + " " + resi.lastName
          : "",
        avatarUrl: !_.isUndefined(resi) ? resi.avatarUrl : "",
        address: !_.isUndefined(resi)
          ? `${resi.addressLine1 + " " + resi.addressLine2}`
          : "",
        isApproved: !_.isUndefined(resi) ? resi.isApproved : null
      });
    }

    if (
      this.props.residentUpdatedApprovalStatus !==
      nextProps.residentUpdatedApprovalStatus ||
      !_.isUndefined(nextProps.residentUpdatedApprovalStatus)
    ) {
      console.log("no data coming through");
      if (nextProps.residentUpdatedApprovalStatus.status === true) {
        this.setState({
          residentApprovalUpdated:
            nextProps.residentUpdatedApprovalStatus.status
          // isApproved: !_.isUndefined(this.props.isApproved)
          //   ? this.props.isApproved
          //   : this.state.isApproved
        });
      } else {
        this.setState({
          residentApprovalUpdated:
            nextProps.residentUpdatedApprovalStatus.status,
          //isApproved: !_.isUndefined(this.props.isApproved) ? this.props.isApproved : this.state.isApproved,
          error: nextProps.residentApprovalUpdatedStatus.error
        });
      }
    }
  }

  // archiveResident() {
  //   console.log(this.state);
  //   if (this.state.residentApprovalUpdated === true) {
  //     console.log("here");
  //     this.setState({
  //       isApproved: this.state.isApproved === true ? false : true
  //     });
  //   }
  // }

  validate(fromSubmit = false) {
    if (fromSubmit === true) {
      //this.formReset();

      let residentData = {
        phone: this.state.phone,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        address: this.state.address,
        addressId: this.state.addresslookupId,
        isApproved: this.state.isApproved === true ? false : true,
        residentApprovalUpdated: this.state.residentApprovalUpdated
      };

      this.props.dispatch(
        residentActions.updateApprovalResident(residentData, success => {
          if (success === true) {
            this.returnToProfile();
          }
        })
      );

      this.setState({
        addResidentFormUsed: false
      });

      // setTimeout(
      //   function() {
      //     this.returnToProfile();
      //   }.bind(this),
      //   2000
      // );
    }
  }

  componentDidCatch() {
    // this.addResidentValidation.destroy();
  }

  returnToProfile() {
    this.props.history.push({
      pathname: "/concierge/residents/profile",
      state: {
        residentId: this.state.residentId,
        //isApproved: this.state.isApproved,
        residentApprovalUpdated: this.state.residentApprovalUpdatedStatus
      }
    });
  }

  formReset() {
    this.setState({
      fname: "",
      lname: "",
      email: "",
      addresslookupId: "",
      phone: "",
      address: ""
    });

    //this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.addResidentValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);

    // this.setState({
    //   isApproved: !this.state.isApproved
    // });

    // setTimeout(() => {
    //  this.handleArchiveClick();
    // }, 2000);
  }

  handleInputChange(e) {
    let field = e.target.name;
    let value = e.target.value;

    this.setState({
      [field]: value
    });
  }

  handleArchiveClick() {
    this.props.history.push({
      pathname: "/concierge/residents/profile",
      state: {
        residentId: this.props.location.state.residentId,
        isApproved: this.state.isApproved,
        residentUpdatedApprovalStatus: this.state.residentApprovalUpdated
      }
    });
  }

  handleSubmit(event) {
    this.validate(true);

    event.preventDefault();
  }


  render() {

    const currentModule = _.find(
      this.props.modules,
      o => o.title === "Residents"
    );

    return (
      <div>
        <div className={"container type-" + this.state.type + " archive"}>
          <FeatureArea
            type={this.state.type}
            pageHeader={
              this.state.isApproved === true
                ? "Do you wish to archive?"
                : "Do you wish to re-activate?"
            }
            areaTitle="Residents"
            areaTagline="Resident Archive"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
          />

          <div className="content-wrapper">
            <form
              className="content-wrapper"
              ref="validate"
              onSubmit={this.handleSubmit}
              data-parsley-errors-container="#validation-messages"
              data-parsley-focus="none"
            >
              <ProfileCard
                name={this.state.fullName}
                address={this.state.address}
                picture={this.state.avatarUrl}
                txtColor={currentModule.imageOverlayTextColour}
              />

              <div
                className={
                  "validation-messages" +
                  (this.state.addResidentFormValid ? " valid" : " invalid")
                }
                id="validation-messages"
              >
                {this.state.addResidentFormUsed && <p> </p>}
                <div>
                  {this.props.residentApprovalUpdated &&
                    (this.state.residentApprovalUpdatedStatus === true ? (
                      <p className="message type-success">Member updated</p>
                    ) : (
                        this.state.error && (
                          <p className="message error"> {this.state.error}</p>
                        )
                      ))}
                </div>
              </div>

              <ButtonGroup
                sizes={["smedium", "medium"]}
                leftText={"Cancel"}
                rightText={
                  this.state.isApproved === true ? "Archive" : "Re-activate"
                }
                //rightDisabled={this.state.addResidentFormValid === false}
                leftClick={this.props.history.goBack()}
                rightClick={e => {
                  this.validate(true);
                }}
              />
            </form>
          </div>
        </div>
        <FooterNavigation />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    modules: moduleSelectors.loadModules(state),
    residents: residentSelectors.getResidents(state),
    residentUpdatedStatus: residentSelectors.residentUpdatedStatus(state),
    residentUpdatedApprovalStatus: residentSelectors.residentUpdatedApprovalStatus(
      state
    )
  };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ResidentArchive
);
