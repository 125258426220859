import React, { Component } from "react";

import { connect } from "react-redux";
import autoBind from "react-autobind";
import dateFormat from "dateformat";
import _ from "lodash";


import BigCalendar from "react-big-calendar";
import moment from "moment";
import {withRouter} from 'react-router-dom';

import CustomDayWrapper from "../CalendarCustomComponents/CustomDayWrapper";
import Week from "react-big-calendar/lib/Week";
//import WorkWeek from "react-big-calendar/lib/WorkWeek";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import dates from "react-big-calendar/lib/utils/dates";
import localizer from "react-big-calendar/lib/localizer";
import EventCell from "react-big-calendar/lib/EventCell";

import EventModal from "../ariaModals/EventModal";
import CustomEventWrapper from "../CalendarCustomComponents/CustomEventWrapper";
import WorldPayComponent from "../PaymentGateway/WorldPayComponent";

import * as resourceSelector from "../../store/Resources/reducer";
import * as resourceActions from "../../store/Resources/actions";
//import * as serviceModuleActions from "../../store/ServiceModules/actions";
import * as serviceModuleSelectors from "../../store/ServiceModules/reducer";
import * as tenantSelectors from "../../store/Tenants/reducer";
import * as checkoutSelectors from "../../store/Checkout/reducer";
import * as CyptoHelper from "../../utility/cryptoUtility";

import "../../sass/components/BigCalendar/react-big-calendar.css";
var PropTypes = require('prop-types');

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
function dayWrapperClicked() { }

const calendarViews = {
  Day: "day",
  Week: "week",
  WorkWeek: "work_week",
  Month: "month"
};
const ServiceUnitType = {
  Day: "day",
  Minute: "minute"
};

const ORDER_KEY = "order_key";

//test template for cards, some settings can be moved into css styling for each cards, see sample class included
const custom_cards = [
  { card: 0, color: "#ffffff", border: "#009cc4", borderPast: "#7fcee2" },
  { card: 1, color: "#0ea610", border: "#0ea610", borderPast: "#87d388" },
  { card: 2, color: "#fa4b2e", border: "#fa4b2e", borderPast: "#fda597" },
  { card: 3, color: "#009cc4", border: "#009cc4", borderPast: "#7fcee2" }];


const myCustomDayWrapper = () => (
  <CustomDayWrapper handleClick={dayWrapperClicked} />
);
function eventPropsGetter(event, start, end, isSelected) {

  if (!event.isAvailable && !event.inBasket && _.isUndefined(event.booked) && _.isUndefined(event.blocked)) {
    return {
      style: {
        backgroundColor: "rgba(255,255,255,0)",
        border: 0,
        borderRadius: 0,
        zIndex: 0,

      }
    };
  } else if (event.inBasket && !event.isAvailable) {
    return {
      style: {
        backgroundColor: "#AEDBBC ",
        border: 0,
        borderRadius: 0
      }
    };
  } else if (event.isAvailable) {
    return {
      style: {
        backgroundColor: "#FFFFFF ",
        borderBottom: "0.9px solid #d9d9d9",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 0,
        opacity: 0,
        color: "#ffffff"
      }
    };
  } else if (
    !event.isAvailable &&
    !_.isUndefined(event.booked) &&
    event.booked
  ) {
    let current_card = _.find(custom_cards, { card: event.card })
    return {
      className: !_.isUndefined(event.card) ? event.past === true ? "card--" + event.card + " event past" : "card--" + event.card + " event" : "event", //provide special class styling attribute based on card no ie, inner text, headers etc
      style: {
        border: "0px solid #ffffff",
        borderLeft: !_.isUndefined(current_card) ? "solid " + 2 + "px " + (!_.isUndefined(event.card) && event.past === true ? current_card.borderPast : current_card.border) : ""

      }
    };
  } else if (!event.isAvailable
    && !_.isUndefined(event.blocked) && event.blocked) {
    let current_card = _.find(custom_cards, { card: event.blocked_card });

    return {
      className: event.past === true ? "card--blocked past" : "card--blocked",
      style: {
        border: "0px solid #ffffff",
        borderLeft: !_.isUndefined(current_card) ? "solid " + 2 + "px " + current_card.border : ""
      }
    }
  }
}

// function slotPropGetter(date) {
//   return {
//     style: {
//       backgroundColor: "#AEDBBC",
//       border: 0,
//       borderRadius: 0
//     }
//   };
// }


const CustomEventCell = (event) => {
  return (
    <EventCell
      eventWrapperComponent={CustomEventWrapper}
      event={event}
    />
  );
}


class MyWeek extends Week {
  handleNav(date, action) { }

  render() {
    let { date } = this.props;
    let { start, end } = MyWeek.range(date, this.props);

    return (
      <TimeGrid {...this.props} start={start} end={end} eventOffset={18} />
    );
  }
}

MyWeek.range = (date, { culture }) => {
  let firstOfWeek = localizer.startOfWeek(culture);
  firstOfWeek = new Date().getDay() - 1;
  let start = dates.startOf(date, "week", firstOfWeek);
  let end = dates.endOf(date, "week", firstOfWeek);

  if (firstOfWeek === 1) {
    end = dates.subtract(end, 2, "day");
  } else {
    start = dates.add(start, 1, "day");
    end = dates.add(end, 1, "day");
  }

  return {
    start,
    end
  };
};
class BookingCalendarCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSlot: 1,
      modal: "",
      bookingId: 0,
      date: "" /*date string xxxx-xx-xx */,
      time: "" /*time specified in 24hr A  (11:00 AM) */,
      duration: "" /* duration calculated in minutes */,
      selectedBookedSlot: undefined,
      error: "",
      booking_action_valid: false,
      min_cancel_time: moment(),
      slots: [],
      cancelledBooking: undefined,
      bookingUpdated: undefined,
      type2: "",
      current_nav_view: "Today",
      switchActive: "",
      open: false,
      slotSelected: undefined,
      eventsResourceSelected: this.props.selectedEventResources,
      selectedSartDate: undefined,
      selectedEndDate: undefined,
      selectedSlotTimes: [],
      slotDataRefreshed: false,
      selectedTenant: undefined,
      basketQue: [],
      paymentStatus: undefined,
      modalActive : false

    };

    autoBind(this);

    this.props.dispatch(resourceActions.getBookingsCancelReasons());
    this.props.dispatch(resourceActions.getBookingErrors());
  }

  handleCloseClick(event, modalActive) {
    this.setState({ modal: "", modalActive: false });

    if (this.props.fromRedirect === true) {
      this.props.modalActionEvent("payment", this.props.paymentAction);
    }
  }

  getApplicationNode = () => {
    return document.getElementById("bookingApp");
  };


  modalActionCompleted(action, valid) {

    this.props.modalActionEvent(action, valid);

    if (valid === true)
      setTimeout(() => {
        if (
          action === "delete" &&
          !_.isUndefined(this.state.cancelledBooking)
        ) {
          this.props.dispatch(
            resourceActions.nofifyCancelBooking(
              this.state.cancelledBooking.booking
            )
          );
        } else if (
          action === "update" &&
          !_.isUndefined(this.state.bookingUpdated)
        ) {

          if (this.state.bookingId === this.state.bookingUpdated.id)
            this.props.dispatch(
              resourceActions.notifyEditBooking(this.state.bookingUpdated.id)
            );
        } else if (action === "block") {
          this.setState({ modal: "", modalActive: false });
        } else if (action === "create") {

        } else if (action === "payment") {


          this.setState({
            actionTriggered: action,
            modal: "",
            modalActive: false
          });


          let data = {
            itemQue: this.props.itemQueue,
            tenant: this.props.selectedTenant,
            selectedEvents: this.props.selectedEvents,
            selectedResource: _.find(this.props.selectedEvents, { eventId: this.props.eventId }),
            selectedService: { id: this.props.service.id, duration_unit: this.props.service.duration_unit },
            carlendarView: this.props.current_view,
            basket: this.props.basket,
            chargeType: this.props.chargeType
          };

          var crypted = CyptoHelper.encryptAES(data);

          // var crypt = "?data=" + encodeURIComponent(crypted);
          localStorage.setItem(ORDER_KEY, crypted);

          setTimeout(() => {


            this.props.history.push({
              pathname: "/concierge/bookings/payment",

            });
            window.scrollTo(0, 0);
          }, 0);
        }

      }, 0);
  }
  
  componentDidMount() {
    const { serviceId, date } = this.props;

    this.serviceBookings(serviceId, dateFormat(date._d, "isoDate"));

    //trigger create booking notification on return from payment redirect journey from resi
    if (this.props.fromRedirect && this.props.fromRedirect === true) {
      let status_screen = "";
      if (this.props.paymentAction === true) {
        status_screen = "booking_success"

        // this.props.dispatch(resourceActions.notifyCreateBooking(this.props.booking));
      } else if (this.props.paymentAction === false) {
        status_screen = "booking_error"
      }

      this.setState({
        modal: this.showModal(true),
        modalActive: true,
        modalScreenType: status_screen,
        paymentStatus: this.props.paymentAction
      });
    }
  }

  componentWillUnmount() {
    //depricated
    //this.props.dispatch(resourceActions.setActionRedirect(undefined));
    //this.props.dispatch(resourceActions.setPaymentAction(undefined));

  }

  //keep for carlendar anvigation
  // handleNavigation(date, action) {
  //   switch (action) {
  //     case "day":
  //       console.log(date);
  //       if (date >= new Date().setHours(0)) {
  //         this.props.dispatch(
  //           resourceActions.fetchServicesSlots(this.props.serviceId, date._d)
  //         );
  //       }
  //       return date;
  //     default:
  //       return date;
  //   }
  // }


  componentDidUpdate(prevProps, prevState) {
    
    const { selectedTenant, basketQue } = this.props;
    if (
      prevProps.serviceId !== this.props.serviceId ||
      prevProps.date !== this.props.date
    ) {
      this.serviceBookings(
        this.props.serviceId,
        dateFormat(this.props.date._d, "isoDate")
      );
    }

    if (prevState.selectedBookedSlot !== this.state.selectedBookedSlot) {
      this.setState({
        selectedBookedSlot: this.state.selectedBookedSlot
      });
    }

    if (prevProps.selectedTenant !== selectedTenant) {
      this.setState({
        selectedTenant: selectedTenant
      });
    }
    if (prevProps.basketQue !== basketQue) {
      this.setState({
        basketQue: basketQue
      });
    }

    if (this.props.slots !== prevProps.slots && this.props.slots) {
      this.setState({
        slots: this.props.slots
      });
    }

    if (this.props.selectedEventResources !== prevProps.selectedEventResources ) {
     
      this.setState({
        eventsResourceSelected: this.props.selectedEventResources
      });
    }

    if (this.props.bookingCreated !== prevProps.bookingCreated ) {
      this.setState({
        bookingCreated: !_.isUndefined(this.props.bookingCreated) ? this.props.bookingCreated : undefined
      });
    }

    if (this.props.bookingUpdated !== prevProps.bookingUpdated && this.props.bookingUpdated) {
      this.setState({
        bookingUpdated:
          !_.isUndefined(this.props.bookingUpdated.booking) &&
          this.props.bookingUpdated.booking.length > 0
            ? this.props.bookingUpdated.booking[0]
            : undefined
      });
    }

    if (this.props.bookingsCancelled !== prevProps.bookingsCancelled && this.props.bookingsCancelled) {
      this.setState({
        cancelledBooking:
          !_.isUndefined(this.props.bookingsCancelled) &&
          this.props.bookingsCancelled.length > 0
            ? this.props.bookingsCancelled[0]
            : undefined
      });
    }

    if (this.props.selectedBookingDetail !== prevProps.selectedBookingDetail && this.props.selectedBookingDetail) {
      if (
        !_.isUndefined(this.props.selectedBookingDetail.min_cancellation_time)
      ) {
        this.setState({
          min_cancel_time: moment(
            this.props.selectedBookingDetail.min_cancellation_time
          )
        });
      }
    }
   
    if (this.props.itemQueue !== prevProps.itemQueue && this.props.itemQueue) {
      this.setState({
        basketQueue: this.props.itemQueue
      });
    }

  }

  //handle slot selection
  slotSelected(slotInfo) {

    //const { serviceId } = this.props;
    const { slots, serviceId } = this.state;
    let stime = new Date(slotInfo.start);
    let min = stime.getHours() * 60 + stime.getMinutes();
    let etime = new Date(slotInfo.end);
    let end_min = etime.getHours() * 60 + etime.getMinutes();

    this.setState({
      selectedStartDate: stime,
      selectedEndDate: etime
    });

    //console.log("slot: ",slotInfo);
    //console.log("slots: ",slots);

    //Get slot data for selected slot
    let selectedSlotTimes = _.filter(slots, o => {
      return moment(o.start).format("YYYY/MM/DD") === moment(stime).format("YYYY/MM/DD")
        && o.start_min >= min && o.end_min <= end_min;
    });

    //Get grouped slot data from slots data, this would check if selected time span is available
    let slot_obj = _.find(slots, s => {
      let slot_time = new Date(s.start);
      let slot_min = slot_time.getHours() * 60 + slot_time.getMinutes();
      return moment(s.start).format("YYYY-MM-DD") === moment(slotInfo.start).format("YYYY-MM-DD") && slot_min === min;
    });

    let slot = {};

    if (
      (slotInfo &&
        !_.isUndefined(slot_obj) &&
        (slot_obj.isAvailable === true) & _.isUndefined(slotInfo.booked)) ||
      slotInfo.booked === false
    ) {

      slot = {
        isAvailable: true,
        inBasket: false,
        id: slot_obj.id,
        start: slotInfo.start,
        end: slotInfo.end,
        price: slot_obj.price,
        start_min: min,
        end_min: end_min,
        type: slotInfo.action,
        active: true,
        duration: (end_min - min)
      };


      this.props.dispatch(resourceActions.setSelectedSlot(slot, serviceId));

    }


    if (slotInfo.action === "select") {
     // console.log("selectedSlotTimes: ", selectedSlotTimes);
     // console.log("slot: ", selectedSlotTimes);

      let now = new Date();

      if (stime >= now && this.state.eventsResourceSelected && this.state.eventsResourceSelected.length > 0) {
        this.props.dispatch(resourceActions.updateBasketQueue(slot));

        this.setState({
          modalActive: true,
          slotSelected: slot,
          modalScreenType: "create",
          selectedSlotTimes: selectedSlotTimes
        });
      }


    }


    this.getBookedBooking(slot_obj);
  }


  showModal(active) {
    const { eventsResourceSelected,
      modalScreenType,
      switchActive,
      slotSelected,
      selectedStartDate,
      selectedEndDate

    } = this.state;
    const { serviceDetail, bookingQuestions, step, serviceId, portalSettings } = this.props;
    const events = _.map(eventsResourceSelected, o => {
      return { name: o.eventName, eventId: o.eventId };
    });

    
    return (
      <EventModal
        handleCloseClick={this.handleCloseClick}
        focus={active}
        type={modalScreenType}
        switchActive={switchActive}
        getApplicationNode={this.getApplicationNode}
        activateModal={""}
        deactivateModal={""}
        selectedSlot={slotSelected}
        //slots={slots}
        resources={events}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        service={serviceDetail}
        bookingQuestions={bookingQuestions}
        timeStep={step}
        modalActionCompleted={this.modalActionCompleted}
        cancellationPeriod={!_.isUndefined(portalSettings) && !_.isUndefined(portalSettings.cancellation_period) ? portalSettings.cancellation_period : 0}
        view={this.props.current_view}
        serviceId={serviceId}
      />
    );
  }


  //helper method to get selected booked slot
  getBookedBooking(slot) {
    const { bookings, selectedEvents } = this.props;

    let selected_booking = undefined;
    if (
      slot &&
      bookings &&
      !_.isUndefined(slot.booked) &&
      slot.booked === true
    ) {
      const event = _.find(selectedEvents, { eventId: slot.eventId });

      let stime = new Date(slot.start);
      let etime = new Date(slot.end);

      let smin = stime.getHours() * 60 + stime.getMinutes();
      let emin = etime.getHours() * 60 + etime.getMinutes();

      selected_booking = _.find(bookings, b => {
        //convert each booking time from to minutes
        let booking_start_time = moment(b.timeFrom, "HH:mm A");
        let booking_start_mins =
          booking_start_time.get("hour") * 60 +
          booking_start_time.get("minutes");

        //convert each booking end time to minutes
        let booking_end_time = moment(b.timeTo, "HH:mm A");
        let booking_end_min =
          booking_end_time.get("hour") * 60 + booking_end_time.get("minutes");

        //return booking that falls within a booked slot range
        return booking_start_mins === smin && booking_end_min === emin && event.eventName === b.resourceName;
      });


    } else {

    }


  }


  //handle event selected
  eventSelected(slot, e) {
    const { serviceId } = this.props;
    const { slots } = this.state;


    if (slot.booked || slot.blocked) {


      //for currently selected slot, find overlaping time on selected slot
      let current_overlaps = this.getOverlappingSlot(slots, slot);

      let temp_slot = _.findLast(current_overlaps, o => {

        return o.booked === true
      });


      //swap selected booked slot with last booked slot in group, this is used by react calendar 
      //in position cards based on package calculaions. 
      let new_slots = _.map(slots, (data, index) => {
        if (data.id === slot.id) {
          return {
            ...data,
            active: true,
          };
        }

        return {
          ...data,
          active: false,
        };
      });


      this.setState({
        selectedBookedSlot: slot,
        slotSelected: slot,
        slots: new_slots,

      });

      if (!_.isUndefined(slot.active) && slot.active === true) {

        this.setState({
          modal: this.showModal(slot, true),
          modalActive: true,
          modalScreenType: "edit"
        });
      }
    }

    if (slot.inBasket) {
      this.props.dispatch(resourceActions.deSelectSlot(slot));
    } else {
      if (slot.isAvailable) {
        let selected_slot = {
          isAvailable: true,
          inBasket: false,
          id: slot.id,
          start: slot.start,
          end: slot.end,
          price: slot.price,
          start_min: slot.start_min,
          end_min: slot.end_min,
          type: "click",
          duration: slot.end_min - slot.start_min
        };
        this.props.dispatch(
          resourceActions.setSelectedSlot(selected_slot, serviceId)
        );
      }
    }

    this.setState({
      open: !this.state.open
    });

    // this.getBookedBooking(slot);
  }

  serviceBookings(serviceid, date) {

    const { current_view } = this.props;

    let startDate = moment(date);
    let endDate = moment(date);


    if (current_view === calendarViews.Day) {
      startDate = moment(date).startOf("day");
      endDate = moment(date).endOf("day");
    } else if (current_view === calendarViews.Week) {
      startDate = moment(date).startOf("week");
      endDate = moment(date).endOf("week");

    }

    this.props.dispatch(resourceActions.getServiceBookings_range(serviceid, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"), success => {
      if (success === true) {
        this.setState({
          request_fail: undefined
        });
      } else {
        this.setState({
          request_fail: true
        });
      }
    }));


  }

  //helper to get overlapping related time event slot from slots list
  getOverlappingSlot(slots, selected_slot) {
    const current_slots = _.filter(slots, slot => {
      return moment(selected_slot.start).format("YYYY-MM-DD") === moment(slot.start).format("YYYY-MM-DD") &&

        !_.isUndefined(slot.booked) && slot.booked === true &&
        ((selected_slot.start_min < slot.start_min && slot.start_min < selected_slot.end_min) ||
          (selected_slot.start_min === slot.start_min && slot.end_min <= selected_slot.end_min) ||
          (selected_slot.start_min >= slot.start_min && slot.end_min >= selected_slot.end_min)
        );

    });
    const sort_slot = _.orderBy(current_slots, ['start_min'], ['asc']);


    return sort_slot;
  }

  clearBookingSelection() {
    this.setState({
      selectedBookedSlot: undefined,
      bookingId: "",
      date: "",
      time: "",
      duration: "",
      error: ""
    });
  }

  handleUpdateDateChange(val) {
    this.setState({
      date: val
    });
  }

  switchDayNext(dayType) {
    const { current_nav_view } = this.state;
    const { date, current_view, endDate } = this.props;

    let now = moment();



    if (current_view === calendarViews.Week || current_view === calendarViews.WorkWeek || current_view === calendarViews.Month) {

      if (dayType === "Today") {

        this.setState({
          current_nav_view: "Next"
        });
      }
      if (dayType === "Next") {

        this.setState({
          current_nav_view: "Next"
        });
      }

      if (dayType === "Back") {

        this.setState({
          current_nav_view: "Next"
        });
      }

    }


  }

  switchDayPrevious(dayType) {
    const { current_nav_view } = this.state;
    const { date, current_view, endDate } = this.props;



    if (current_view === calendarViews.Week || current_view === calendarViews.Month) {

      if (dayType === "Next") {
        this.setState({
          current_nav_view: "Back"
        });

      }

      if (dayType === "Back") {

        this.setState({
          current_nav_view: "Back"
        });
      }
    }


  }


  getCustomToolbar(toolbar) {
    //toolbar parameter exposes, view, date
    this.toolbarDate = toolbar.date;

    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };
    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };
    const goToCurrent = () => {
      toolbar.onNavigate("TODAY");
    };
    var monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
    var weekDays = this.props.current_view === calendarViews.Week || this.props.current_view === calendarViews.WorkWeek || this.props.current_view === calendarViews.Month ?
      ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] : ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]



    var n = new Date(this.props.date);
    // var curruntDate = new Date();

    var ed = new Date(this.props.endDate);
    var currentMonth = monthNames[n.getMonth()];
    var currentDay = weekDays[n.getDay()];
    var currentYear = n.getFullYear();
    var ws = new Date(this.props.endDate);
    ws.setDate(ws.getDate() - 6);

    let dayCount = 0;

    if (this.props.current_view === calendarViews.WorkWeek || this.props.current_view === calendarViews.Month)
      dayCount = 5;
    if (this.props.current_view === calendarViews.Week || this.props.current_view === calendarViews.Month)
      dayCount = 7;
    if (this.props.current_view === calendarViews.Day)
      dayCount = 1;

    let isCurrentMonth = monthNames[ws.getMonth()] === monthNames[new Date().getMonth()];


    let viewSwitcher = this.props.serviceUnit && this.props.serviceUnit === ServiceUnitType.Minute ?
      <div className="week_select" style={{ display: "flex", flexDirection: "row" }} >
        <button className={"Day" + (this.props.current_view === 'work_week' || this.props.current_view === "week" ? ' active' : '')} onClick={() => this.props.switchTypeHandler('week')}  >Week</button>
        <button className={"Week" + (this.props.current_view === 'day' ? ' active' : '')} onClick={() => this.props.switchTypeHandler('day')} >Day</button>
      </div>
      : "";



    return <div className="toolbar-container">
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
        {viewSwitcher}
        <div className="day_select" style={{ display: "flex", flexDirection: "row", paddingRight: 10 + "px", paddingLeft: 15 + "px" }}>
          <button className="nav-btn" onClick={() => {
            this.switchDayPrevious(this.state.current_nav_view);
            goToBack();

          }}><i style={{ fontWeight: "bolder", fontSize: 22 + "px", color: "#6b6b6b" }} className="fa fa-angle-left fa-2" aria-hidden="true"></i></button>
          <div onClick={() => { goToCurrent(); }} className="Text-Field">
            <label className="today" key={this.state.current_nav_view} value={this.state.current_nav_view}>{this.state.current_nav_view}</label>

          </div>
          <button className="nav-btn" onClick={() => {
            // this.switchDayNext(this.state.current_nav_view);
            goToNext();
          }}><i style={{ fontWeight: "bolder", fontSize: 22 + "px", color: "#6b6b6b" }} className="fa fa-angle-right fa-2" aria-hidden="true"></i></button>

        </div>

        <div style={{ width: 100 + "%" }}>
          {this.props.servicesOptions}
        </div>
      </div>
      {this.props.requestStatus && this.props.requestStatus === true && <div>
        <p className="message type-error">Network error, Please try again later.</p>
      </div>}



      <div className="currentMonthYear">
        <div className={`${!isCurrentMonth ? "set set-month" : (this.props.current_view === 'day' ? "set_day" : "set")}`}>
        <label className="lblMonthYear" style={ ((n.getDate() > new Date().getDate() || n.getMonth() > new Date().getMonth() || currentYear > new Date().getFullYear()) ? { color: '#221f20' } : ((n.getDate() < new Date().getDate() || n.getMonth() < new Date().getMonth() || currentYear < new Date().getFullYear())) ? { color: '#6b6b6b' }:{ color: '#009cc4' })}>{this.props.current_view === 'day' ? n.getDate() : ''}  {currentMonth}  {currentYear} </label>
          <label className="lblCurrentDay" style={ ((n.getDate() > new Date().getDate() || n.getMonth() > new Date().getMonth() || currentYear > new Date().getFullYear()) ? { color: '#221f20' } :(n.getDate() < new Date().getDate() || n.getMonth() < new Date().getMonth() || currentYear < new Date().getFullYear()) ? { color: '#6b6b6b' }:{ color: '#009cc4' })}>{this.props.current_view === 'day' ? currentDay : ''}   </label>
        </div>
        {this.props.current_view === calendarViews.WorkWeek || this.props.current_view === calendarViews.Week || this.props.current_view === calendarViews.Month ? _.times(dayCount, i =>
          <div key={i} className={this.props.current_view === 'week' ? "currentWeek" : 'currentDay'} >
            <label className="lblDateDay" style={ws < new Date() ? { color: '#6b6b6b' } : ((ws.getDate() === new Date().getDate() && ws.getMonth() === new Date().getMonth() && ws.getFullYear() === new Date().getFullYear()) ? { color: '#009cc4' } : { color: '#221f20' })}>{ws.getDate()} </label>
            <label className="lblCurrentWeekDay" style={ws < new Date() ? { color: '#6b6b6b' } : ((ws.getDate() === new Date().getDate() && ws.getMonth() === new Date().getMonth() && ws.getFullYear() === new Date().getFullYear()) ? { color: '#009cc4' } : { color: '#221f20' })}>{weekDays[ws.getDay()]}</label>
            <label style={{ display: 'none' }} >{ws.setDate(ws.getDate() + 1)}  </label>
          </div>
        ) : ''}
      </div>

    </div>;
  }

  getDatesRange(startDate, endDate) {
    var dateRange = [];
    var currentDate = new Date(moment(startDate).format("YYYY-MM-DD"));
    var end = new Date(moment(endDate).format("YYYY-MM-DD"));

    let current = currentDate;
    for (var d = current; d <= end; d.setDate(d.getDate() + 1)) {
      dateRange.push(new Date(d));
    }

    return dateRange;
  }

  showPaymentView() {
    var customOptions = {
      type: 'lightbox',
      iframeIntegrationId: 'libraryObject',
      iframeHelperURL: 'http://localhost:3000/helper.html',
      iframeBaseURL: 'http://localhost:3000',
      url: 'https://payments.worldpay.com/ngpp/integration/wpg/corporate?OrderKey=YOUR_ORDER_KEY&Ticket=YOUR_TICKET_ID',
      target: 'custom-html',
      trigger: 'custom-trigger',
      lightboxMaskOpacity: 50,
      lightboxMaskColor: '#000000',
      accessibility: true,
      debug: false,
      language: 'en',
      country: 'gb',
      preferredPaymentMethod: '',
      successURL: 'https://www.example.com/success',
      cancelURL: 'https://www.example.com/cancel',
      failureURL: 'https://www.example.com/failure',
      pendingURL: 'https://www.example.com/pending',
      errorURL: 'https://www.example.com/error'
    };

    //initialise the library and pass options
    //var libraryObject = new WPCL.Library();
    // libraryObject.setup(customOptions);

    // return (<div id='custom-html'>

    // </div>);

    return (<WorldPayComponent handlePaymentLoad={this.paymentLoad}
    />);
  }

  handlePayment(val) {

    this.showPaymentView();
  }

  paymentLoad(val) {

  }
  initPaymentEditor() {

    return (
      <div>
        <a className="button">Click me </a>
        <div id='custom-html'></div>
      </div>

    );
  }


  render() {
    MyWeek.navigate = this.props.handleNavigation;

    const { current_view, date, endDate, step, slots, minCalTime, maxCalTime } = this.props;
    const { modalActive } = this.state;

    let formats = {
      dayFormat: (date, culture, localizer) =>
        localizer.format(date, 'D ddd', culture),
      timeGutterFormat: "HH:mm",
    }

    if (!this.state.slots) {
      return this.renderLoading();
    }

    if (this.state.wpscriptLoaded === true) {
      //this.initializeHostedPaymentPageContent(val);
    }
    
   // console.log("props: ", this.props);
   // console.log("state: ", this.state);
    return (

      <div id="bookingApp">
        {_.isUndefined(this.props.eventsSlotStatus) ?
          <BigCalendar
            className={"calendar " + this.props.current_view}
            events={_.filter(this.state.slots, o => {
              return o.booked === true || o.blocked === true;
            })}
            defaultDate={moment().format("DD/MM/YYYY")}
            formats={formats}
            date={new Date(date._d)}
            defaultView={this.props.current_view}
            step={step * this.state.timeSlot}
            timeslots={this.state.timeSlot}
            view={this.props.current_view}
            views={['day', 'week', 'work_week']}
            onView={() => { }}
            selectable={'ignoreEvents'}
            toolbar={true}

            onSelectEvent={(event, target) => {
              this.eventSelected(event, target);
            }}
            onSelectSlot={slotInfo => {
              this.slotSelected(slotInfo);
            }}
            elementProps={{}}
            min={moment( minCalTime, "h:mma").toDate()}
            max={moment(maxCalTime, "h:mma").toDate()}
            components={{
              event: CustomEventCell,
              dayWrapper: myCustomDayWrapper,
              toolbar: this.getCustomToolbar
            }}
            eventPropGetter={eventPropsGetter}
            //slotPropGetter={slotPropGetter}
            onNavigate={(date, action) => this.props.handleNavigation(date, action)}

          />
          :
          <div> <div className="progress-spinner"> <i className={"fa fa-spinner fa-spin resource-arrow "} style={{ fontSize: 24 + "px" }}></i></div> </div>
        }
        {modalActive === true && this.showModal(this.state.slotSelected, true)}
        {
          // this.initPaymentEditor()
          // this.state.wpscriptLoaded === true && this.initPaymentEditor()
        }

      </div>
    );
  }
  renderLoading() {
    return <p> Loading... </p>;
  }
}
BookingCalendarCustom.propTypes = {
  bookedEventSelected: PropTypes.func,
  //serviceUnit: PropTypes.string
};
function mapStateToProps(state, ownProps) {
  return {
    basketQueue: resourceSelector.getBasketQueue(state),
    serviceId: resourceSelector.getServiceId(state),
    eventId: resourceSelector.getEventId(state),
    bookings: resourceSelector.getBookings(state),
    bookingErrors: resourceSelector.getBookingErrors(state),
    timeAvailable: resourceSelector.getTimeAvailable(state),
    selectedBookingDetail: resourceSelector.getSelectedBookingDetail(state),
    bookingsCancelled: resourceSelector.getBookingsCancelled(state),
    bookingUpdated: resourceSelector.getBookingUpdated(state),
    selectedEvents: resourceSelector.getSelectedEventResources(state),
    portalSettings: serviceModuleSelectors.loadPortalSttings(state),
    bookingCreated: resourceSelector.getBookingCreated(state),
    serviceUnit: resourceSelector.getServiceUnit(state),
    selectedTenant: tenantSelectors.getSelectedTenant(state),
    service: resourceSelector.getService(state),
    fromRedirect: resourceSelector.getActionRedirect(state),
    paymentAction: resourceSelector.getPaymentAction(state),
    booking: resourceSelector.getSetBooking(state),
    basket: checkoutSelectors.getBasket(state),
    carlendarSetting: resourceSelector.getCalendarSettings(state),
    itemQueue: resourceSelector.getItemQueue(state),
    chargeType: checkoutSelectors.getChargeType(state)
  };
}
export default withRouter(connect(mapStateToProps)(BookingCalendarCustom));
