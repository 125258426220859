import React, { Component } from "react";
import autobind from "react-autobind";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";

import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";

//Test loading modules, this would be moved to when use signs in

import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";

import "../../../sass/containers/residents.css";

import * as $ from "jquery";
import Button from "../../../components/Button/Button";

class ResidentProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "residents",
      residentId: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.residentId
        : "",
      resident: undefined,
      fname: "",
      lname: "",
      email: "",
      phone: "",
      addresslookupId: "",
      address: "",
      residentCreated: false,
      isApproved: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.isApproved
        : null,
      residentApprovalUpdated: null,
      residentUpdatedApprovalStatus: [],
      residentUpdated: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.residentUpdated
        : null
    };

    this.props.dispatch(residentActions.getAllResidents());

    this.addResidentValidation = null;

    autobind(this);
  }

  componentDidMount() {
    this.addValidation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.residents !== prevProps.residents) {
      let resi = _.find(
        this.props.residents,
        res => res.id === this.state.residentId
      );

      if (!_.isUndefined(resi))
        this.setState({
          resident: resi,
          phone:
            (!_.isUndefined(resi) && !_.isUndefined(resi.mobileNumber)) ||
              !_.isEmpty(resi.mobileNumber)
              ? resi.mobileNumber
              : "",
          email: !_.isUndefined(resi) ? resi.email : "",
          fullName: !_.isUndefined(resi)
            ? resi.firstName + " " + resi.lastName
            : "",
          avatarUrl: !_.isUndefined(resi) ? resi.avatarUrl : "",
          address: !_.isUndefined(resi)
            ? `${resi.addressLine1}` + `${resi.addressLine2 ? " " + resi.addressLine2 : ""}`
            : "",
          addresslookupId: !_.isUndefined(resi) ? resi.addressLookupId : "",
          isApproved: !_.isUndefined(resi) ? resi.isApproved : null
        });
    }
  }

  addValidation() {
    this.addResidentValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }

  validate(fromSubmit = false) {
    if (fromSubmit === true) {
      //this.formReset();
      let residentData = {
        phone: this.state.phone,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        address: this.state.address,
        addressId: this.state.addresslookupId,
        isApproved: this.state.isApproved,
        residentApprovalUpdatedStatus: this.state.residentApprovalUpdated,
        residentUpdated: this.location.state.residentUpdated
      };

      this.props.dispatch(residentActions.createNewResident(residentData));
      this.props.dispatch(residentActions.updateResident(residentData));
      // this.props.dispatch(residentActions.updateApprovalResident(residentData));

      this.setState({
        addResidentFormUsed: false
      });
    }
  }

  componentDidCatch() {
    // this.addResidentValidation.destroy();
  }

  formReset() {
    this.setState({
      fname: "",
      lname: "",
      email: "",
      addresslookupId: "",
      phone: "",
      address: ""
    });

    //this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.addResidentValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }

  handleInputChange(e) {
    let field = e.target.name;
    let value = e.target.value;

    this.setState({
      [field]: value
    });
  }

  handleArchiveClick() {
    this.props.history.push({
      pathname: "/concierge/residents/profile",
      state: {
        residentId: this.props.location.state.residentId,
        isApproved: this.state.isApproved,
        residentApprovalUpdatedStatus: this.state.residentApprovalUpdated
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {

    const {
      residentId,
      avatarUrl,
    } = this.state;
    const currentModule = _.find(
      this.props.modules,
      o => o.title === "Residents"
    );

    // const searched = _find(this.props.searchResidentsQueue, o => o.addressLine1 )
    /**used to style the profile image */
    let avatarStyle = {
      backgroundImage: `${"url(" + avatarUrl + ")"}`
    };

    return (
      <div>
        <div className={"container type-" + this.state.type + " view_profile"}>
          <FeatureArea
            type={this.state.type}
            pageHeader=""
            areaTitle="Residents"
            areaTagline={
              this.state.residentUpdated === true
                ? "Success! Resident has been updated"
                : "Resident Profile"
            }
            landing={false}
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            activeMenu={true}
          />
          {/* // {this.state.resident && ( */}
          <div className="content-wrapper">
            <div className="profile">
              <div className="image_holder">
                <div className="profile_image" style={avatarStyle} />
                <p>
                  {this.state.isApproved === true ? (
                    <Link
                    to={{
                        
                          pathname: "/concierge/residents/archive_profile",
                          state: {
                            residentId: residentId,
                            isApproved: this.state.isApproved
                          }
                        
                      }}
                      // onClick={e => {
                      //   this.props.history.push({
                      //     pathname: "/concierge/residents/archive_profile",
                      //     state: {
                      //       residentId: residentId,
                      //       isApproved: this.state.isApproved
                      //     }
                      //   });
                      // }}
                    >
                      {"Archive this resident"}{" "}
                    </Link>
                  ) : (
                      <a
                        onClick={e => {
                          this.props.history.push({
                            pathname: "/concierge/residents/archive_profile",
                            state: {
                              residentId: residentId,
                              isApproved: this.state.isApproved
                            }
                          });
                        }}
                      >
                        {"Re-activate this resident"}{" "}
                      </a>
                    )}
                </p>
                <h1 style={{ color: currentModule.imageOverlayTextColour }}>
                  {this.state.fullName}
                </h1>
                <h2>{this.state.address}</h2>
              </div>

              <form onSubmit={this.handleSubmit}>
                <div
                  className={
                    this.state.isApproved ? "inputs" : "archived inputs"
                  }
                >
                  <InputComponent
                    label="Email"
                    name="email"
                    placeHolder="Email"
                    disabled={true}
                    value={this.state.email || ""}
                    parsley={{
                      "data-parsley-type": "email",
                      "data-parsley-validate-if-empty": false,
                      "data-parsley-error-message": "Please enter a valid email"
                    }}
                  />

                  <InputComponent
                    label="Phone"
                    name="phone"
                    placeHolder="Phone"
                    disabled={true}
                    value={this.state.phone || ""}
                    parsley={{
                      "data-parsley-tye": "number",
                      "data-parsley-minlength": 2,
                      "data-parsley-error-message":
                        "Please enter a valid phone number"
                    }}
                  />
                </div>
              </form>
            </div>
            <div
              className={
                "validation-messages" +
                (this.state.addResidentFormValid ? " valid" : " invalid")
              }
              id="validation-messages"
            >
              {this.state.addResidentFormUsed && <p> </p>}
              <div>
                {this.props.residentCreatedstatus &&
                  (this.state.residentCreated === true ? (
                    <p className="message type-success">Member created</p>
                  ) : (
                      this.state.error && (
                        <p className="message error"> {this.state.error}</p>
                      )
                    ))}
              </div>
              {this.state.isApproved === true ? (
                <ButtonGroup
                  sizes={["smedium", "medium"]}
                  leftText={""}
                  rightText="Edit Resident"
                  //rightDisabled={this.state.addResidentFormValid === false}
                  leftClick={() => {
                    this.props.history.push({
                      pathname: "/",
                      state: { setModule: "Residents" }
                    });
                  }}
                  rightClick={e => {
                    this.props.history.push({
                      pathname: "/concierge/residents/profileinfo",
                      state: { residentId: residentId }
                    });
                  }}
                />
              ) : (
                  <div className="content-wrapper button ">
                    <Button
                      size="medium"
                      text="Home"
                      handleClick={e => {
                        this.props.history.push({
                          pathname: "/",
                          state: { residentId: residentId }
                        });
                      }}
                    />
                  </div>
                )}
            </div>
          </div>

          <FooterNavigation />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modules: moduleSelectors.loadModules(state),
    residents: residentSelectors.getResidents(state),
    searchResidentsQueue: residentSelectors.getSearchedResidents(state),
    residentUpdatedStatus: residentSelectors.residentUpdatedStatus(state),
    residentUpdatedApprovalStatus: residentSelectors.residentUpdatedApprovalStatus(
      state
    )
  };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ResidentProfile
);
