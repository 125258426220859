import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import autobind from "react-autobind";
import { createBrowserHistory } from 'history';

import * as moduleSelectors from "../../store/ServiceModules/reducer";
import ModuleMenu from "../ModuleMenu/ModuleMenu";
import _ from "lodash";
import "../../sass/components/FeatureArea/index.css";
import history from '../../history';
var PropTypes = require('prop-types');

const browserHistory = createBrowserHistory();
class FeatureArea extends Component {
  constructor(props) {
    super(props);

    let type = (this.props.type).replace(/\b\w/g, function (l) { return l.toUpperCase() })
    let overlayColor;
    let moduleImage;

    let currentModule = _.find(this.props.modules, o => o.title === type);
    moduleImage = currentModule && currentModule.imageUrl;

    this.state = {
      overlayColor: overlayColor,
      moduleImage: moduleImage,
      activeModule: currentModule
    };
    autobind(this);

  }

  // UNSAFE_componentWillMount() {
  //   let type = (this.props.type).replace(/\b\w/g, function (l) { return l.toUpperCase() })
  //   let overlayColor;
  //   let moduleImage;

  //   let currentModule = _.find(this.props.modules, o => o.title === type);
  //   moduleImage = currentModule && currentModule.imageUrl;

  //   this.setState({
  //     overlayColor: overlayColor,
  //     moduleImage: moduleImage,
  //     activeModule: currentModule
  //   });
  // }
  handleModuleMenuSelected(module) {

    this.props.history.push({
      pathname: "/",
      state: { module: module }
    });
   
    window.scrollTo(0, 0)
    
  }
  render() {
    const {
      pageHeader,
      areaTitle,
      areaTagline,
      type,
      imageBG,
      overlayColor,
      landing,
      activeMenu,
      modules
    } = this.props;

    const { activeModule } = this.state;

    let bgStyle = {
      backgroundImage: `${"url("} ${!imageBG ? this.state.moduleImage : imageBG} ${")"}`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      backgroundColor: overlayColor
    };

    const landing_exception = {};

    //console.log("fA: ", this.props)
    return (
      <div>
        <div className={"feature type-" + type} style={bgStyle}>
          <div>
            <h2>{areaTitle}</h2>
          </div>
        </div>
        <div className="main-container">
          <h4 className="tagline">{areaTagline}</h4>
        </div>
        <div className="main-container">
          {activeMenu && activeMenu === true ?

            <ModuleMenu
              modules={modules}
              handleModuleMenuSelected={e => this.handleModuleMenuSelected(e)}
              selectedModule={activeModule && activeModule.title}
            />

            :
            <aside
              id="subbnav"
              className={`${"middleNav disabled"} ${landing ? "gone" : ""}`}
              style={landing_exception}
            >
              {this.props.modules &&
                _.map(this.props.modules, (module, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        !_.isUndefined(this.props.type) &&
                          this.props.type.toLowerCase() === module.title.toLowerCase()
                          ? "current-module"
                          : ""
                      }
                    >
                      <span>{module.title}</span>
                    </div>
                  );
                })}
            </aside>
          }

        </div>
        {landing !== true ?
          <div className={type + " main-container customLabelWrap visitor-heading"}>
            <h1 style={{ color: overlayColor }}>{pageHeader}</h1>
          </div>
          : ""}

      </div>
    );
  }
}

FeatureArea.propType = {

  type: PropTypes.string,
  areaTitle: PropTypes.string,
  areaTagline: PropTypes.string,
  pageHeader: PropTypes.string,
  imageBG: PropTypes.string,
  overlayColor: PropTypes.string,
  landing: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    modules: moduleSelectors.loadModules(state)
  };
}

export default  withRouter(connect(mapStateToProps)(FeatureArea));
