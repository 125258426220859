import React, { Component } from "react";
import { connect } from "react-redux";
import autobind from "react-autobind";
import _ from "lodash";
import moment from "moment";
import * as $ from "jquery";
import {withRouter} from 'react-router-dom';
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";
import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import Button from "../../../components/Button/Button";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import * as keysActions from "../../../store/Keys/actions";
import * as keySelectors from "../../../store/Keys/reducer";
import * as addressLookupActions from "../../../store/AddressLookUp/actions";
import * as addressLookupSelectors from "../../../store/AddressLookUp/reducer";
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";


import * as moduleActions from "../../../store/ServiceModules/actions";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";

import DataList from "../../../components/DetailList/DetailList";
import DataListMulti from "../../../components/DetailList/DetailList5Column";
import "../../../sass/containers/Keys/index.css";
import "../../../sass/components/DateTimePicker/index.css";


class KeyProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "keys",
      keyId: "",
      overlayColor: "",
      keyBarcode: "",
      phoneNumber: "",
      email: "",
      currentDate: moment(new Date()).format("DD/MM/YYYY"),
      currentTime: moment(new Date()).format("HH:mm "),
      dateReturned: undefined,
      dateAssigned: undefined,
      timeAssigned: undefined,
      expectedReturnDate: moment(),
      name: "",
      selectedKey: "",
      selectedKeyLog: undefined,
      topLink: false,
      archive: ""
    };

    this.props.dispatch(keysActions.getAllKeys());
    this.props.dispatch(keysActions.getKeyStatuses());
    this.props.dispatch(residentActions.getAllResidents());
    this.props.dispatch(addressLookupActions.loadAddresses());
    this.props.dispatch(moduleActions.loadModules());
    this.updateKeyValidation = null;
    autobind(this);
  }


 
  componentDidMount() {
    const { keyId } = this.state;
    this.addValidation();

    let overlayColor;
    let currentModule = _.find(this.props.modules, o => o.title === "Keys");
    overlayColor = currentModule && currentModule.imageOverlayTextColour;

    this.setState({
      overlayColor: overlayColor
    });

    
    if ( !_.isUndefined(this.props.location.state) && this.props.location.state.keyId) {
      this.props.dispatch(keysActions.loadKeyLogList(null, keyId));
      this.handleKeyLogList([{ keyId: this.props.location.state.keyId }]);

      this.setState({
        keyId: this.props.location.state.keyId
      });
    }
    
  }
  
  // componentWillUnmount() {
  //   this.props.dispatch(keysActions.resetAction("*"));
  //   this.props.dispatch(keysActions.setSelectedKey("*"))
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.keys !== this.props.keys && this.props.keys.length > 0) {
      let currentKey = _.find(this.props.keys, o => o.id === this.state.keyId);

      this.setState({
        selectedKey: currentKey,
        keyBarcode: currentKey ? currentKey.barcode : "",
        status: currentKey ? currentKey.status : "",
        archive: currentKey.status
      });

      this.trackCurrentKey(currentKey.id);
    }

    if (prevProps.keyLog !== this.props.keyLog && this.props.keyLog.length > 0) {
      let currentKeyLog = this.props.keyLog[0];

      if (currentKeyLog) {
        let keyRecipient = {};

        if (currentKeyLog.recipientType !== "Visitor") {
          keyRecipient = {
            name: `${currentKeyLog.resident.firstName} ${
              currentKeyLog.resident.lastName
              }`,
            email: currentKeyLog.resident.email,
            phoneNumber: !currentKeyLog.resident.mobileNumber ? "" : currentKeyLog.resident.mobileNumber
          };
        } else {
          keyRecipient = {
            name: `${currentKeyLog.visitor.firstName} ${
              currentKeyLog.visitor.surname
              }`,
            email: currentKeyLog.visitor.email,
            phoneNumber: ""
          };
        }
        this.setState({
          selectedKeyLog: currentKeyLog,
          name: keyRecipient.name,
          phoneNumber: !keyRecipient.phoneNumber ? "" : keyRecipient.phoneNumber,
          email: keyRecipient.email,
          expectedReturnDate: moment(currentKeyLog.expectedDateReturn),
          dateAssigned: moment(currentKeyLog.dateTimeReceived).format("DD/MM/YYYY"),
          timeAssigned: moment.utc(currentKeyLog.dateTimeReceived).format("HH:mm ")
        });
      }
    }

    if (
      this.state.topLink !== prevState.topLink &&
      this.state.topLink === true
    ) {
      this.onStartTop();
    }

    if (this.state.keyId !== prevState.keyId && this.state.keyId) {
      this.trackCurrentKey(this.state.keyId);
      this.props.dispatch(keysActions.loadKeyLogList(null, this.state.keyId));
    }

    if (this.props.keyActionStatus !== prevProps.keyActionStatus) {
      this.props.dispatch(keysActions.getAllKeys());
    }

  }

  onStartTop() {
    window.scrollTo(0, 0);
    this.setState({
      topLink: false
    });
  }

  handleChange(e) {
    var field = e.target.name;
    var value = e.target.value;
    this.setState({
      [field]: value
    });
  }
  trackCurrentKey(id) {
    this.props.dispatch(keysActions.setSelectedKey(id, success => {
     
    }));
    
    this.handleKeyLogList([{ keyId: id }]);
      
  }
  handleKeyLogList(data) {
    this.props.dispatch(keysActions.getKeyLogByKeyStatus(data));
  }
  handleHistoryView(e) { }

  handleViewClick(e) {
    this.setState({
      topLink: true
    });
    this.props.history.push({
      pathname: "/concierge/keys/profile",
      state: {
        keyId: e
      }
    });

    let updateCurrentKey = _.find(this.props.keys, item => item.id === e);
    this.setState({
      keyId: e,
      selectedKey: updateCurrentKey,
      keyBarcode: updateCurrentKey ? updateCurrentKey.barcode : "",
      status: updateCurrentKey ? updateCurrentKey.status : ""
    });

    this.handleKeyLogList([{ keyId: e }]);
  }
  addValidation() {
    this.updateKeyValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }

  validate(fromSubmit = false) {
    this.props.history.push({
      pathname: "/concierge/keys/assign",
      state: { keyId: this.state.keyId }
    });
    window.scrollTo(0, 0);
  }

  validateReturn(fromSubmit = false) {
    this.props.history.push({
      pathname: "/concierge/keys/return",
      state: {
        keyId: this.state.keyId,
        keyLogId: this.state.selectedKeyLog.id
      }
    });

    window.scrollTo(0, 0);
  }

  validateStatus(fromSubmit = false) {
    this.updateKeyValidation.validate({ force: true });
    const valid = this.updateKeyValidation.isValid();

    this.setState({
      updateKeyFormValid: valid,
      updateKeyFormUsed: false
    });


    if (valid === true && fromSubmit === true) {
      this.formReset();

      this.handleUpdate({
        keyId: this.state.keyId,
        status: this.state.archive.toLowerCase()
      });
      this.setState({
        updateKeyFormUsed: true
      });

      setTimeout(() => {
        this.props.history.push({
          pathname: "/",
          state: { module: "Keys" }
        });
        window.scrollTo(0, 0);
      }, 5001);

    }
  }
  formReset() {
    this.setState({
      archive: ""
    });

    $(this.refs.validate)
      .get(0)
      .reset();

    this.updateKeyValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
  }

  handleUpdate(data) {
    this.props.dispatch(keysActions.updateKeyStatus(data), success => {
      if (success === true) {
        
      }
    });
  }
  formatString(str) {
    if (str && str.length > 10) {
      return str.substr(0, 4) + "..." + str.substr(str.length - 3, str.length);
    }
    return str;
  }

  handleReturn() {

  }

  render() {
    const {
      phoneNumber,
      email,
      name,
      overlayColor,
      selectedKey,
      dateAssigned,
      timeAssigned,
      expectedReturnDate
    } = this.state;

    const { keys, addressLookup, keyHistory } = this.props;

    const keyStyle = {
      color: overlayColor
    };



    const currentModule = _.find(this.props.modules, o => o.title === "Keys");



    /**Get address for current key profile */
    let address = selectedKey
      ? _.find(addressLookup, addr => addr.id === selectedKey.lookupAddressId)
      : "";

    /**Get related key, filter keys with selected key lookup address id */
    let relatedKeys = selectedKey
      ? _.filter(
        _.map(
          _.filter(
            keys,
            item => item.lookupAddressId === selectedKey.lookupAddressId
          ),
          keyItem => {
            let addr = _.find(addressLookup, { id: keyItem.lookupAddressId });
            if (keyItem.id !== selectedKey.id)
              return {
                id: keyItem.id,
                barcode: keyItem.barcode,
                address: addr
                  ? `${addr.addr1FlatNumber} ${addr.addr2BuildingName}`
                  : ""
              };

            return null;
          }
        ),
        o => o !== null
      )
      : [];

    /**compile keyHistry to object format needed using map */
    let keyHistoryList = _.take(
      _.map(keyHistory, logItem => {
        /**Find the address id look for key */
        let keyData = _.find(keys, o => o.id === logItem.keyId);
        let keyAddr = _.find(
          addressLookup,
          addr => addr.id === keyData.lookupAddressId
        );

        return {
          id: logItem.keyLogId,
          status: logItem.status,
          name: `${logItem.firstName} ${logItem.lastName}`,
          type: logItem.recipientType,
          address: `${keyAddr.addr1FlatNumber}${
            keyAddr.addr2BuildingName ? ", " + keyAddr.addr2BuildingName : ""
            }`, 
          dateAssigned: !_.isUndefined(logItem.dateAssigned)
            ? moment(logItem.dateAssigned).format("DD/MM/YYYY")
            : "",
          dateReturned: !_.isUndefined(logItem.dateReturned) && (logItem.dateReturned > logItem.dateAssigned)
            ? moment(logItem.dateReturned).format("DD/MM/YYYY")
            : ""

        };

      }),
      5
    );
    let ArchiveStatus = [
      { value: "faulty", label: "Faulty" },
      { value: "lost", label: "Lost" }
    ];

   
    return (
      <div>
        <div className={"container key_profile"}>
          <FeatureArea
            type={this.state.type}
            areaTagline="Key Profile Information"
            pageHeader={"Key ID " + this.formatString(this.state.keyBarcode)}
            areaTitle="Key Management"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            landing={false}
						activeMenu={true}
          />
          <div className="content-wrapper">
            <span className="address">
              {address
                ? `${address.addr1FlatNumber} ${address.addr2BuildingName} `
                : ""}
            </span>

            {this.state.status === "assigned" ||
              this.state.status === "overdue" ? (
                <form className="double-margin-top">
                  <div className="inputs marginKeys">
                    <InputComponent
                      label="Assignee"
                      name="name"
                      placeHolder="Assignee"
                      handleChange={e => {
                        this.handleChange(e);
                      }}
                      value={name}
                    />
                  </div>
                  <div className="inputs two-column marginKeys">
                    <InputComponent
                      label="Phone Number"
                      name="phoneNumber"
                      placeHolder="Phone Number"
                      handleChange={e => {
                        this.handleChange(e);
                      }}
                      value={phoneNumber}

                    />

                    <InputComponent
                      label="Email"
                      name="email"
                      placeHolder="email"
                      handleChange={e => {
                        this.handleChange(e);
                      }}
                      value={email}
                      parsley={{
                        "data-parsley-type": "email",
                        "data-parsley-validate-if-empty": false,
                        "data-parsley-error-message": "Please enter a valid email"
                      }}
                    />
                  </div>

                  <div className="inputs two-column marginKeys">
                    <div className="inputs two-column">
                      <InputComponent
                        label="Date Collected"
                        name="dateAssigned"
                        value={dateAssigned}
                        handleChange={e => this.handleChange(e)}
                      />
                      <InputComponent
                        label="Time Received"
                        name="timeAssigned"
                        value={timeAssigned}
                        handleChange={e => this.handleChange(e)}
                      />
                    </div>

                    <InputComponent
                      ref="expectedDate"
                      type="datePicker"
                      label="Expected Return"
                      name="selectedDate"
                      defaultMin={moment()}
                      defaultSelect={expectedReturnDate}
                      handleDateChange={this.handleDateChange}
                    />
                  </div>

                  <div className="align-center">
                    <Button
                      size="medium"
                      text="Return Key"
                      handleClick={e => this.validateReturn(true, e)}
                    />
                  </div>
                </form>
              ) : (
                <div className="align-center">
                  <Button
                    size="medium"
                    text="Assign Key"
                    handleClick={e => this.validate(true, e)}
                    isDisabled={this.state.status === "faulty" || this.state.status === "lost" ? true : false}
                  />
                </div>
              )}

            <div className="key_history">
              <h1 style={keyStyle}>Keys History</h1>

              <div className="tableCont">
                <DataListMulti
                  data={keyHistoryList}
                  listType="key-history-list"
                  headingsAlone={true}
                  actionClick={e => this.handleHistoryView(e)}
                />
              </div>
            </div>

            <div className="related_keys">
              <h1 style={keyStyle}>Related Keys</h1>
              {
                <div className="tableCont">
                  <DataList
                    data={relatedKeys}
                    listType="related-key-list"
                    headingsAlone={false}
                    actionClick={e => this.handleViewClick(e)}
                  />
                </div>
              }
            </div>

            <div className="key_status">
              <form
                ref="validate"
                onSubmit={e => {
                  e.preventDefault();
                }}
                data-parsley-errors-container="#validation-messages"
                data-parsley-focus="none"
              >
                <h1 style={keyStyle}>Key Archive</h1>

                <InputComponent
                  type="select"
                  label="Status"
                  name="archive"
                  placeHolder="Archive Key"
                  ref="formSelect-archive"
                  value={this.state.archive}
                  handleChange={e => {
                    this.handleChange(e);


                    !_.isUndefined(e.target.label) &&
                      this.setState({ archive: e.target.label });

                    this.validateStatus();
                  }}
                  options={ArchiveStatus}
                  parsley={{
                    "data-parsley-required": true,
                    "data-parsley-error-message": "Please select status"
                  }}
                />
                <div
                  className={
                    "validation-messages" +
                    (this.state.updateKeyFormValid ? " valid" : " invalid")
                  }
                  id="validation-messages"
                >
                  <div>
                    {this.props.keyActionStatus &&
                      !_.isUndefined(this.props.keyActionStatus.action) &&
                      (this.state.updateKeyFormUsed && this.state.updateKeyFormUsed === true) && (this.state.updateKeyFormValid && this.state.updateKeyFormValid === true) ? (
                        <p className="message type-success">Key Archived</p>
                      ) : (
                        this.state.error && (
                          <p className="message error"> {this.state.error}</p>
                        )
                      )}
                  </div>
                </div>
                <ButtonGroup
                  sizes={["smedium", "medium"]}
                  leftText=""
                  rightText="Save Changes"
                  leftClick={() => {
                    this.props.history.push({
                      pathname: "/",
                      state: { module: "Keys" }
                    });
                    window.scrollTo(0, 0);
                  }}
                  rightClick={e => {
                    this.validateStatus(true);
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <FooterNavigation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    keys: keySelectors.loadAllKeys(state),
    addressLookup: addressLookupSelectors.getAddresses(state),
    residents: residentSelectors.getResidents(state),
    keyActionStatus: keySelectors.getKeyActionStatus(state),
    keyLog: keySelectors.loadKeysLog(state),
    modules: moduleSelectors.loadModules(state),
    keyHistory: keySelectors.getKeyLogList(state)
  };
}

export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(
  KeyProfileScreen
));
