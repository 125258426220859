import React, { Component } from "react";
import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../components/FooterNavigation/FooterNavigation";
import LoginForm from "../components/LoginForm/LoginForm";
import { connect } from "react-redux";
import * as authActions from "../store/auth/actions";
import * as authSelectors from "../store/auth/reducer";
import * as moduleActions from "../store/ServiceModules/actions";
import * as settingActions from "../store/ConciergeSetting/actions";
import * as settingSelector from "../store/ConciergeSetting/reducer";
import autoBind from "react-autobind";

import {createBrowserHistory} from 'history';
import "../sass/static/logout.css";

let history = createBrowserHistory();
class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loginSuccessfull: null
    };
    autoBind(this);
    this.props.dispatch(settingActions.getGlobalSetting());

  }

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      history.push("/");
    }
  }

  handleEmailChange(e) {
    this.props.dispatch(authActions.setEmail(e.target.value));
  }

  handlePasswordChange(e) {
    e.target.value.length < 8
      ? this.setState({ error: true })
      : this.setState({ error: false });

    this.props.dispatch(authActions.setPassword(e.target.value));
  }

  handleLogin(e) {
    e.preventDefault();

    this.props.dispatch(
      authActions.login(this.props.email, this.props.password, success => {      
        if (success === true) {  
          this.props.dispatch(moduleActions.getModules());
          this.props.dispatch(authActions.fetchConciergeDetails(success_ => {
            if (success_ === true) {
              
            }
          }))

        }else{
          console.log("status ", success);
          this.setState({
            loginSuccessfull: success
          });
        }
      })
    );
  }

  render() {
    const { isLoggedIn, setting } = this.props;
    const mainBackground = setting && setting.mainBackgroundImage;

    const body_style = {
      backgroundImage: "url('" + mainBackground + "')",
      backgroundRepeatY: "no-repeat",
      backgroundSize: "cover"
    };

    return (
      <div className="loginScreen" style={body_style}>
        <HeaderNavigation isLoggedIn={isLoggedIn} setting={setting} />
        <div className="loginWrap">
          <h2 className="loginScreen">Concierge System</h2>
        </div>
        <div className="main-wrp-login">
          <div className="login-form-wrapper">
            <LoginForm
              handleEmailChange={this.handleEmailChange}
              handlePasswordChange={this.handlePasswordChange}
              username={this.props.username}
              password={this.props.password}
              //error={this.state.error}
              handleSubmit={this.handleLogin}
              actionSuccess={this.state.loginSuccessfull}
              mode={"login"}
            />
          </div>
        </div>

        <FooterNavigation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    email: authSelectors.getEmail(state),
    password: authSelectors.getPassword(state),
    loginSuccess: authSelectors.getLoginSuccess(state),
    setting: settingSelector.loadGlobalSetting(state)
  };
}

export default connect(mapStateToProps)(LoginScreen);
