import React, { Component } from "react";
import { Link } from 'react-router-dom';

import _ from "lodash";
import "../../sass/components/DetailList/index.css";
import DeleteBtn from "../../images/delete-btn/bin.png";
import DeleteWhiteBtn from "../../images/delete-bin-white/bin-white.png";
import InstructionBtn from "../../images/special-instructions/special-instructions.png";
import Popover from 'react-tiny-popover'

var PropTypes = require('prop-types');
/**
 * Display list of up to two properties with an con in the third column. Data is passed in as an array and mapped to appropriate
 * column with the listsConfig.
 */

class DetailListWithOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsDeletePopoverOpen: true,
      activeRow: ""

    }



    this.listsConfig = {
      types: [
        {
          name: "visitor-add-meeting",
          headings: ["Visitor Name", "Company", "Special Instruction"],
          fields: ["name", "companyName", "specialInstruction", "index"],
          iconClass: "ion-close-round"
        },
        {
          name: "visitor-confirm-meeting",
          headings: ["Visitor", "Company"],
          fields: ["firstName", "companyName", "index"],
          iconClass: "ion-close-round"
        },
        {
          name: "parcel-check-in",
          headings: ["Parcel Barcode", "Location"],
          fields: ["barcode", "locationFullName", "index"],
          iconClass: "ion-close-round"
        },
        {
          name: "parcel-review",
          headings: ["Parcel Barcode", "Location"],
          fields: ["barcode", "locationFullName", "index"],
          iconClass: "ion-edit"
        },
        {
          name: "parcel-confirm",
          headings: ["Parcel Barcode", "Location"],
          fields: ["barcode", "location.locationName", "id"],
          iconClass: "ion-checkmark-round"
        },
        {
          name: "car",
          headings: ["Name", "Reg. Number"],
          fields: ["name", "reg"],
          iconClass: "ion-close-round"
        },
        {
          name: "parcel-overview-check-in",
          headings: ["Parcel Barcode", "Tenant"],
          fields: ["barcode", "fullName"],
          iconClass: "ion-arrow-up-c"
        },
        {
          name: "parcel-overview-check-out",
          headings: ["Parcel Barcode", "Tenant"],
          fields: ["barcode", "fullName"],
          iconClass: "ion-arrow-down-c"
        },
        {
          name: "visitor",
          headings: ["Visitor Name", "Visitor Company"],
          fields: ["fullName", "company", "id"],
          iconClass: "ion-checkmark-round"
        },
        {
          name: "tenant",
          headings: ["Tenant", "Company"],
          fields: ["fullName", "company", "id"],
          iconClass: "ion-checkmark-round"
        },
        {
          name: "resident-view-list",
          headings: ["Resident", "Address"],
          fields: ["fullName", "address", "id"],
          link: "view"
        },
        {
          name: "key-view-list",
          headings: ["Status", "Address"],
          fields: ["status", "address", "id"],
          link: "view"
        },
        {
          name: "related-key-list",
          headings: ["Key ID Code", "Key"],
          fields: ["barcode", "address", "id"],
          iconClass: "ion-forward"
        }
      ]
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //Change trigere fro selected row, clear selection on deselct trigger recieved
    if (this.props.selectDetailReset !== nextProps.selectDetailReset) {

      if (_.isUndefined(nextProps.selectDetailReset))
        this.setState({
          selectedItem: undefined
        })
    }
  }

  // Utility function so fields: [] options above can optionally specifiy a path to a nested value
  // e.g. for a parcel location.locationName
  // https://stackoverflow.com/questions/6491463
  accessObjectByString(o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }


  handleClick = (idx) => {
    // const { target: { value } } = event;

    const selectedRow = idx;

    // And do whatever you need with it's value, for example change state 
    this.setState({
      IsDeletePopoverOpen: false,
      activeRow: selectedRow
    });
  };

  render() {
    const { listType, data, pageClass } = this.props;

    let { headingsAlone } = this.props;

    ;
    // const config = this.listsConfig.types.find(type => {
    //   return type.name === listType;
    // });
    const config = _.find(this.listsConfig.types, type => {
      return type.name === listType;
    });

    if (_.isUndefined(headingsAlone)) {
      headingsAlone = false;
    }

    if ((_.isUndefined(data) || data.length === 0) && headingsAlone === false) {
      return false;
    }


    return (
      <div className="visitor-section list-section">
        <div className="heading">
          <div>Visitor Name</div>
          <div>Company</div>
          <div></div>
        </div>

        {data.length > 0 &&
          data.map((row, rowIndex) => {
            return (
              <div className={`${"detail "}${this.state.selectedItem === rowIndex ? "selected" : ""}`} key={rowIndex} >
                <div className="info" onClick={() => {


                  if (this.state.selectedItem === rowIndex) {
                    this.setState({
                      selectedItem: undefined
                    })
                    this.props.selectedDetail(
                      undefined
                    );
                  } else {
                    this.setState({
                      selectedItem: rowIndex
                    })
                    this.props.selectedDetail(
                      this.accessObjectByString(
                        data[rowIndex],
                        config.fields[3]
                      )
                    );
                  }

                }}>
                  <div className="visitorName" > <span>
                    {this.accessObjectByString(
                      data[rowIndex],
                      config.fields[0]
                    )}</span></div>
                  <div className="company"> {this.accessObjectByString(
                    data[rowIndex],
                    config.fields[1]
                  )}
                  </div>
                </div>
                {
                  <div className="actions">

                    {this.state.activeRow !== rowIndex ?
                      <div className="actions">
                        <Link className="bin" onClick={(e) => {

                          this.handleClick(rowIndex)
                          if (this.props.resetForm) {
                            this.props.resetForm(true);
                          }

                        }}><img src={DeleteBtn} alt="" /></Link>
                      </div>
                      :
                      <div className="actions">
                        <Link className="back" onClick={(e) => {
                          e.preventDefault();
                          this.setState({ activeRow: "" })
                        }}>Back</Link>
                        <Link className="bin del-bin" onClick={() => {

                          this.setState({
                            IsDeletePopoverOpen: false,
                            activeRow: "",
                            selectedItem: undefined
                          });

                          this.props.actionClick(
                            this.accessObjectByString(
                              data[rowIndex],
                              config.fields[3]
                            )
                          );


                        }}><img src={DeleteWhiteBtn} alt="" /></Link>
                      </div>
                    }

                  </div>
                }
              </div>

            )
          })}


        {data.length === 0 && (
          <div>
            <span className="list-messages">
              {this.props.emptyMessage}
            </span>
          </div>
        )}


      </div>




    );
  }
}

DetailListWithOverlay.propTypes = {

  /** First Heading Text for the coloumn */
  heading1: PropTypes.string,
  /** Second Heading Text for the coloumn */
  heading2: PropTypes.string,
  /** Third Heading Text for the coloumn */
  heading3: PropTypes.string,
  /** Message to display if the table is empty */
  emptyMessage: PropTypes.string,
  /** Function that is called for clicking on the icon */
  actionClick: PropTypes.func,
  /** If list data is empty can headings be displayed on their own? */
  headingsAlone: PropTypes.bool,
  /** The configuration to use from listsConfig in the component constrctor. (specifies icon and data to column mappings) */
  listType: PropTypes.string,
  /** An array of objects, fields are variable as long as configuration is available for mappings in listsConfig */
  data: PropTypes.array,
  /**The class name for the detail list */
  pageClass: PropTypes.string
};

export default DetailListWithOverlay;
