import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Datepicker from 'react-datepicker'; //https://www.npmjs.com/package/reactjs-datepicker
import Flatpickr from 'react-flatpickr'; //https://chmln.github.io/flatpickr/examples/#time-picker
import autobind from 'react-autobind';
import _ from 'lodash';
import * as $ from 'jquery';

import ToggleButton from '../ToggleButton/ToggleButton';
import InputComponent from '../Input/Input';
import Button from '../Button/Button';
import AriaModal from 'react-aria-modal';
import DotNav from '../DotNav/DotNav';
import TenantAutoSuggest from '../TenantAutoSuggest/TenantCompanyAutoSuggest';
import { ServicesUnit } from '../../helpers/configHelper';

import * as resourceActions from '../../store/Resources/actions';
import * as resourceMonthlyActions from '../../store/ResourcesMonthly/actions';
import * as resourceSelectors from '../../store/Resources/reducer';
import * as checkoutActions from '../../store/Checkout/actions';
import * as checkoutSelectors from '../../store/Checkout/reducer';
import * as tenantActions from '../../store/Tenants/actions';

import * as serviceModuleActions from '../../store/ServiceModules/actions';
import '../../sass/components/EventModal/index.css';

import '../../sass/components/BigCalendar/flatpickr.min.css';
import '../../sass/components/ToggleButton/index.css';

var PropTypes = require('prop-types');
/**
 * Main Modal event, diferent modal states loaded as type is specified
 */

const calendarViews = {
	Day: 'day',
	Week: 'week',
	WorkWeek: 'work_week',
	Month: 'month'
};
/**Constant values for modale screens loaded */
const ModalScreenType = {
	CreateScreen: 'create',
	EditScreen: 'edit',
	BookingQuestionScreen: 'booking_question',
	CheckOut: 'checkout',
	BookingSuccess: 'booking_success',
	BookingError: 'booking_error',
	EditBookingQuestionScreen: 'edit_booking_question',
	BookingAttentionWarning: 'booking_attention_warning',
	BookingAttentionSuccess: 'booking_attention_success'
};

const activeScreens = {
	screens: [
		{ type: 'create', pages: [ 'create', 'booking_question', 'checkout' ] },
		{ type: 'edit', pages: [ 'edit', 'edit_booking_question' ] },
		{ type: 'create-checkout', pages: [ 'create', 'checkout' ] }
	]
};

/***Constant values for modal screen actions */
const TriggerTypes = {
	CreateBooking: 'create_booking',
	BlockBooking: 'block_booking',
	CancelBooking: 'cancel_booking',
	UpdateBooking: 'update_booking'
};

const BookingQuestionFieldType = {
	SpecialInstructions: 'special_instructions',
	EventDescription: 'event_description',
	Other: 'questions'
};

const EditFieldStatus = {
	Editing: 'editing',
	Discarded: 'discarded'
};

const EditButtonText = {
	EditReservation: 'Edit Reservation',
	CancelReservation: 'Cancel Reservation',
	SaveChanges: 'Save Changes',
	DiscardChanges: 'Discard Changes'
};

const PaymentOptions = {
	Account: 1,
	Card: 2
};

const ActionMessages = {
	success: 'You have successfully paid for this booking. A confirmation email will be sent to the relevant tenant.',
	fail: 'Sorry, your booking has been unsuccessfull',
	cancel_success: 'You have successfully cancelled this reservation.',
	cancel_fail: 'Unable to cancel this booking.'
};
class EventModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slots: this.props.slots,
			switchActive: false,
			addTenantFormUsed: false,
			timeFrom: new Date(moment(this.props.startDate)),
			timeTo: new Date(moment(this.props.endDate)),
			specialInstruction_chk: false,
			special_instructions: '',
			event_description: '',
			modalScreen: this.props.type,
			selecteSlotTimes: [],
			questions: this.props.bookingQuestions ? this.props.bookingQuestions : [],
			timeStep: this.props.timeStep,
			selectedEventId: '',
			allow_edit: !_.isUndefined(this.props.selectedSlot) ? !this.props.selectedSlot.past : false,
			slotDataRefreshed: false,
			triggerAction: '',
			booking_action_valid: false,
			field_active: false,
			edit_status: undefined,
			edit_button_text: EditButtonText.EditReservation,
			cancel_button_text: EditButtonText.CancelReservation,
			currentSetBasketSlot: undefined,
			memberId: '',
			selectedTenant: undefined,
			resources: '',
			selectedOption: null,
			activeScreen: this.props.type
				? _.find(activeScreens.screens, (screenItem) => screenItem.type === this.props.type)
				: undefined,
			payment_option_selected: false,
			payment_type: '',
			bookingDataUpdated: false
		};

		this.addTenantValidation = null;
		this.props.dispatch(serviceModuleActions.getPortalSetting());

		autobind(this);
	}


	componentDidMount() {
		const { selectedSlot, bookingQuestions } = this.props;
		this.addValidation();

		let questions = [];

		if (
			this.props.type === ModalScreenType.CreateScreen &&
			this.props.bookingQuestions &&
			this.props.bookingQuestions.length === 0
		) {
			this.setState({
				activeScreen: _.find(activeScreens.screens, (screenItem) => screenItem.type === 'create-checkout')
			});
		}
		//set initial state for booking question state text filed on edit screen
		if (this.state.modalScreen === ModalScreenType.EditScreen && !_.isUndefined(selectedSlot)) {
			if (!_.isUndefined(selectedSlot.questions) && selectedSlot.questions.length > 0) {
				questions = _.map(selectedSlot.questions, (que, i) => {
					return { ...que, id: Number(i) };
				});
			} else {
				questions = _.map(bookingQuestions, (que) => {
					return { ...que };
				});
			}
			let special_instr_field = _.find(questions, (q) => {
				let field_name = _.split(q.name, ' ');
				return _.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.SpecialInstructions;
			});

			let event_descr_field = _.find(questions, (q) => {
				let field_name = _.split(q.name, ' ');
				return _.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.EventDescription;
			});

			let event_descr_answer = event_descr_field
				? _.find(selectedSlot.answers, { question_id: event_descr_field.id })
				: undefined;

			let descr_answer = !_.isUndefined(event_descr_answer)
				? event_descr_answer.answer ? event_descr_answer.answer : ''
				: '';

			let special_instruct_answer = special_instr_field
				? _.find(selectedSlot.answers, { question_id: special_instr_field.id })
				: undefined;

			let spec_answer = !_.isUndefined(special_instruct_answer) ? special_instruct_answer.answer : '';

			this.setState({
				event_description: descr_answer,
				special_instructions: spec_answer
			});
		}
	}

	componentWillUnmount() {
		// this.props.dispatch(resourceActions.setPaymentAction(undefined));
		this.addTenantValidation.destroy();
	}
	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (this.props.slots !== nextProps.slots) {
			this.setState({
				slots: nextProps.slots
			});

			if (this.state.slotDataRefreshed === true && this.state.triggerAction === TriggerTypes.BlockBooking) {
				this.handleBlockedResource(this.state.slotDataRefreshed, nextProps.slots);
			}
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.state.edit_status !== nextState.edit_status) {
			if (!_.isUndefined(nextState.edit_status) && nextState.edit_status === EditFieldStatus.Discarded) {
				this.reloadBookingQuestionsFields();
			}
		}

		if (this.state.switchActive !== nextState.switchActive && nextState.switchActive === true) {
			//this.formReset();
			this.setState({
				error: undefined,
				errorMessage: ''
			});
		}

		if (this.props.bookingSlot !== nextProps.bookingSlot) {
			// console.log(this.props.bookingSlot);
			// console.log(nextProps.bookingSlot);
			// console.log("booking slot updated");
		}

		if (this.state.currentSetBasketSlot !== nextState.currentSetBasketSlot) {
			// console.log(this.state.currentSetBasketSlot);
			// console.log(nextState.currentSetBasketSlot);
			// console.log("basket slot updated");
		}

		if (this.state.timeFrom !== nextState.timeFrom) {
			this.handleTimeSelectionChanged(nextState.timeFrom, this.state.timeTo);
		}
		if (this.state.timeTo !== nextState.timeTo) {
			this.handleTimeSelectionChanged(this.state.timeFrom, nextState.timeTo);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.switchActive !== this.state.switchActive &&
			prevState.switchActive === true &&
			!_.isUndefined(this.state.error)
		) {
			this.setState({
				error: undefined,
				errorMessage: ''
			});
		}
	}
	addValidation() {
		this.addTenantValidation = $(this.refs.validate).parsley({
			classHandler: function(el) {
				return el.$element.closest('div:not(.input-wrapper):not(.Select)');
			}
		});
	}

	// componentWillUnmount() {
	//     this.addTenantValidation.destroy();
	//     // this.formReset();

	// }

	validate(fromSubmit = false) {
		const { selectedEventId } = this.state;
		this.addTenantValidation.validate({ force: true });

		const valid = this.addTenantValidation.isValid();

		this.setState({
			modalFormValid: valid,
			addTenantFormUsed: true
		});

		if (valid === true && fromSubmit === true) {
			this.checkAvailability();

			//this.formReset();
			this.setState({
				addTenantFormUsed: false
			});
		}
	}

	formReset() {
		this.setState({
			companyName: '',
			tenant: '',
			resources: '',
			error: undefined,
			message: ''
		});

		this.refs['tenant-auto-suggest'].getWrappedInstance().reset();
		//this.refs["formSelect-resources"].setVal("");
		//this.refs["formSelect-resources"].getWrappedInstance().reset();

		$(this.refs.validate).get(0).reset();

		this.addTenantValidation.destroy();

		// Reset all the select input components to the placeholder value
		for (let key in this.refs) {
			if (key.indexOf('formSelect') === 0) {
				this.refs[key].reset();
			}
		}

		setTimeout(() => {
			this.addValidation();
		}, 0);
	}

	loadEventSlots(events, startDate, endDate) {
		const { view } = this.props;

		const { serviceId } = this.props;

		if (view === calendarViews.Week) {
			this.props.dispatch(
				resourceActions.getSelectedEventsSlots(
					events,
					new Date(moment(startDate).startOf('week')._d),
					new Date(moment(endDate).endOf('week')._d),
					serviceId,
					(success) => {
						if (success === true) {
							this.setState({
								request_fail: undefined
							});
						} else {
							this.setState({
								request_fail: true
							});
						}
					}
				)
			);
		} else if (view === calendarViews.Day) {
			this.props.dispatch(
				resourceActions.getSelectedEventsSlots(
					events,
					new Date(startDate._d),
					new Date(startDate._d),
					serviceId,
					(success) => {
						if (success === true) {
							this.setState({
								request_fail: undefined
							});
						} else {
							this.setState({
								request_fail: true
							});
						}
					}
				)
			);
		} else if (view === calendarViews.Month) {
			var date_ = startDate.toDate(),
				y = date_.getFullYear(),
				m = date_.getMonth();

			var startDay = new Date(y, m, 1);
			var endDay = new Date(y, m + 1, 0);

			startDay = moment(startDay).subtract(7, 'd');
			endDay = moment(endDay).add(this.props.max_day_bookings, 'd');

			this.props.dispatch(
				resourceMonthlyActions.getSelectedEventsSlots(
					events,
					new Date(startDay._d),
					new Date(endDay._d),
					serviceId,
					(success) => {
						if (success === true) {
							this.setState({
								request_fail: undefined
							});
						} else {
							this.setState({
								request_fail: true
							});
						}
					}
				)
			);
		}
	}

	handleClick() {
		this.setState({
			// switchActive: !this.state.switchActive === true ? false : true,
			switchActive: !this.state.switchActive
		});
	}

	//Delete Modal section

	//Edit Modal section
	handleEditBooking() {
		const { selectedSlot, bookingQuestions } = this.props;

		let slot_questions = _.map(selectedSlot.questions, (que) => {
			return que;
		});
		let slot_answers = selectedSlot.answers;

		if (_.isUndefined(selectedSlot.questions) || slot_questions.length === 0) {
			if (bookingQuestions.length > 0) {
				slot_questions = bookingQuestions;
				slot_answers = _.map(slot_questions, (q) => {
					return { question_id: q.id, answer: '' };
				});
			}
		}

		let questions = _.map(
			_.map(bookingQuestions, (o) => {
				let other_field = 'question_' + o.id;
				let field_name = _.split(o.name, ' ');
				if (_.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.SpecialInstructions) {
					return {
						id: o.id,
						answer: this.state.special_instructions,
						name: o.name
					};
				} else if (_.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.EventDescription) {
					return { id: o.id, answer: this.state.event_description, name: o.name };
				} else
					return {
						id: o.id,
						answer: this.state[other_field],
						name: o.name
					};
			}),
			(que) => {
				let field = 'question_' + que.id;
				let check_que = _.find(slot_questions, { name: que.name });
				let answer = _.find(slot_answers, { question_id: que.id });
				let field_name = _.split(que.name, ' ');
				let isSpecialInstructionField =
					_.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.SpecialInstructions;
				let isEventDescriptionField =
					_.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.EventDescription;

				if (
					!_.isUndefined(check_que) &&
					_.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.SpecialInstructions &&
					!_.isUndefined(this.state.special_instructions)
				) {
					return { id: answer.question_id, answer: this.state.special_instructions };
				} else if (
					!_.isUndefined(check_que) &&
					_.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.EventDescription &&
					!_.isUndefined(this.state.event_description)
				) {
					return { id: answer.question_id, answer: this.state.event_description };
				} else if (!_.isUndefined(check_que) && !_.isUndefined(this.state[field])) {
					return { id: que.id, answer: que.answer };
				} else if (_.isUndefined(check_que) && !_.isUndefined(que)) {
					let other_field = 'question_' + que.id;

					if (!_.isUndefined(this.state[other_field])) {
						return { id: que.id, answer: this.state[other_field] };
					} else if (isSpecialInstructionField === true && this.state.special_instructions !== '') {
						return { id: que.id, answer: this.state.special_instructions };
					} else if (isEventDescriptionField === true && this.state.event_description !== '') {
						return { id: que.id, answer: this.state.event_description };
					} else return undefined;
				} else return undefined;
			}
		);

		let data = {
			id: selectedSlot.id,
			questions: _.filter(questions, (q) => !_.isUndefined(q))
		};

		this.props.dispatch(
			resourceActions.updateBookingResource(data, (success) => {
				if (success === true) {
					this.setState({
						processing: undefined,
						slotDataRefreshed: true,
						triggerAction: TriggerTypes.UpdateBooking,
						success: success,
						message: 'Booking updated successfully',
						field_active: false,
						edit_status: undefined
					});

					this.props.modalActionCompleted('update', success);

					//this.reloadBookingQuestionsFields();
				} else {
					this.setState({
						field_active: false,
						edit_status: 'discarded',
						processing: undefined,
						success: false,
						message: 'Unable to complete request.'
					});
				}
			})
		);

		this.setState({
			edit_button_text: EditButtonText.EditReservation,
			cancel_button_text: EditButtonText.CancelReservation
		});
	}

	//Create modal section

	handleTimeToChange(val) {
		this.setState({
			timeTo: val[0]
		});
	}
	handleTimeFromChange(val) {
		this.setState({
			timeFrom: val[0]
		});
	}

	toggleChange() {
		this.setState({
			specialInstruction_chk: !this.state.specialInstruction_chk
		});
	}
	handleChange(e) {
		let field = e.target.name;
		let value = e.target.value;
		this.setState({
			[field]: value
		});
	}

	handleCreateClick() {}
	handleTimeSelectionChanged(timeFrom, timeTo) {
		const { view, slots, startDate, timeStep, basketQueue, selectedSlot } = this.props;
		const { selectedOption } = this.state;
		if (view === calendarViews.Day || view === calendarViews.Week || view === calendarViews.WorkWeek) {
			if (selectedOption) {
				let event = selectedOption.target.value;
				let selectedSlotTimes = [];
				let slotAvailable = true;

				let time_from = new Date(timeFrom);
				let time_to = new Date(timeTo);

				let timeFrom_min = time_from.getHours() * 60 + time_from.getMinutes();
				let timeTo_min = time_to.getHours() * 60 + time_to.getMinutes();
				let times = _.range(timeFrom_min, timeTo_min, timeStep);

				selectedSlotTimes = _.filter(
					_.filter(slots, (s) => {
						return (
							s.eventId === event &&
							moment(s.start).format('YYYY/MM/DD') === moment(startDate).format('YYYY/MM/DD')
						);
					}),
					(t) => {
						return !_.isUndefined(_.find(times, (o) => o === t.start_min));
					}
				);

				let price = 0;
				let time_data = _.map(selectedSlotTimes, (s) => {
					price += s.price;
				});
				if (times.length !== selectedSlotTimes.length) {
					slotAvailable = false;
					this.setState({
						error: true,
						errorMessage:
							'This resource is not available for the desired dates. Please select alternative dates or resource.'
					});
				} else {
					this.setState({
						error: undefined,
						errorMessage: ''
					});

					let totalPrice = 0;
					if (selectedSlotTimes && selectedSlotTimes.length > 0 && slotAvailable === true) {
						_.map(selectedSlotTimes, (s) => {
							totalPrice += s.price;

							return s;
						});

						if (totalPrice === 0) {
							this.setState({
								payment_option_selected: true,
								payment_type: 1
							});
						}
					}
				}

				let slotData = {
					isAvailable: slotAvailable,
					start: time_from,
					end: time_to,
					price: price,
					start_min: timeFrom_min,
					end_min: timeTo_min,
					duration: timeTo_min - timeFrom_min
				};
				if (selectedSlot) {
					let slotUpadted = _.map(basketQueue, (b) => {
						if (b.id === selectedSlot.id) {
							return { ...b, ...slotData };
						}

						return b;
					});

					let newBasketData = _.find(slotUpadted, { id: selectedSlot.id });

					this.props.dispatch(
						resourceActions.updateBasketQueue(newBasketData, (success) => {
							if (success === true) {
								this.setState({
									currentSetBasketSlot: newBasketData
								});
							}
						})
					);
				}
				this.setState({
					selectedSlotTimes: selectedSlotTimes,
					selectedResoucreisAvailable: slotAvailable
				});
			}
		}
	}
	handleSelectChange(selectedOption) {
		this.setState({ selectedOption });

		const { slots, startDate, timeStep, view, selectedSlot } = this.props;
		const { timeFrom, timeTo } = this.state;

		let event = selectedOption.target.value;
		let selectedSlotTimes = [];
		let slotAvailable = false;
		let availableCounter = 0;
		let totalPrice = 0;

		if (view === calendarViews.Month) {
			var eventSlots = _.filter(slots, { eventId: event });

			var selectedSlotDays = _.filter(eventSlots, (item) => {
				return (
					moment(item.start) >= moment(selectedSlot.start) && moment(item.start) < moment(selectedSlot.end)
				);
			});

			var daySlots = _.map(selectedSlotDays, (daySlot) => {
				if (!daySlot.isAvailable) {
					slotAvailable = false;
				} else {
					availableCounter += 1;
				}

				//remove this when rectified in booking bug, amount presented in pound not in pence
				let price = 0;
				if (!_.isUndefined(daySlot.price) && daySlot.isAvailable === true) price = daySlot.price * 100;

				return { ...daySlot, price: price };
			});
			//recheck slot availability
			if (selectedSlot.duration === daySlots.length && selectedSlot.duration === availableCounter) {
				slotAvailable = true;
			}

			if (
				(!slotAvailable && selectedSlot.duration !== daySlots.length) ||
				(!slotAvailable && selectedSlot.duration === daySlots.length)
			) {
				this.setState({
					error: true,
					errorMessage:
						'This resource is not available for the desired dates. Please select alternative dates or resource.'
				});
			} else {
				this.setState({
					error: undefined,
					errorMessage: ''
				});
			}
			//TODO refactor this based on bookingbug above price rectified
			selectedSlotTimes = daySlots;

			var timeData = _.map(selectedSlotTimes, (s) => {
				totalPrice += s.price;

				return s;
			});

			if (totalPrice === 0) {
				this.setState({
					payment_option_selected: true,
					payment_type: 1
				});
			}
		} else if (view === calendarViews.Day || view === calendarViews.Week || view === calendarViews.WorkWeek) {
			let time_from = new Date(timeFrom);
			let time_to = new Date(timeTo);

			let timeFrom_min = time_from.getHours() * 60 + time_from.getMinutes();
			let timeTo_min = time_to.getHours() * 60 + time_to.getMinutes();
			let times = _.range(timeFrom_min, timeTo_min, timeStep);

			selectedSlotTimes = _.filter(
				_.filter(slots, (s) => {
					return (
						s.eventId === event &&
						moment(s.start).format('YYYY/MM/DD') === moment(startDate).format('YYYY/MM/DD')
					);
				}),
				(t) => {
					return !_.isUndefined(_.find(times, (o) => o === t.start_min));
				}
			);

			if (times.length !== selectedSlotTimes.length) {
				slotAvailable = false;
				this.setState({
					error: true,
					errorMessage:
						'This resource is not available for the desired dates. Please select alternative dates or resource.'
				});
			} else {
				//make sure all slot selected are available to book
				_.map(selectedSlotTimes, (o) => {
					if (!o.isAvailable) {
						slotAvailable = false;
					} else {
						availableCounter += 1;
					}
				});

				//recheck slot availability
				if (times.length === selectedSlotTimes.length && selectedSlotTimes.length === availableCounter) {
					slotAvailable = true;
				}

				this.setState({
					error: undefined,
					errorMessage: ''
				});

				if (selectedSlotTimes && selectedSlotTimes.length > 0 && slotAvailable === true) {
					_.map(selectedSlotTimes, (s) => {
						totalPrice += s.price;

						return s;
					});

					if (totalPrice === 0) {
						this.setState({
							payment_option_selected: true,
							payment_type: 1
						});
					}
				}
			}
		}

		if (!slotAvailable) {
			this.setState({
				error: true,
				errorMessage:
					'This resource is not available for the desired dates. Please select alternative dates or resource.'
			});
		}
		this.setState({
			selectedEventId: event,
			selectedSlotTimes: selectedSlotTimes,
			selectedResoucreisAvailable: slotAvailable
		});

		this.props.dispatch(resourceActions.setEventSelected(event));
	}

	handleSelectedTenant(ten) {
		this.setState({
			memberId: ten.memberId,
			companyName: ten.companyName,
			tenant: ten.tenant,
			selectedTenant: ten
		});

		this.props.dispatch(tenantActions.getSelectedTenant(ten.memberId));

		setTimeout(() => {
			this.state.addTenantFormUsed && this.validate();
		}, 0);
	}
	convertToDecimal(value) {
		if (value >= 0) return parseFloat(value / 100).toFixed(2);
		else return 0;
	}
	timeStepHelper(value) {
		// let hr = Number(value) / 60;
	}

	//helper to check for valid time set in minutes
	checktimeMinValid(step, min) {
		return Number(min) % step === 0;
	}

	//load booking questions text fields
	loadQuestionsTextField(field) {
		const { questions } = this.state;

		let special_instr_field = _.find(questions, (q) => {
			let field_name = _.split(q.name, ' ');
			return _.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.SpecialInstructions;
		});

		let event_descr_field = _.find(questions, (q) => {
			let field_name = _.split(q.name, ' ');
			return _.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.EventDescription;
		});

		switch (field) {
			case BookingQuestionFieldType.SpecialInstructions:
				return (
					<div className="content-item">
						<label className="like-h4">Special Instructions</label>
						<div className="">
							<textarea
								className="textarea"
								onChange={this.handleChange}
								name="special_instructions"
								defaultValue={this.state.special_instructions ? this.state.special_instructions : ''}
							/>
						</div>
					</div>
				);
			case BookingQuestionFieldType.EventDescription:
				return (
					<div className="content-item">
						<InputComponent
							type="text"
							name="event_description"
							label={'Event Description'}
							handleChange={this.handleChange}
							value={this.state.event_description ? this.state.event_description : ''}
						/>
					</div>
				);

			case BookingQuestionFieldType.Other:
				if (!_.isUndefined(special_instr_field) || !_.isUndefined(event_descr_field)) {
					return _.map(
						_.filter(questions, (q) => q.id !== special_instr_field.id && q.id !== event_descr_field.id),
						(o, index) => {
							return (
								<div key={index} className="content-item">
									<InputComponent
										type="text"
										name={'question_' + o.id}
										label={o.name}
										handleChange={this.handleChange}
										value={this.state['question_' + o.id] ? this.state['question_' + o.id] : ''}
									/>
								</div>
							);
						}
					);
				} else return '';

			default:
				return '';
		}
	}

	//load booking questions Edit text fields
	loadQuestionsEditTextField(field, status) {
		const { selectedSlot, bookingQuestions } = this.props;

		let questions = [];

		if (!_.isUndefined(selectedSlot.questions) && selectedSlot.questions.length > 0) {
			questions = _.map(selectedSlot.questions, (que, i) => {
				return { ...que, id: Number(i) };
			});
		} else {
			questions = _.map(bookingQuestions, (que) => {
				return { ...que };
			});
		}
		let special_instr_field = _.find(questions, (q) => {
			let field_name = _.split(q.name, ' ');
			return _.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.SpecialInstructions;
		});

		let event_descr_field = _.find(questions, (q) => {
			let field_name = _.split(q.name, ' ');
			return _.join(field_name, '_').toLowerCase() === BookingQuestionFieldType.EventDescription;
		});

		switch (field) {
			case BookingQuestionFieldType.SpecialInstructions:
				let special_instruct_answer = special_instr_field
					? _.find(selectedSlot.answers, { question_id: special_instr_field.id })
					: undefined;

				let answer = !_.isUndefined(special_instruct_answer) ? special_instruct_answer.answer : '';

				if (this.state.allow_edit) {
					return (
						<div className={'content-item'}>
							<label className="like-h4">Special Instructions</label>
							<div className="">
								<textarea
									className={
										!this.state.allow_edit || !this.state.field_active ? (
											'textarea textarea-editable'
										) : (
											'textarea textarea-editable edit'
										)
									}
									onChange={(e) => this.handleChange(e)}
									name="special_instructions"
									disabled={!this.state.allow_edit || !this.state.field_active}
									value={this.state.special_instructions}
								/>
							</div>
						</div>
					);
				} else {
					return (
						<div className="content-item">
							<label className="like-h4"> Special Instruction</label>
							<div className="display--textarea">
								<span>{answer}</span>
							</div>
						</div>
					);
				}
			case BookingQuestionFieldType.EventDescription:
				let event_descr_answer = event_descr_field
					? _.find(selectedSlot.answers, { question_id: event_descr_field.id })
					: undefined;

				let descr_answer = !_.isUndefined(event_descr_answer)
					? event_descr_answer.answer ? event_descr_answer.answer : ''
					: '';
				if (this.state.allow_edit) {
					return (
						<div className="content-item pad-top-x15">
							<InputComponent
								type="text"
								name="event_description"
								label={'Event Description'}
								uneditable={!this.state.allow_edit || !this.state.field_active}
								value={this.state.event_description}
								handleChange={this.handleChange}
							/>
						</div>
					);
				} else {
					return (
						<div className="content-item pad-top-x15">
							<label className="like-h4"> Event Description</label>
							<div className="display--text">
								<span>{descr_answer} </span>
							</div>
						</div>
					);
				}

			case BookingQuestionFieldType.Other:
				if (!_.isUndefined(special_instr_field) || !_.isUndefined(event_descr_field)) {
					return _.map(
						_.filter(questions, (q, i) => {
							return q.id !== special_instr_field.id && q.id !== event_descr_field.id;
						}),
						(o, index) => {
							let answer_val = _.find(selectedSlot.answers, { question_id: o.id });
							let input_field_name = 'question_' + o.id;
							return (
								<div key={index} className={index === 0 ? 'content-item mrg-top-x15' : 'content-item'}>
									<InputComponent
										type="text"
										name={input_field_name}
										label={o.name}
										handleChange={(e) => this.handleChange(e)}
										uneditable={!this.state.allow_edit || !this.state.field_active}
										value={
											!_.isUndefined(this.state[input_field_name]) ? (
												this.state[input_field_name]
											) : !_.isUndefined(answer_val) ? (
												answer_val.answer
											) : (
												''
											)
										}
									/>
								</div>
							);
						}
					);
				} else return <div className="content-item mrg-top-x15">&nbsp;</div>;

			default:
				return '';
		}
	}

	reloadBookingQuestionsFields() {
		const { selectedSlot, bookingQuestions, bookings } = this.props;
		//const { questions } = this.state;

		let selected_booking = _.find(bookings, { id: selectedSlot.id });

		var answers = selected_booking ? selected_booking.answers : []; // selectedSlot.answers;
		var special_instr_que_field = '';
		var event_descr_que_field = '';

		if (!_.isUndefined(bookingQuestions) && bookingQuestions.length > 0) {
			for (var quest = 0; quest < bookingQuestions.length; quest++) {
				let field = _.split(bookingQuestions[quest].name, ' ');
				let field_name = _.join(field, '_').toLowerCase();
				let que_field = 'question_' + bookingQuestions[quest].id;
				let que_answer = _.find(answers, { question_id: bookingQuestions[quest].id });
				if (field_name === BookingQuestionFieldType.SpecialInstructions) {
					special_instr_que_field = 'question_' + bookingQuestions[quest].id;

					this.setState({
						special_instructions:
							!_.isUndefined(que_answer) &&
							!_.isUndefined(que_answer.answer) &&
							que_answer.answer !== null
								? que_answer.answer
								: ''
					});
				} else if (field_name === BookingQuestionFieldType.EventDescription) {
					event_descr_que_field = 'question_' + bookingQuestions[quest].id;
					this.setState({
						event_description:
							!_.isUndefined(que_answer) &&
							!_.isUndefined(que_answer.answer) &&
							que_answer.answer !== null
								? que_answer.answer
								: ''
					});
				} else {
					this.setState({
						[que_field]:
							!_.isUndefined(que_answer) &&
							!_.isUndefined(que_answer.answer) &&
							que_answer.answer !== null
								? que_answer.answer
								: ''
					});
				}
			}
		}
	}
	//Block resource event
	handleBlockedResource(status, slots) {
		const { selectedEventId, timeFrom, timeTo, startDate, endDate } = this.state;
		const { timeStep, view } = this.props;

		if (status === true) {
			var isValid = false;

			var start_time;
			var end_time;

			if (view === calendarViews.Day || view === calendarViews.Week || view === calendarViews.WorkWeek) {
				start_time = moment.utc(timeFrom).format();
				end_time = moment.utc(timeTo).format();

				let now = new Date();
				let tmin = now.getHours() * 60 + now.getMinutes();
				let stime = new Date(timeFrom);
				let etime = new Date(timeTo);
				let smin = stime.getHours() * 60 + stime.getMinutes();
				let emin = etime.getHours() * 60 + etime.getMinutes();

				var resourceSlots = _.filter(slots, (data) => {
					return data.eventId === selectedEventId && (data.booked || data.blocked);
				});

				const checkSlots = _.filter(resourceSlots, (s) => {
					return (
						(moment(s.start).format('YYYY-MM-DD') === moment(start_time).format('YYYY-MM-DD') &&
							s.eventId === selectedEventId &&
							(s.booked || s.blocked) &&
							s.start_min === smin &&
							s.end_min >= emin) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(start_time).format('YYYY-MM-DD') &&
							s.eventId === selectedEventId &&
							(s.booked || s.blocked) &&
							s.start_min > smin &&
							s.end_min >= emin) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(start_time).format('YYYY-MM-DD') &&
							s.eventId === selectedEventId &&
							(s.booked || s.blocked) &&
							s.start_min < smin &&
							s.end_min > smin) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(start_time).format('YYYY-MM-DD') &&
							s.eventId === selectedEventId &&
							(s.booked || s.blocked) &&
							s.start_min === smin &&
							s.end_min <= emin) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(start_time).format('YYYY-MM-DD') &&
							s.eventId === selectedEventId &&
							(s.booked || s.blocked) &&
							s.start_min > smin &&
							s.end_min > emin) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(start_time).format('YYYY-MM-DD') &&
							s.eventId === selectedEventId &&
							(s.booked || s.blocked) &&
							s.start_min > smin &&
							s.end_min < emin)
					);
				});

				console.log('check slots: ', checkSlots);
				this.setState({
					processing: undefined
				});

				if (selectedEventId === '' || _.isUndefined(selectedEventId)) {
					isValid = false;
					this.setState({
						slotDataRefreshed: false,
						error: true,
						errorMessage: 'invalid resource'
					});
				} else if (
					!this.checktimeMinValid(timeStep, smin) ||
					!this.checktimeMinValid(timeStep, emin) ||
					emin <= smin
				) {
					isValid = false;
					this.setState({
						slotDataRefreshed: false,
						error: true,
						errorMessage: 'invalid Date/Time specified'
					});
				} else if (moment(now).format('YYYY-MM-DD') === moment(timeFrom).format('YYYY-MM-DD') && smin < tmin) {
					isValid = false;
					this.setState({
						slotDataRefreshed: false,
						error: true,
						errorMessage: 'invalid Date/Time specified'
					});
				}

				if (checkSlots.length > 0) {
					isValid = false;
					this.setState({
						slotDataRefreshed: '',
						error: true,
						errorMessage: 'unable to block resource! active event.'
					});
				} else {
					isValid = true;
				}
			} else if (view === calendarViews.Month) {
				var checkSlots = _.filter(slots, (data) => {
					return data.eventId === selectedEventId && (data.booked || data.blocked);
				});

				var slotsEmpty = _.filter(checkSlots, (s) => {
					return (
						(moment(s.start).toDate() > moment(timeFrom).toDate() &&
							moment(s.end).toDate() > moment(timeTo).toDate() &&
							moment(s.start).toDate() < moment(timeTo).toDate()) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(timeFrom).format('YYYY-MM-DD') &&
							moment(s.end).format('YYYY-MM-DD') === moment(timeTo).format('YYYY-MM-DD')) ||
						(moment(s.start).format('YYYY-MM-DD') === moment(timeFrom).format('YYYY-MM-DD') &&
							moment(s.end).toDate() > moment(timeTo).toDate()) ||
						(moment(s.start).toDate() <= moment(timeFrom).toDate() &&
							moment(s.end).toDate() < moment(timeTo).toDate() &&
							moment(s.start).toDate() < moment(timeTo).toDate() &&
							moment(s.end).toDate() > moment(timeFrom).toDate()) ||
						(moment(s.start).toDate() < moment(timeFrom).toDate() &&
							moment(s.end).toDate() > moment(timeTo).toDate() &&
							moment(s.start).toDate() < moment(timeTo).toDate() &&
							moment(s.end).toDate() > moment(timeFrom).toDate()) ||
						(moment(s.start).toDate() > moment(timeFrom).toDate() &&
							moment(s.end).toDate() <= moment(timeTo).toDate() &&
							moment(s.start).toDate() < moment(timeTo).toDate() &&
							moment(s.end).toDate() > moment(timeFrom).toDate())
					);
				});

				if (selectedEventId === '' || _.isUndefined(selectedEventId)) {
					isValid = false;
					this.setState({
						error: true,
						errorMessage: 'invalid resource'
					});
				} else if (moment().format('YYYY-MM-DD') === moment(timeFrom).format('YYYY-MM-DD')) {
					isValid = false;
					this.setState({
						error: true,
						errorMessage: 'Date not valid'
					});
				}

				if (slotsEmpty.length > 0) {
					isValid = false;
					this.setState({
						error: true,
						errorMessage: 'invalid  Date'
					});
				} else {
					isValid = true;
				}

				if (isValid === true) {
					start_time = moment(timeFrom).format('YYYY-MM-DD');
					end_time = moment(timeTo).format('YYYY-MM-DD');
				}
			}

			if (isValid === true) {
				this.setState({
					processing: true,
					error: undefined,
					errorMessage: ''
				});

				this.props.dispatch(
					resourceActions.blockResource(selectedEventId, start_time, end_time, (success) => {
						if (success === true) {
							this.setState({
								processing: undefined
							});
							this.props.modalActionCompleted('block', success);
						} else {
							this.setState({
								processing: undefined,
								error: true,
								errorMessage: 'unable to block resource!'
							});
						}
					})
				);
			}
		}
	}

	//Cancel Booking
	cancellationValid() {
		const { selectedSlot, cancellationPeriod } = this.props;
		let now = new Date();

		if (moment(now).isBefore(moment(selectedSlot.start))) {
			let current_diff = moment.duration(moment(selectedSlot.start).diff(moment(now)));
			let diff_min = current_diff.asMinutes().toFixed(0);

			//check if time in minutes is within cancellation span in minutes
			if (diff_min > cancellationPeriod) {
				//within calcellation period

				return true;
			} else {
				//exceeded cancellation period
				return false;
			}
		} else {
			//cancellation exceeded
			return false;
		}
	}
	handleCancelBooking() {
		const { selectedSlot } = this.props;
		// const { booking_action_valid } = this.state;
		if (!_.isUndefined(selectedSlot)) {
			this.props.dispatch(
				resourceActions.cancelBookingSingle(selectedSlot.id, 'None', (success) => {
					if (success === true) {
						this.props.modalActionCompleted('delete', success);
						this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.cancel_success));
						this.props.dispatch(checkoutActions.SetActionStatus(true));
						this.setState({
							processing: undefined,
							modalScreen: ModalScreenType.BookingSuccess
						});
					} else {
						this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.cancel_fail));
						this.props.dispatch(checkoutActions.SetActionStatus(false));
						this.setState({
							processing: undefined,
							modalScreen: ModalScreenType.BookingError
						});
					}
				})
			);
		}
	}

	handleCreateBooking() {
		const { currentSetBasketSlot, selectedTenant } = this.state;
		const { bookingQuestions, serviceId } = this.props;

		this.props.dispatch(
			checkoutActions.addItemToBasket(selectedTenant, serviceId, (success) => {
				if (success === true) {
					this.props.dispatch(
						checkoutActions.completeCheckout(selectedTenant.bookingBugMemberId, (checkoutSuccess) => {
							if (checkoutSuccess === true) {
								this.props.modalActionCompleted('create', checkoutSuccess);
								this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.success));
								this.props.dispatch(checkoutActions.SetActionStatus(true));
								this.setState({
									processing: undefined,
									// slotDataRefreshed: true,
									success: success,
									message: 'Booking created successfully',
									modalScreen: ModalScreenType.BookingSuccess
								});
							} else {
								this.props.dispatch(checkoutActions.SetActionMessage(ActionMessages.fail));
								this.props.dispatch(checkoutActions.SetActionStatus(false));
								this.setState({
									processing: undefined,
									// slotDataRefreshed: true,
									success: false,
									message: 'Unable to complete your request at the moment, please try again shortly',
									modalScreen: ModalScreenType.BookingError
								});
							}
						})
					);
				} else {
				}
			})
		);

		// let questions = [];

		// _.map(bookingQuestions, que => {
		//     let field = _.split(que.name, " ");
		//     let field_name = _.join(field, "_").toLowerCase();

		//     if (field_name === BookingQuestionFieldType.SpecialInstructions && this.state.special_instructions !== "") {
		//         questions.push({ id: que.id, answer: this.state.special_instructions });
		//     } else if (field_name === BookingQuestionFieldType.EventDescription && this.state.event_description !== "") {
		//         questions.push({ id: que.id, answer: this.state.event_description })
		//     } else if (field_name !== BookingQuestionFieldType.SpecialInstructions || field_name !== BookingQuestionFieldType.EventDescription) {
		//         let que_field = "question_" + que.id;

		//         if (!_.isUndefined(this.state[que_field])) {
		//             questions.push({ id: que.id, answer: this.state[que_field] });
		//         }
		//     }
		// });

		// let slot_data = { ...currentSetBasketSlot, questions };

		// this.props.dispatch(resourceActions.setSelectedSlot(slot_data, serviceId, success => {
		//     if (success === true) {

		//         this.props.dispatch(checkoutActions.addItemToBasket(selectedTenant, serviceId, addSuccess => {
		//             if (addSuccess === true) {

		//                 this.props.dispatch(
		//                     checkoutActions.completeCheckout(selectedTenant.bookingBugMemberId, checkoutSuccess => {
		//                         if (checkoutSuccess === true) {

		//                             this.props.modalActionCompleted("create", checkoutSuccess);
		//                             this.setState({
		//                                 processing: undefined,
		//                                 // slotDataRefreshed: true,
		//                                 success: success,
		//                                 message: "Booking created successfully",
		//                                 modalScreen: ModalScreenType.BookingSuccess
		//                             });

		//                         } else {
		//                             this.setState({
		//                                 processing: undefined,
		//                                 // slotDataRefreshed: true,
		//                                 success: false,
		//                                 message: "Unable to complete your request at the moment, please try again shortly",
		//                                 modalScreen: ModalScreenType.BookingError
		//                             });
		//                         }
		//                     }));
		//             } else {

		//             }
		//         }))
		//     } else {

		//     }
		// }));
	}

	updateBasketBookingQuestions() {
		const { currentSetBasketSlot, selectedSlotTimes } = this.state;
		const { bookingQuestions, serviceId } = this.props;
		let totalPrice = this.calculateTotalPrice();

		let questions = [];

		var data = _.map(bookingQuestions, (que) => {
			let field = _.split(que.name, ' ');
			let field_name = _.join(field, '_').toLowerCase();

			if (field_name === BookingQuestionFieldType.SpecialInstructions && this.state.special_instructions !== '') {
				questions.push({ id: que.id, answer: this.state.special_instructions });
			} else if (
				field_name === BookingQuestionFieldType.EventDescription &&
				this.state.event_description !== ''
			) {
				questions.push({ id: que.id, answer: this.state.event_description });
			} else if (
				field_name !== BookingQuestionFieldType.SpecialInstructions ||
				field_name !== BookingQuestionFieldType.EventDescription
			) {
				let que_field = 'question_' + que.id;

				if (!_.isUndefined(this.state[que_field])) {
					questions.push({ id: que.id, answer: this.state[que_field] });
				}
			}
		});

		let slot_data = { ...currentSetBasketSlot, questions };

		this.props.dispatch(
			resourceActions.setSelectedSlot(slot_data, serviceId, (success) => {
				if (success === true) {
					this.setState({
						bookingDataUpdated: true
					});

					if (
						(this.state.payment_type && this.state.payment_type === PaymentOptions.Account) ||
						totalPrice === 0
					) {
						this.handleCreateBooking();
					} else if (this.state.payment_type && this.state.payment_type === PaymentOptions.Card) {
						this.handleCreateBookingWithPayment();
					}
				} else {
				}
			})
		);
	}

	calculateTotalPrice() {
		const { selectedSlotTimes, selectedResoucreisAvailable } = this.state;

		let totalPrice = 0;
		if (selectedSlotTimes && selectedSlotTimes.length > 0 && selectedResoucreisAvailable === true) {
			_.map(selectedSlotTimes, (s) => {
				totalPrice += s.price;
				return s;
			});
		}
		return totalPrice;
	}

	handleCreateBookingWithPayment(e) {
		const { selectedTenant, currentSetBasketSlot } = this.state;
		const { serviceId } = this.props;

		// let questions = [];

		// var data = _.map(bookingQuestions, que => {
		//     let field = _.split(que.name, " ");
		//     let field_name = _.join(field, "_").toLowerCase();

		//     if (field_name === BookingQuestionFieldType.SpecialInstructions && this.state.special_instructions !== "") {
		//         questions.push({ id: que.id, answer: this.state.special_instructions });
		//     } else if (field_name === BookingQuestionFieldType.EventDescription && this.state.event_description !== "") {
		//         questions.push({ id: que.id, answer: this.state.event_description })
		//     } else if (field_name !== BookingQuestionFieldType.SpecialInstructions || field_name !== BookingQuestionFieldType.EventDescription) {
		//         let que_field = "question_" + que.id;

		//         if (!_.isUndefined(this.state[que_field])) {
		//             questions.push({ id: que.id, answer: this.state[que_field] });
		//         }
		//     }
		// });

		// let slot_data = { ...currentSetBasketSlot, questions };

		//this.props.modalActionCompleted("payment", true);

		this.props.dispatch(
			checkoutActions.addItemToBasket(selectedTenant, serviceId, (success) => {
				if (success === true) {
					this.setState({
						processing: undefined
					});
					this.props.modalActionCompleted('payment', success);
				} else {
					this.setState({
						processing: undefined,
						success: false,
						error: true,
						message: 'Unable to complete your request at the moment, please try again shortly',
						modalScreen: ModalScreenType.BookingError
					});
				}
			})
		);
	}

	//check availability on create
	checkAvailability() {
		const { startDate, slots, timeStep, selectedSlot, bookingQuestions, view } = this.props;
		const { timeFrom, timeTo, selectedEventId } = this.state;

		if (selectedEventId === '' || _.isUndefined(selectedEventId)) {
			this.setState({
				error: true,
				errorMessage: 'invalid resource'
			});
		} else {
			var eventSlots = _.filter(slots, { eventId: selectedEventId });

			if (view === calendarViews.Month) {
				var slotAvailable = true;

				var selectedSlotDays = _.filter(eventSlots, (item) => {
					return (
						moment(item.start) >= moment(selectedSlot.start) &&
						moment(item.start) < moment(selectedSlot.end)
					);
				});

				let totalprice = 0;
				var daySlots = _.map(selectedSlotDays, (daySlot) => {
					if (!daySlot.isAvailable) slotAvailable = false;

					//remove this when rectified in booking bug, amount presented in pound not in pence
					//let price = 0;
					if (!_.isUndefined(daySlot.price)) {
						//  price = daySlot.price * 100;
						totalprice += daySlot.price;
					}
					//return { ...daySlot, price: price };

					return { ...daySlot };
				});

				if (slotAvailable && selectedSlot.duration === daySlots.length) {
					let slot = {
						isAvailable: true,
						inBasket: false,
						id: selectedSlot.id,
						start: moment(selectedSlot.start).format('YYYY-MM-DD'),
						end: moment(selectedSlot.end).format('YYYY-MM-DD'),
						price: totalprice,
						type: selectedSlot.action,
						duration: selectedSlot.duration,
						units: 'day',
						active: true
					};

					this.setState({
						currentSetBasketSlot: slot,
						booking_action_valid: true,
						error: undefined,
						errorMessage: '',
						modalScreen:
							bookingQuestions && bookingQuestions.length > 2
								? ModalScreenType.BookingQuestionScreen
								: ModalScreenType.CheckOut
					});
				} else {
					this.setState({
						error: true,
						errorMessage:
							'This resource is not available for the desired dates. Please select alternative dates or resource.'
					});
				}
			} else {
				let time_from = new Date(timeFrom);
				let time_to = new Date(timeTo);

				let timeFrom_min = time_from.getHours() * 60 + time_from.getMinutes();
				let timeTo_min = time_to.getHours() * 60 + time_to.getMinutes();
				let start_date = moment(startDate);
				let price = 0;
				let times = _.range(timeFrom_min, timeTo_min, timeStep);

				var slots_data = _.filter(eventSlots, (data) => {
					let s_date = moment(new Date(data.start));

					// return start_date.format("YYYY-DD-MM") === s_date.format("YYYY-DD-MM") && data.eventId === selectedEventId;
					return start_date.format('YYYY-DD-MM') === s_date.format('YYYY-DD-MM');
				});

				//check there isnt booked item within slot selected
				let isValid = 'true';
				this.setState({
					error: undefined,
					errorMessage: ''
				});

				var check_availaible = _.map(times, (data) => {
					var avail = _.find(slots_data, (val) => {
						return val.start_min === data && val.isAvailable === true;
					});

					return avail;
				});

				//check time selected is available /
				//toDo: rework check_available data

				let compare_selected_times = _.map(times, (time_val) => {
					if (check_availaible.length === 0) {
						return isValid;
					} else if (check_availaible.length > 0) {
						if (_.isUndefined(_.find(check_availaible, { start_min: time_val }))) {
							isValid = false;

							return false;
						}
					}

					return true;
				});

				//check each selected slot time is valid
				let all_valid = true;
				_.map(compare_selected_times, (value) => {
					if (!value) {
						all_valid = false;
					}
				});

				if (
					compare_selected_times.length === times.length &&
					check_availaible.length > 0 &&
					isValid &&
					all_valid
				) {
					let slot = {
						isAvailable: true,
						inBasket: false,
						id: check_availaible[0].id,
						start: timeFrom,
						end: timeTo,
						price: price,
						start_min: timeFrom_min,
						end_min: timeTo_min,
						type: selectedSlot.action,
						active: true,
						duration: selectedSlot.duration
					};

					this.setState({
						currentSetBasketSlot: slot,
						booking_action_valid: true,
						error: undefined,
						errorMessage: '',
						modalScreen:
							bookingQuestions && bookingQuestions.length > 2
								? ModalScreenType.BookingQuestionScreen
								: ModalScreenType.CheckOut
					});
				} else {
					this.setState({
						error: true,
						errorMessage:
							'This resource is not available for the desired time. Please select alternative times or resource.'
					});
				}
			}
		}
	}

	//Defined Modal View screens

	//Create view
	showCreateModalView() {
		const { handleCloseClick, resources, startDate, endDate, service, view } = this.props;
		const {
			timeFrom,
			timeTo,
			switchActive,
			selectedSlotTimes,
			questions,
			selectedResoucreisAvailable
		} = this.state;

		let resource_options = [];

		if (resources && resources.length > 0) {
			resource_options = _.map(resources, (o) => {
				return { label: o.name, value: o.eventId };
			});
		}

		let totalPrice = 0;

		if (selectedSlotTimes && selectedSlotTimes.length > 0 && selectedResoucreisAvailable === true) {
			_.map(selectedSlotTimes, (s) => {
				totalPrice += s.price;

				return s;
			});
		}

		return (
			<div className="modal-container">
				<div className="modal-top">
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className="modal-body create">
					<div className="content-item mrg-top-x15">
						{resource_options && (
							<InputComponent
								type="select"
								name="resources"
								ref="formSelect-Resources"
								label="Resource Name"
								options={resource_options}
								placeHolder="Select Resource"
								value={this.state.selectedEventId.toString()}
								handleChange={(val) => {
									this.handleSelectChange(val);
									setTimeout(() => {
										this.state.addTenantFormUsed && this.validate();
									}, 0);
								}}
								parsley={{
									'data-parsley-required': true,
									'data-parsley-error-message': 'Please select a resource'
								}}
							/>
						)}
					</div>
					{view === calendarViews.Month ? (
						<div className="content-item">
							<div className="content-item--double">
								<div className="date">
									<label className="like-h4"> Starts</label>
									<Datepicker
										selected={moment(startDate)}
										minDate={moment(startDate)}
										dateFormat="DD/MM/YYYY"
										locale="en-gb"
										disabled={true}
										onChange={() => {}}
										placeHolder="Date"
										value={moment(startDate).format('DD/MM/YYYY')}
									/>
								</div>

								<div className="date">
									<label className="like-h4"> Ends</label>
									<Datepicker
										selected={moment(endDate)}
										minDate={moment(endDate)}
										dateFormat="DD/MM/YYYY"
										locale="en-gb"
										disabled={true}
										onChange={() => {}}
										placeHolder="Date"
										value={moment(endDate).format('DD/MM/YYYY')}
									/>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div className="content-item">
								<label className="like-h4"> Starts</label>
								<div className="content-item--double">
									<div className="date">
										<Datepicker
											selected={moment(startDate)}
											minDate={moment(startDate)}
											dateFormat="DD/MM/YYYY"
											locale="en-gb"
											disabled={true}
											onChange={() => {}}
											placeHolder="Date"
											value={moment(startDate).format('DD/MM/YYYY')}
										/>
									</div>
									<div className="timing active time">
										<div className="time_from">
											<Flatpickr
												id="timeFrom"
												name="timeFrom"
												options={{
													defaultDate: new Date(moment(startDate).toDate()),
													defaultHour: Number(moment(startDate).hour()),
													defaultMinute: Number(moment(startDate).minutes()),
													enableTime: true,
													noCalendar: true,
													dateFormat: 'h:i K',
													placement: 'top',
													focusOnOpen: false,
													minuteIncrement: this.state.timeStep,
													static: true
												}}
												value={new Date(timeFrom)}
												disabled={false}
												onChange={this.handleTimeFromChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="content-item">
								<label className="like-h4"> Ends</label>
								<div className="content-item--double">
									<div className="date">
										<Datepicker
											selected={moment(endDate)}
											minDate={moment(endDate)}
											dateFormat="DD/MM/YYYY"
											locale="en-gb"
											disabled={true}
											onChange={() => {}}
											placeHolder="Date"
											value={moment(endDate).format('DD/MM/YYYY')}
										/>
									</div>
									<div className="timing active time">
										<div className="time_to">
											<Flatpickr
												id="timeTo"
												name="timeTo"
												options={{
													defaultDate: new Date(moment(endDate).toDate()),
													defaultHour: Number(moment(endDate).hour()),
													defaultMinute: Number(moment(endDate).minutes()),
													enableTime: true,
													noCalendar: true,
													dateFormat: 'h:i K',
													placement: 'top',
													focusOnOpen: false,
													minuteIncrement: this.state.timeStep,
													static: true,
													minDate: timeFrom
												}}
												value={timeTo}
												disabled={false}
												onChange={(date) => {
													this.handleTimeToChange(date);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="content-item">
						<div className="content-item--double pad-top-x15">
							<label className={switchActive ? 'switch-label active' : 'switch-label'}>Unavailable</label>

							<ToggleButton handleClick={this.handleClick} active={switchActive} />
						</div>
					</div>
					<div className={switchActive === false ? 'content-item resize-popup' : 'content-item hide'}>
						<label className="like-h4">Charges</label>

						<div className="content-item--double pad-top-x5 charges">
							<label>
								{service && !_.isUndefined(service.service_name) ? service.service_name : ''}{' '}
								{selectedSlotTimes && selectedSlotTimes.length > 0 ? (
									'x' + selectedSlotTimes.length
								) : (
									''
								)}
							</label>
							<span>
								£{selectedSlotTimes && selectedSlotTimes.length > 0 ? (
									this.convertToDecimal(selectedSlotTimes[0].price)
								) : (
									0
								)}{' '}
							</span>
						</div>
						<div id="total--price" className="aligner-item--bottom mrg-top-x15">
							<span>Total</span>
							<span>£{this.convertToDecimal(totalPrice)}</span>
						</div>

						<div className="pad-top-x25 resize-popup">
							<TenantAutoSuggest
								ref="tenant-auto-suggest"
								label="Resident Name"
								placeHolder="Resident Name"
								onSelected={(data) => this.handleSelectedTenant(data)}
								defaultTenantId={this.state.memberId !== '' ? this.state.memberId : undefined}
								clearableIfValid={true}
							/>
						</div>
					</div>
				</div>
				<div className={switchActive === false ? 'modal-footer' : 'modal-footer fix'}>
					<div className="content-item">
						{this.state.processing === true && (
							<div className="progress-spinner">
								{' '}
								<i
									className={'fa fa-spinner fa-spin resource-arrow '}
									style={{ fontSize: 24 + 'px' }}
								/>
							</div>
						)}
					</div>
					{!switchActive ? (
						<div className="content-item">
							<div
								className={'validation-messages' + (this.state.modalFormValid ? ' valid' : ' invalid')}
								id="validation-messages"
							>
								{this.state.modalFormValid && (
									<div>{/* <p className="message type-success">Sending data To Server...</p> */}</div>
								)}
							</div>
							<div className="content-item dot-nav">
								<DotNav
									items={!_.isUndefined(this.state.activeScreen) ? this.state.activeScreen.pages : []}
									currentItem={this.state.modalScreen}
								/>
							</div>

							<div className="content-item ">
								{this.state.error &&
								this.state.error === true && (
									<div>
										<p className="message type-error">
											{this.state.error && this.state.errorMessage}
										</p>
									</div>
								)}
							</div>
							<div className="content-item">
								<div
									className={
										'validation-messages' + (this.state.modalFormValid ? ' valid' : ' invalid')
									}
									id="validation-messages"
								>
									{this.state.modalFormValid && (
										<div>
											{/* <p className="message type-success">Sending data To Server...</p> */}
										</div>
									)}
								</div>
								<div className="content-item--double ation-btn pad-bottom-x20">
									<Button icon="arrow-left-c" isDisabled={true} handleClick={() => {}} />
									<Button
										icon="arrow-right-c"
										handleClick={() => {
											this.validate(true);
										}}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="content-item pad-bottom-x20">
							{this.state.error &&
							this.state.error === true && (
								<div>
									<p className="message type-error">{this.state.error && this.state.errorMessage}</p>
								</div>
							)}
							{this.state.status &&
							this.state.status === true && (
								<div>
									<p className="message type-success">completed</p>
								</div>
							)}
							<Button
								text={switchActive === false ? 'Next' : 'Save'}
								isDisabled={!_.isUndefined(this.state.processing)}
								handleClick={() => {
									if (this.state.selectedResoucreisAvailable === true) {
										let events = _.map(resources, (o) => {
											return { eventName: o.name, eventId: o.eventId };
										});

										this.loadEventSlots(events, startDate, endDate, this.props.serviceId);

										if (switchActive === true) {
											this.setState({
												processing: true,
												message: ''
											});
											setTimeout(() => {
												this.setState({
													slotDataRefreshed: true,
													triggerAction: TriggerTypes.BlockBooking
												});
											}, 0);
										} else if (switchActive === false) {
											this.setState({
												modalScreen:
													questions.length > 1
														? ModalScreenType.BookingQuestionScreen
														: ModalScreenType.CheckOut
											});
										}
									} else {
										this.setState({
											error: true,
											errorMessage: 'Unable to block, resource unavailable.'
										});
									}
								}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}

	//Create Modal Booking questions view
	showBookingQuestionsView() {
		const { handleCloseClick } = this.props;
		return (
			<div className="modal-container">
				<div className="modal-top">
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className="modal-body create">{this.loadQuestionsTextField(BookingQuestionFieldType.Other)}</div>
				<div className="modal-footer">
					<div className="content-item dot-nav">
						<DotNav
							items={!_.isUndefined(this.state.activeScreen) ? this.state.activeScreen.pages : []}
							currentItem={this.state.modalScreen}
						/>
					</div>
					<div className="content-item">
						<div className="content-item--double action-btn pad-bottom-x20">
							<Button
								icon="arrow-left-c"
								isDisabled={false}
								handleClick={() => {
									this.setState({
										modalScreen: ModalScreenType.CreateScreen
									});
								}}
							/>
							<Button
								icon="arrow-right-c"
								isDisabled={false}
								handleClick={() => {
									this.setState({
										modalScreen: ModalScreenType.CheckOut
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handlePaymentSelection(option) {
		const pay_option = option.target.value;
		var pay_label = option.target.label;
		this.setState({
			payment_option_selected: true,
			payment_type: pay_option
		});

		this.props.dispatch(checkoutActions.setChargeType(pay_label));
	}

	completBookingOption() {
		if (this.state.payment_type === PaymentOptions.Account) {
			this.handleCreateBooking();
		} else if (this.state.payment_type === PaymentOptions.Card) {
			this.handleCreateBookingWithPayment();
		}
	}

	//Checkout view
	showCheckoutView() {
		const { handleCloseClick, bookingQuestions } = this.props;
		let totalPrice = this.calculateTotalPrice();
		let payment_option = [
			{ name: 'account', label: 'Account', value: 1 },
			{ name: 'card', label: 'Card', value: 2 }
		];

		return (
			<div className="modal-container">
				<div className="modal-top">
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className="modal-body create">
					{this.loadQuestionsTextField(BookingQuestionFieldType.EventDescription)}
					{this.loadQuestionsTextField(BookingQuestionFieldType.SpecialInstructions)}
					{totalPrice !== 0 ? (
						<div className="content-item">
							<InputComponent
								type="select"
								label="Payment Method"
								options={payment_option}
								handleChange={(e) => this.handlePaymentSelection(e)}
								value={this.state.payment_type.toString()}
							/>
						</div>
					) : (
						''
					)}
				</div>
				<div className="modal-footer">
					{this.state.processing && (
						<div className="progress-spinner">
							{' '}
							<i className={'fa fa-spinner fa-spin resource-arrow '} style={{ fontSize: 24 + 'px' }} />
						</div>
					)}
					<div className="content-item dot-nav">
						<DotNav
							items={!_.isUndefined(this.state.activeScreen) ? this.state.activeScreen.pages : []}
							currentItem={this.state.modalScreen}
						/>
					</div>
					<div className="content-item--double action-btn pad-bottom-x20">
						<Button
							icon="arrow-left-c"
							isDisabled={false}
							handleClick={() => {
								this.setState({
									modalScreen:
										bookingQuestions && bookingQuestions.length > 2
											? ModalScreenType.BookingQuestionScreen
											: ModalScreenType.CreateScreen
								});
							}}
						/>

						<Button
							icon="arrow-right-c"
							isDisabled={
								!this.state.payment_option_selected || !_.isUndefined(this.state.processing) ? (
									true
								) : (
									false
								)
							}
							handleClick={(e) => {
								this.setState({
									processing: true
								});

								this.updateBasketBookingQuestions();
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	//Edit View
	showEditModalView() {
		const { handleCloseClick, selectedSlot, timeStep, view } = this.props;

		return (
			<div className="modal-container ">
				<div
					className={`${'modal-top edit'} ${selectedSlot
						? 'card--' + selectedSlot.card
						: ''} ${selectedSlot.past === true ? 'past' : ''}`}
				>
					<div className="text">
						<label>{this.props.selectedSlot && this.props.selectedSlot.eventName}</label>
					</div>
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className={!this.state.allow_edit || !this.state.field_active ? 'modal-body ' : 'modal-body edit'}>
					<div className="content-item mrg-top-x15">
						<label className="like-h4"> Tenant Name</label>
						<div className="display--text">
							<span>
								{!_.isUndefined(selectedSlot.tenant) ? (
									`${selectedSlot.tenant.firstName} ${selectedSlot.tenant.lastName}`
								) : (
									''
								)}{' '}
							</span>
						</div>
					</div>
					<div className="content-item--double pad-top-x5">
						<div className="date">
							<label className="like-h4"> Starts</label>
							<div className="display--text half">
								<span>
									{selectedSlot.type === ServicesUnit.Minute ? (
										moment(selectedSlot.start).format('HH:mm')
									) : (
										moment(selectedSlot.start).format('DD/MM/YYYY')
									)}{' '}
								</span>
							</div>
						</div>
						<div className="date">
							<label className="like-h4"> Ends</label>
							<div className="display--text half">
								<span>
									{selectedSlot.type === ServicesUnit.Minute ? (
										moment(selectedSlot.end).format('HH:mm')
									) : (
										moment(selectedSlot.end).format('DD/MM/YYYY')
									)}{' '}
								</span>
							</div>
						</div>
					</div>

					{this.loadQuestionsEditTextField(BookingQuestionFieldType.EventDescription, '')}
					{this.loadQuestionsEditTextField(BookingQuestionFieldType.SpecialInstructions, '')}

					<div className="content-item resize-popup">
						<label className="like-h4">Charges</label>

						<div className="content-item--double pad-top-x5 charges">
							{view === calendarViews.Month ? (
								<label>
									{selectedSlot.service_name ? (
										`${selectedSlot.service_name + ' x' + selectedSlot.duration}`
									) : (
										' x' + selectedSlot.duration
									)}
								</label>
							) : (
								<label>
									{selectedSlot.service_name ? (
										`${selectedSlot.service_name + ' x' + selectedSlot.duration / timeStep}`
									) : (
										' x' + selectedSlot.duration / timeStep
									)}
								</label>
							)}

							<div id="total--price--display">
								<span>Total</span>
								<span>£{this.convertToDecimal(selectedSlot.price)}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<div className="content-item dot-nav">
						<DotNav
							items={!_.isUndefined(this.state.activeScreen) ? this.state.activeScreen.pages : []}
							currentItem={this.state.modalScreen}
						/>
					</div>
					<div className="content-item pad-bottom-x20">
						<div className="content-item--double  action-btn">
							<Button icon="arrow-left-c" isDisabled={true} handleClick={() => {}} />
							<Button
								icon="arrow-right-c"
								handleClick={() => {
									this.setState({
										modalScreen: ModalScreenType.EditBookingQuestionScreen
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//Edit Booking questions View
	showEditBookingQuestionsView() {
		const { handleCloseClick, selectedSlot } = this.props;

		let now = new Date();
		return (
			<div className="modal-container">
				<div
					className={`${'modal-top edit'} ${selectedSlot
						? 'card--' + selectedSlot.card
						: ''} ${selectedSlot.past === true ? 'past' : ''}`}
				>
					<div className="text">
						<label>{this.props.selectedSlot && this.props.selectedSlot.eventName}</label>
					</div>
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className={!this.state.allow_edit || !this.state.field_active ? 'modal-body' : 'modal-body edit'}>
					{this.loadQuestionsEditTextField(BookingQuestionFieldType.Other, '')}
				</div>
				<div className="modal-footer fix">
					<div className="content-item">
						{this.state.processing && (
							<div className="progress-spinner">
								{' '}
								<i
									className={'fa fa-spinner fa-spin resource-arrow '}
									style={{ fontSize: 24 + 'px' }}
								/>
							</div>
						)}
						{!_.isUndefined(this.state.success) && (
							<div>
								<p
									className={
										this.state.success === true ? 'message type-success' : 'message type-error'
									}
								>
									{this.state.message}
								</p>
							</div>
						)}
						<Button
							text={this.state.edit_button_text}
							isDisabled={!this.state.allow_edit || this.state.processing}
							handleClick={(e) => {
								if (moment(now).isAfter(moment(selectedSlot.start))) {
									this.setState({
										success: false,
										message: 'Unable to complete request.',
										edit_button_text: EditButtonText.EditReservation,
										cancel_button_text: EditButtonText.CancelReservation,
										field_active: false,
										allow_edit: false,
										edit_status: undefined
									});
									return false;
								} else {
									if (!this.state.field_active) {
										this.setState({
											edit_button_text: EditButtonText.SaveChanges,
											cancel_button_text: EditButtonText.DiscardChanges,
											field_active: true,
											success: undefined,
											message: '',
											edit_status: EditFieldStatus.Editing
										});
									} else {
										this.setState({
											processing: true
										});

										setTimeout(() => {
											this.handleEditBooking();
										}, 0);
									}
								}
							}}
						/>
						<Button
							text={this.state.cancel_button_text}
							isDisabled={moment(now).isAfter(moment(selectedSlot.start)) || this.state.processing}
							handleClick={() => {
								if (moment(now).isAfter(moment(selectedSlot.start))) {
									this.setState({
										success: false,
										message: 'Unable to complete request.',
										cancel_button_text: EditButtonText.CancelReservation,
										edit_button_text: EditButtonText.EditReservation,
										field_active: false,
										allow_edit: false
									});
									return false;
								} else {
									if (this.state.field_active === true) {
										if (this.state.edit_status === EditFieldStatus.Editing) {
											this.setState({
												cancel_button_text: EditButtonText.CancelReservation,
												edit_button_text: EditButtonText.EditReservation,
												field_active: false,
												success: true,
												message: 'Changes discarded',
												edit_status: EditFieldStatus.Discarded
											});

											setTimeout(() => {
												this.setState({
													message: ''
												});
											}, 3000);
										}
									} else {
										this.setState({
											success: undefined,
											message: '',
											modalScreen: ModalScreenType.BookingAttentionWarning
										});
									}
								}
							}}
						/>
					</div>
					<div className="content-item dot-nav">
						<DotNav
							items={!_.isUndefined(this.state.activeScreen) ? this.state.activeScreen.pages : []}
							currentItem={this.state.modalScreen}
						/>
					</div>
					<div className="content-item--double pad-top-x15 pad-bottom-x20">
						<Button
							icon="arrow-left-c"
							handleClick={() => {
								this.setState({
									modalScreen: ModalScreenType.EditScreen
								});
							}}
						/>
						<Button icon="arrow-right-c" isDisabled={true} handleClick={() => {}} />
					</div>
				</div>
			</div>
		);
	}
	//Success View
	showSuccessView() {
		const { handleCloseClick } = this.props;

		return (
			<div className="modal-container">
				<div className="modal-top">
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className="modal-body">
					<div className="content-item">
						<div className="status--icon">
							<div className="icon success" />
						</div>
					</div>
					<div className="content-item">
						<label className="status--success"> Success! </label>
						<span className="status--message">
							{this.props.actionMessage ? this.props.actionMessage : ''}
						</span>
					</div>
				</div>
				<div className="modal-footer fix">
					<div className="content-item pad-top-x15 pad-bottom-x20">
						<Button
							text="Done"
							handleClick={() => {
								handleCloseClick(true);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	//Error View
	showErrorView() {
		const { handleCloseClick } = this.props;

		return (
			<div className="modal-container">
				<div className="modal-top">
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className="modal-body">
					<div className="content-item">
						<div className="status--icon">
							<div className="icon error" />
						</div>
					</div>
					<div className="content-item">
						<label className="status--error"> Oops! </label>
						<span className="status--message">
							{this.props.actionMessage ? (
								this.props.actionMessage
							) : (
								'Unable to complete your request at the moment, Please try again shortly'
							)}
						</span>
					</div>
				</div>
				<div className="modal-footer fix">
					<div className="content-item pad-top-x15 pad-bottom-x20">
						<Button
							text="Done"
							handleClick={() => {
								handleCloseClick(true);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	//Warning View
	showWarningView() {
		const { handleCloseClick, selectedSlot } = this.props;

		let now = new Date();

		return (
			<div className="modal-container">
				<div className="modal-top">
					<div className="icons">
						<span className="ion-close-round close_btn" onClick={() => handleCloseClick(true)} />
					</div>
				</div>
				<div className="modal-body">
					<div className="content-item">
						<div className="status--icon">
							<div className="icon attention" />
						</div>
					</div>
					<div className="content-item">
						<label className="status--attention"> Attention! </label>
						<span className="status--message">
							You’re about to cancel this booking. If you go ahead this action can not be undone.
						</span>
						{selectedSlot && selectedSlot.price > 0 ? this.cancellationValid() === true ? (
							<span className="status--message success">
								This booking can be cancelled free of charge. Do you wish to delete this booking?
							</span>
						) : (
							<span className="status--message warning">
								This booking can’t be cancelled free of charge. Do you wish to delete this booking?
							</span>
						) : (
							''
						)}
					</div>
				</div>
				<div className="modal-footer fix">
					<div className="content-item pad-top-x15 pad-bottom-x20">
						{this.state.processing && (
							<div className="progress-spinner">
								{' '}
								<i
									className={'fa fa-spinner fa-spin resource-arrow '}
									style={{ fontSize: 24 + 'px' }}
								/>
							</div>
						)}
						<Button
							text="Delete Booking"
							isDisabled={moment(now).isAfter(moment(selectedSlot.start))}
							handleClick={() => {
								if (moment(now).isAfter(moment(selectedSlot.start))) {
									this.setState({
										booking_action_valid: false
									});
								} else {
									this.setState({
										processing: true
									});
									setTimeout(() => {
										this.handleCancelBooking();
									}, 0);
								}
							}}
						/>
						<Button
							icon="arrow-left-c"
							handleClick={() => {
								this.setState({
									modalScreen: ModalScreenType.EditBookingQuestionScreen
								});
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { handleCloseClick, getApplicationNode, focus } = this.props;

		const styles = {
			EventPopupStyle: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-around'
			}
		};

		return (
			<AriaModal
				titleText="Booking Details"
				onExit={handleCloseClick}
				getApplicationNode={getApplicationNode}
				underlayStyle={styles.EventPopupStyle}
				underlayClickExits={false}
				escapeExits={false}
				focusDialog={focus}
			>
				<form
					id="modalForm"
					className="modal-wrapper"
					ref="validate"
					onSubmit={(e) => {
						e.preventDefault();
					}}
					data-parsley-errors-container="#validation-messages"
					data-parsley-focus="none"
					data-parsley-validate-if-empty
					method={this.state.payment_type === 2 ? 'POST' : ''}
					action={this.state.payment_type === 2 ? 'https://secure-test.worldpay.com/wcc/purchase' : ''}
				>
					<div className="modal-wrapper">
						{/*********    create modal screen    **************/}
						{this.state.modalScreen === ModalScreenType.CreateScreen ? this.showCreateModalView() : ''}

						{this.state.modalScreen === ModalScreenType.BookingQuestionScreen ? (
							this.showBookingQuestionsView()
						) : (
							''
						)}

						{/*********    checkout modal screen    **************/}
						{this.state.modalScreen === ModalScreenType.CheckOut ? this.showCheckoutView() : ''}

						{/*********    success modal screen    **************/}
						{this.state.modalScreen === ModalScreenType.BookingSuccess ? this.showSuccessView() : ''}

						{/*********    error modal screen    **************/}
						{this.state.modalScreen === ModalScreenType.BookingError ? this.showErrorView() : ''}

						{/*********    Edit modal screen    **************/}
						{this.state.modalScreen === ModalScreenType.EditScreen ? this.showEditModalView() : ''}

						{/*********    booking question modal screen    **************/}
						{this.state.modalScreen === ModalScreenType.EditBookingQuestionScreen ? (
							this.showEditBookingQuestionsView()
						) : (
							''
						)}

						{/************** Delete Modal ********************************/}
						{this.state.modalScreen === ModalScreenType.BookingAttentionWarning ? (
							this.showWarningView()
						) : (
							''
						)}
					</div>
				</form>
			</AriaModal>
		);
	}
}

EventModal.propTypes = {
	/**Screen type value to specify current screen loaded */
	type: PropTypes.string
};

function mapStateToProps(state) {
	return {
		resourceBlocked: resourceSelectors.getResourceBlocked(state),
		servicesSlots: resourceSelectors.getServicesSlots(state),
		basketQueue: resourceSelectors.getBasketQueue(state),
		slots: resourceSelectors.getSelectedEventsSlots(state),
		bookings: resourceSelectors.getBookings(state),
		basketItems: checkoutSelectors.getBasket(state),
		bookingSlot: resourceSelectors.getBlockSlotSelected(state),
		actionType: checkoutSelectors.getActionType(state),
		actionStatus: checkoutSelectors.getActionStatus(state),
		actionMessage: checkoutSelectors.getActionMessage(state),
		serviceUnit: resourceSelectors.getServiceUnit(state)
	};
}

export default connect(mapStateToProps)(EventModal);
