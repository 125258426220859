import * as config from './config';
import authService from './loginService';

class ResidentService {
	async GetResidents() {
		const url = `${config.RESIDENTS_ENDPOINT}`;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getResidents service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}


	async GetConciergeGroups() {
		const url = `${config.RESIDENTS_GROUPS_ENDPOINT}`;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);

		const response = await fetch(url, {
			method: 'GET',
			headers: requestHeader
		});

		if (!response.ok) {
			config.errorPageRedirect(response);
			throw new Error(`getConciergeGroups service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}




	async SearchResidentsBy(data) {
		const url = `${config.SEARCH_RESIDENTS_ENDPOINT}`;
		const authToken = authService.getToken();

		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(data)
		});
		if (!response.ok) {
			 config.errorPageRedirect(response);
			throw new Error(`Search resident by service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async CreateNewResident(data) {
		const url = `${config.CREATE_RESIDENT_ENDPOINT}`;
		const authToken = authService.getToken();
		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(data)
		});

		if (!response.ok) {
			 config.errorPageRedirect(response);
			throw new Error(`create member service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async UpdateResident(data) {
		const url = `${config.UPDATE_MEMBER_ENDPOINT}`;
		const authToken = authService.getToken();
		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(data)
		});

		if (!response.ok) {
			 config.errorPageRedirect(response);
			throw new Error(`update member service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}

	async UpdateApprovalResident(data) {
		const url = `${config.UPDATEAPPROVAL_MEMBER_ENDPOINT}`;
		const authToken = authService.getToken();
		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(data)
		});

		if (!response.ok) {
			 config.errorPageRedirect(response);
			throw new Error(`update approval member service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}
	async sendRegisterationActivation(data) {
		const url = `${config.SEND_REGISTERATION_ACTIVATION_ENDPOINT}`;
		const authToken = authService.getToken();
		var requestHeader = new Headers();
		requestHeader.append('Authorization', 'Bearer ' + authToken);
		requestHeader.append('Accept', 'application/json');
		requestHeader.append('Content-Type', 'application/json');

		const response = await fetch(url, {
			method: 'POST',
			headers: requestHeader,
			body: JSON.stringify(data)
		});

		if (!response.ok) {
			 config.errorPageRedirect(response);
			throw new Error(`send registration activation service failed, HTTP status ${response.status}`);
		}

		const result = await response.json();

		return result;
	}
}

export default new ResidentService();
