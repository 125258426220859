import React, { Component } from "react";
import autobind from "react-autobind";
import { connect } from "react-redux";
import _ from "lodash";
import {withRouter} from 'react-router-dom';
import FooterNavigation from "../../../components/FooterNavigation/FooterNavigation";

import FeatureArea from "../../../components/FeatureArea/FeatureArea";
import ButtonGroup from "../../../components/Button/ButtonGroup";
import InputComponent from "../../../components/Input/Input";
import AddressLookUp from "../../../components/AddrLookUpAutoSuggest/AddrLookUpAutoSuggest";
import * as residentActions from "../../../store/Residents/actions";
import * as residentSelectors from "../../../store/Residents/reducer";
import * as moduleSelectors from "../../../store/ServiceModules/reducer";
import * as addressLookupActions from "../../../store/AddressLookUp/actions";
import * as addressLookupSelector from "../../../store/AddressLookUp/reducer";
import * as $ from "jquery";



class ResidentEditProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "residents",
      residentId: !_.isUndefined(this.props.location.state)
        ? this.props.location.state.residentId
        : "",
      resident: undefined,
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      addresslookupId: undefined,
      residentUpdated: false,
      addressValid: false
    };

    this.props.dispatch(residentActions.getAllResidents());
    this.props.dispatch(addressLookupActions.loadAddresses());
    this.props.dispatch(residentActions.GetConciergeGroups());

    this.addResidentValidation = null;
    autobind(this);
  }


  componentDidMount() {
    this.addValidation();
  }
  componentWillUnmount() {
    this.addResidentValidation.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.residents !== prevProps.residents && this.props.residents) {
      let resi = _.find(
        this.props.residents,
        res => res.id === this.state.residentId
      );

      this.setState({
        resident: resi,
        firstName: !_.isUndefined(resi) ? resi.firstName : "",
        lastName: !_.isUndefined(resi) ? resi.lastName : "",
        address: !_.isUndefined(resi)
          ? resi.addressLine1 + `${resi.addressLine2 ? " " + resi.addressLine2 : ""}`
          : "",
        phone: !_.isUndefined(resi) ? resi.mobileNumber : "",
        email: !_.isUndefined(resi) ? resi.email : "",
        addresslookupId: !_.isUndefined(resi) ? resi.addressLookupId : undefined
      });
    }


    if (
      this.props.residentUpdatedStatus !== prevProps.residentUpdatedStatus && this.props.residentUpdatedStatus &&
      !_.isUndefined(this.props.residentUpdatedStatus.status)
    ) {
      if (this.props.residentUpdatedStatus.status === true) {
        this.setState({
          residentUpdated: this.props.residentUpdatedStatus.status
        });
      } else {
        this.setState({
          residentUpdated: this.props.residentUpdatedStatus.status,
          error: this.props.residentUpdatedStatus.error
        });
      }
    }
  }

  addValidation() {
    this.addResidentValidation = $(this.refs.validate).parsley({
      classHandler: function (el) {
        return el.$element.closest("div:not(.input-wrapper):not(.Select)");
      }
    });
  }

  validate(fromSubmit = false) {
    this.addResidentValidation.validate({ force: true });

    const valid =
      this.addResidentValidation.isValid() && this.state.addressValid;

    // if (!this.state.addressValid) {
    //   this.setState({
    //     error: "Please enter an existing tenant address"
    //   });
    // }

    this.setState({
      addResidentFormValid: valid,
      addResidentFormUsed: true
    });

    if (valid === true && fromSubmit === true) {
      this.formReset();

      let address = _.find(
        this.props.addresses,
        addr => addr.id === this.state.addresslookupId
      );

      let residentData = {
        mobileNumber: this.state.phone,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        addressLine1: address.addr1FlatNumber,
        addressLine2: `${address.addr2BuildingName ? address.addr2BuildingName : ""}`,
        postCode: address.postCode,
        addressLookupId: address.id
      };

      this.props.dispatch(
        residentActions.updateResident(residentData, success => {
          if (success === true) {
            this.returnToProfile();
          }
          //this.props.dispatch(residentActions.getAllResidents());
        })
      );

      this.setState({
        addResidentFormUsed: false
      });
    }
  }

  returnToProfile() {
    this.props.history.push({
      pathname: "/concierge/residents/profile",
      state: {
        residentId: this.state.residentId,
        residentUpdated: this.state.residentUpdated
      }
    });
  }

  formReset() {
    // this.setState({
    //   fname: "",
    //   lname: "",
    //   email: "",
    //   addresslookupId: "",
    //   phone: "",
    //   address: ""
    // });

    // this.refs["addr-auto-suggest"] &&
    //   this.refs["addr-auto-suggest"].getWrappedInstance().reset();

    $(this.refs.validate)
      .get(0)
      .reset();

    this.addResidentValidation.destroy();

    // Reset all the select input components to the placeholder value
    for (let key in this.refs) {
      if (key.indexOf("formSelect") === 0) {
        this.refs[key].reset();
      }
    }

    setTimeout(() => {
      this.addValidation();
    }, 0);
    // setTimeout(function() {
    //   this.returnToProfile();
    //   }.bind(this), 2000);
  }
  handleInputChange(e) {
    let field = e.target.name;
    let value = e.target.value;

    this.setState({
      [field]: value
    });
  }
  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      address,
      addresslookupId,
      addressDefault     
    } = this.state;
    const currentModule = _.find(
      this.props.modules,
      o => o.title === "Residents"
    );

    
    return (
      <div>
        <div className={"container type-resident profile_edit"}>
          <FeatureArea
            type={this.state.type}
            pageHeader="Resident Info"
            areaTagline="Please enter information below"
            areaTitle="Residents"
            overlayColor={currentModule && currentModule.imageOverlayTextColour}
            activeMenu={true}
          />

          <div className="content-wrapper">
            <form
              className="content-wrapper"
              ref="validate"
              onSubmit={e => {
                e.preventDefault();
              }}
              data-parsley-errors-container="#validation-messages"
              data-parsley-focus="none"
            >
              <div className="inputs  two-column">
                <InputComponent
                  label="First Name"
                  name="firstName"
                  placeHolder="first name"
                  handleChange={e => this.handleInputChangee(e)}
                  value={firstName}
                  parsley={{
                    "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
                    "data-parsley-minlength": 2,
                    "data-parsley-required": true,
                    "data-parsley-error-message":
                      "Please enter a valid first name"
                  }}
                />
                <InputComponent
                  label="Last Name"
                  name="lastName"
                  placeHolder="last name"
                  handleChange={e => this.handleInputChange(e)}
                  value={lastName}
                  parsley={{
                    "data-parsley-pattern": "/^[a-z ,.'-]+$/i", // alpha chars only https://stackoverflow.com/questions/23415795/
                    "data-parsley-minlength": 2,
                    "data-parsley-required": true,
                    "data-parsley-error-message":
                      "Please enter a valid last name"
                  }}
                />
              </div>
              <div className="inputs">
                {
                  <AddressLookUp
                    ref="addr-auto-suggest"
                    labelText="Address"
                    placeholder="Search Address"
                    clearableIfValid={true}
                    value={address}
                    defaultAddressId={
                      addresslookupId === "" ? undefined : addresslookupId
                    }
                    onSelected={data => {
                      this.setState({
                        addresslookupId: data.addresslookupId,
                        address: data.address,
                        addressValid: data.valid
                      });
                      setTimeout(() => {
                        this.state.addResidentFormUsed && this.validate();
                      }, 0);
                    }}
                    defaultText={dataVal => {
                      this.setState({
                        addressDefault: dataVal
                      });
                    }}
                  />
                }

                <InputComponent
                  label="Email"
                  name="email"
                  placeHolder="email"
                  handleChange={e => this.handleInputChange(e)}
                  value={email}
                  parsley={{
                    "data-parsley-type": "email",
                    "data-parsley-required": true,
                    //"data-parsley-validate-if-empty": false,
                    "data-parsley-error-message": "Please enter a valid email"
                  }}
                  disabled={true}
                />
                <InputComponent
                  label="Phone"
                  name="phone"
                  placeHolder="phone"
                  handleChange={e => this.handleInputChange(e)}
                  value={_.isEmpty(phone) ? "" : phone}
                  parsley={{
                    //"data-parsley-type": "digits",
                    "data-parsley-validate-if-empty": false,
                    "data-parsley-minlength": 11,
                    "data-parsley-error-message":
                      "Please enter a valid phone number"
                  }}
                />


              </div>

              <div
                className={
                  "validation-messages" +
                  (this.state.addResidentFormValid ? " valid" : " invalid")
                }
                id="validation-messages"
              >
                {this.state.addResidentFormUsed &&
                  this.state.addResidentFormUsed === true &&
                  addressDefault &&
                  !_.isEmpty(addressDefault) &&
                  this.state.addressValid === false && (
                    <p className="message error"> {this.state.error}</p>
                  )}
                <div>
                  {this.props.residentUpdatedStatus &&
                    (this.state.residentUpdated === true ? (
                      <p className="message type-success">Member Updated</p>
                    ) : (
                        this.state.error && (
                          <p className="message error"> {this.state.error}</p>
                        )
                      ))}
                </div>
              </div>
              <ButtonGroup
                sizes={["smedium", "medium"]}
                leftText="Cancel"
                rightText="Save Changes"
                //rightDisabled={this.state.addResidentFormValid === false}
                leftClick={() => {
                  this.props.history.push({
                    pathname: "/",
                    state: { setModule: "Residents" }
                  });
                  // this.props.history.push({
                  //   pathname: "/concierge/residents/profile",
                  //   state: {
                  //     residentId: this.props.location.state.residentId,
                  //     residentUpdated: this.state.residentUpdated
                  //   }
                  // });
                }}
                rightClick={e => {
                  this.validate(true);
                }}
              />
            </form>
          </div>
        </div>
        <FooterNavigation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modules: moduleSelectors.loadModules(state),
    residents: residentSelectors.getResidents(state),
    addresses: addressLookupSelector.getAddresses(state),
    residentUpdatedStatus: residentSelectors.residentUpdatedStatus(state),
    residentGroups: residentSelectors.GetConciergeGroups(state),
  };
}
export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(ResidentEditProfilePage));
