import React from 'react';
import { Route, Switch  } from 'react-router-dom';
import {IndexRoute, Redirect } from 'react-router';
import App from './App';

import AuthArea from './containers/concierge/AuthArea';
import LoginScreen from './containers/LoginScreen';
import LogoutWrapper from './containers/Logout';
import LandingScreen from './containers/LandingScreen';
import CheckInScreen from './containers/concierge/parcel/CheckInScreen';

import AddResidentScreen from './containers/concierge/residents/ResidentCreatePage';
import ResidentViewProfile from './containers/concierge/residents/ResidentProfilePage';
import ResidentArchiveProfile from './containers/concierge/residents/ResidentArchivePage';

import ResidentProfileScreen from './containers/concierge/residents/ResidentProfilePage';
import ResidentProfileEditScreen from './containers/concierge/residents/ResidentEditProfilePage';

import ForgotPassword from './containers/ForgotPassword';
import PasswordRecoverySent from './containers/PasswordRecoverySent';
import PassRecoveryNewPassword from './containers/PassRecoveryNewPassword';
import TermsAndConditions from './containers/TermsAndConditions';

import KeysTestScreen from './containers/concierge/keys/keysTestScreen';
import KeyAssignScreen from './containers/concierge/keys/KeyAssignScreen';
import KeyProfileScreen from './containers/concierge/keys/KeyProfileScreen';
import KeyReturnScreen from './containers/concierge/keys/KeyReturnScreen';

import CheckinVisitorScreen from './containers/concierge/visitors/CheckinVisitorScreen';

import Visitor from './containers/concierge/visitors/Vistor';
import VisitorBooking from './containers/concierge/visitors/VisitorBooking';
import VisitorManagement from './containers/concierge/visitors/VisitorManagement';
import VisitorProfile from './containers/concierge/visitors/VisitorProfile';
import ManagementScreen from './containers/concierge/visitors/ManagementScreen';

import PaymentsScreen from './containers/concierge/PaymentScreens/Payments';

import ParcelRegisterScreen from './containers/concierge/parcel/RegisterScreen';
import ParcelManagementScreen from './containers/concierge/parcel/ParcelManagementScreen';
import ParcelExpectedScreen from './containers/concierge/parcel/ParcelExpectedScreen';
import ParcelInfoScreen from './containers/concierge/parcel/ParcelInfoScreen';

import ErrorScreen401 from './containers/static/ErrorScreen401';
import ErrorScreen404 from './containers/static/ErrorScreen404';
import ErrorScreen403 from './containers/static/ErrorScreen403';
import ErrorScreen500 from './containers/static/ErrorScreen500';
import { lang } from 'moment';


const currentHostname = document.location.hostname;
const isLocalhost = currentHostname.match(/localhost|127\.0\.0\.1/g);
let restrictRoutesTo = false;

if (isLocalhost === null) {
	if (currentHostname.indexOf('concierge') !== -1) {
		restrictRoutesTo = 'concierge';
	} else if (currentHostname.indexOf('visitor') !== -1) {
		restrictRoutesTo = 'visitor';
	}
}

const onRouteChanges = {
	//Function to set the Class on certain routes
	setClass: (previousRoute, nextRoute) => {
		var bodyClass = nextRoute.location.pathname.replace(/\//g, '-').substr(1);
		if (bodyClass === '') {
			bodyClass = 'home';
		}
		document.body.removeAttribute('class');
		document.body.classList.add(bodyClass);
	},
	checkForRedirect: (previousRoute, nextRoute) => {
		// This function is only called on load of 404 page

		// Possible urls for reference
		// http://127.0.0.1:3000/ http://localhost:3000/ http://kirkstallforge.ept-stage.co.uk/ http://kirkstallforge-concierge.ept-stage.co.uk/
		// http://kirkstallforge-visitor.ept-stage.co.uk/ http://concierge.kirkstallforgelife.com http://visitor.kirkstallforgelife.com
		// http://www.kirkstallforgelife.com

		// User landed on 404 by trying to access a page only available on another sub domain

		if (
			isLocalhost === null &&
			restrictRoutesTo !== false &&
			nextRoute.location.pathname !== '' &&
			nextRoute.location.pathname !== '/' &&
			nextRoute.location.pathname.indexOf('/') === 0 &&
			nextRoute.location.pathname.length > 1
		) {
			const firstSubPath = document.location.pathname.split('/').filter((chunk) => {
				return chunk !== '';
			})[0];

			// Switch name from concierge to reception
			const compareWith = restrictRoutesTo === 'concierge' ? 'concierge' : restrictRoutesTo;
			const swap = restrictRoutesTo === 'concierge' ? 'visitor' : 'concierge';

			if (
				(firstSubPath === 'concierge' || firstSubPath === 'visitor') &&
				firstSubPath !== compareWith &&
				document.location.origin.indexOf(restrictRoutesTo) !== -1
			) {
				window.location.replace(
					document.location.origin.replace(restrictRoutesTo, swap) + document.location.pathname
				);
			}
		}
	}
};

const onChange = (previousRoute, nextRoute) => {
	onRouteChanges.setClass(previousRoute, nextRoute);
};

function APP_(){
	return <App />;
}
function AuthA(){
	return <AuthArea />;
}
function Landing(){
	return <LandingScreen />;
}

function ParcelManagement(){
	return <ParcelManagementScreen />;
}
let rI = 0;

function getScreenAction(type){
	switch(type){
		case '/' : 
		return <LandingScreen />
		case '/concierge/parcel/management' : 
		return <ParcelManagementScreen />;
		default : 
		return null;
	}
}
export default (
	
	<Route path="/" component={APP_} onChange={onChange} onInit={onRouteChanges}>
		<Route path="/login">
		<LoginScreen />
		</Route>
		<AuthArea>
		<Switch>
			<Route path="/" component={LandingScreen} exact/>
			<Route path="/concierge/parcel/register" component={ParcelRegisterScreen} />
			<Route path="/concierge/parcel/management" component={ParcelManagementScreen} exact/>
			<Route path="/concierge/parcel/expected" component={ParcelExpectedScreen} />
			<Route path="/concierge/parcel/info" component={ParcelInfoScreen} />
			<Route path="/concierge/parcel/checkin" component={CheckInScreen} />

			<Route path="/concierge/residents/addnew" component={AddResidentScreen} />
			<Route path="/concierge/residents/view" component={ResidentViewProfile} />
			<Route path="/concierge/residents/profile" component={ResidentProfileScreen} />
			<Route path="/concierge/residents/profileinfo" component={ResidentProfileEditScreen} />
			<Route path="/concierge/residents/archive_profile" component={ResidentArchiveProfile} />
			<Route path="/concierge/keys/keytest" component={KeysTestScreen} />

			<Route path="/concierge/keys/assign" component={KeyAssignScreen} />

			<Route path="/concierge/keys/profile" component={KeyProfileScreen} />

			<Route path="/concierge/keys/return" component={KeyReturnScreen} />
			<Route path="/concierge/bookings/payment" component={PaymentsScreen} />
			{/* Visitors Journey  */}

			<Route path="/concierge/visitors" component={CheckinVisitorScreen} />

			<Route path="/concierge/visitor-express-checkin" component={Visitor} />

			<Route path="/concierge/visitor-booking" component={VisitorBooking} />

			<Route path="/concierge/visitor-management" component={VisitorManagement} />

			<Route path="/concierge/visitor-profile" component={VisitorProfile} />

			<Route path="/concierge/management" component={ManagementScreen} />

			<Route path="/401" component={ErrorScreen401} onInit={onRouteChanges} />
			<Route path="/404" component={ErrorScreen404} onInit={onRouteChanges} />
			<Route path="/403" component={ErrorScreen403} onInit={onRouteChanges} />
			<Route path="/500" component={ErrorScreen500} onInit={onRouteChanges} />
			</Switch>
		</AuthArea>
		

		<Route path="/logout" component={LogoutWrapper}  exact />
		<Route path="/forgotpassword" component={ForgotPassword}  exact />
		<Route path="/passwordrecoverysent" component={PasswordRecoverySent}  exact />

		<Route path="/termsandconditions" component={TermsAndConditions}  exact />

		<Route path="/passrecoverynewpassword" component={PassRecoveryNewPassword}  exact />
	</Route>
	
	);
