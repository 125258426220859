import React, { Component } from 'react';
import _ from 'lodash';
import '../../sass/components/Button/index.css';
import PropTypes from 'prop-types';
/**
 * A general purpose button for use throughout the project, where more than one button is required, consider the button group component
 */

class Button extends Component {
	render() {
		const { text, size, handleClick, icon, otherClass, id, style } = this.props;

		const disabled = _.isUndefined(this.props.isDisabled) || this.props.isDisabled === false ? false : 'disabled';

		return (
			<button
				id={id}
				type="button"
				className={
					'btn ' +
					(size ? size : '') +
					' ' +
					(_.isUndefined(otherClass) ? '' : otherClass) +
					(_.isUndefined(icon) ? '' : _.isUndefined(icon) ? '' : ' ion-' + icon)
				}
				onClick={handleClick}
				disabled={disabled}
				style={style && style}
			>
				{text}
			</button>
		);
	}
}

Button.propTypes = {
	/** The size of the button, small medium or large*/
	size: PropTypes.string,
	/** Click function specification of the button */
	handleClick: PropTypes.func,
	/** For exceptions to the rule */
	otherClass: PropTypes.string,
	/** Text displayed on the button*/
	text: PropTypes.string,
	/** Any icons displayed on the button*/
	icon: PropTypes.string,
	/** Links the button connects to*/
	link: PropTypes.string,
	/** If the button is disabled */
	isDisabled: PropTypes.bool,
	/** If the button needs an id */
	id: PropTypes.string
};

export default Button;
