import * as types from './actionTypes';

import VisitorServices from '../../services/VisitorServices';
import MeetingServices from '../../services/MeetingServices.js';
import PageEvent from '../../services/PegeEvent';
//import * as EmailValidator from "email-validator";
import * as visitorSelectors from './reducer';
import * as config from '../../services/config';
import _ from 'lodash';

export function getVistorByEmail(email) {
	return async (dispatch, getState) => {
		try {
			const result = await VisitorServices.searchVisitorByEmail(email);
			dispatch({ type: types.VISITOR_RECORD_LOADED_SUCCESS, result });
			console.log(result);
		} catch (error) {
			console.error(error);
		}
	};
}

export function searchVisitor(visitor, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const data = visitor.name;

			const element = data.split(' ').filter((part) => {
				return part !== '';
			}); // precaution in case user put in two spaces

			const visitorData = {
				firstName: '',
				surname: '',
				email: '',
				companyName: visitor.companyName,
				status: visitor.status,
				dueDate: visitor.dueDate
			};

			if (element.length > 1) {
				visitorData.firstName = element[0];
				visitorData.surname = element[1];

				const result = await VisitorServices.searchByVisitor(visitorData);

				if (result.length > 0) {
					dispatch({ type: types.VISITOR_RECORD_LOADED_SUCCESS, result });
					dispatch(selectVisitor(result));

					var meetingsId = result.map((item, i) => {
						var data = { Id: item.meetingId };
						return data;
					});
					var meeting = await MeetingServices.getMeetingsById(meetingsId);
					dispatch({ type: types.VISITOR_MEETING_LOADED_SUCCESS, meeting });
				} else {
					dispatch({ type: types.VISITOR_RECORD_LOADED_FAIL, result });
				}

				callback(true);
			} else {
				callback(false);

				//   visitorData.email = EmailValidator.validate(element[0])
				//     ? element[0]
				//     : "";
			}
		} catch (error) {
			callback(false);

			console.error(error);
		}
	};
}

export function getVisitorMeetingById(id) {
	return async (dispatch, getState) => {
		try {
			const result = await MeetingServices.getMeetingById(id);
			dispatch({ type: types.VISITOR_MEETING_LOADED_SUCCESS, result });
		} catch (error) {
			console.error(error);
		}
	};
}

export function checkinVisitor(visitor, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			var visitorCheckedIn = await MeetingServices.checkinVisitor(visitor);

			if (visitorCheckedIn && visitorCheckedIn.length > 0) {
				dispatch({
					type: types.VISITOR_SELF_CHECKIN_SUCCESS,
					visitorCheckedIn
				});

				callback(true);
			} else {
				callback(false);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
export function checkinStatusSuccess(visitor) {
	return async (dispatch, getState) => {
		try {
			dispatch({
				type: types.VISITOR_SELF_CHECKIN_SUCCESS,
				visitor
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function checkOutVisitor(value, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			if (value && value.length > 0) {
				var visitor = value.map((item, i) => {
					//item.status = "checkedout";
					return item;
				});

				var visitorCheckedOut = await MeetingServices.checkOutVisitor(visitor);

				dispatch({
					type: types.UPDATE_CHECKOUT_STATUS_SUCCESS,
					visitorCheckedOut
				});
				callback(true);
			} else {
				callback(false);
			}
		} catch (error) {
			callback(false);
			console.error(error);
		}
	};
}
export function allVisitors() {
	return async (dispatch, getState) => {
		try {
			var result = await VisitorServices.getAllVisitors();
			if (result.length > 0) {
				dispatch({
					type: types.VISITORS_RECORDS_LOADED_SUCCESS,
					result
				});
			} else {
				dispatch({
					type: types.VISITORS_RECORDS_LOADED_FAIL,
					result
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
}

export function getVisitorByName(visitor) {
	return async (dispatch, getState) => {
		try {
			const data = visitor.name;
			const element = data.split(' ');
			const visitorData = {
				firstName: '',
				surname: '',
				email: '',

				status: visitor.status
			};
			if (element.length > 1) {
				visitorData.firstName = element[0];
				visitorData.surname = element[1];
			} else {
			}

			const result = await VisitorServices.searchVisitorByName(visitorData);

			dispatch({ type: types.VISITOR_RECORD_LOADED_SUCCESS, result });
			dispatch(selectVisitor(result));

			var meetingsId = result.map((item, i) => {
				var data = { Id: item.meetingId };
				return data;
			});
			var meeting = await MeetingServices.getMeetingsById(meetingsId);
			dispatch({ type: types.VISITOR_MEETING_LOADED_SUCCESS, meeting });
			if (result.length === 0) {
				dispatch({ type: types.VISITOR_RECORD_LOADED_FAIL, result });
			}
		} catch (error) {
			console.error(error);
		}
	};
}

export function selectVisitor(visitor) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.VISITOR_SELECTED, visitor });
		} catch (error) {
			console.error(error);
		}
	};
}

export function getVisitorSearchInput(visitorSearchInput) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: types.VISITOR_SEARCH_DATA, visitorSearchInput });
		} catch (error) {
			console.error(error);
		}
	};
}

export function setVisitorEvent(event) {
	return async (dispatch, getState) => {
		try {
			setTimeout(() => {
				PageEvent.setPageEvent(event);
			}, 0);

			let pg_event = PageEvent.getPageEvent();

			dispatch({
				type: types.VISITOR_EVENT,
				visitorEvent: pg_event
			});
		} catch (error) {
			console.error(error);
		}
	};
}

export function getPageEvent() {
	return async (dispatch, getSatate) => {
		let pg_event = PageEvent.getPageEvent();

		dispatch({
			type: types.VISITOR_EVENT,
			visitorEvent: pg_event
		});
	};
}

export function checkinById(id) {
	return async (dispatch, getState) => {
		try {
			var visitor = await VisitorServices.checkinVisitorById(id);
			if (visitor) {
				dispatch({
					type: types.VISITOR_CHECKIN_SUCCESS,
					visitor
				});
			} else {
				dispatch({
					type: types.VISITOR_CHECKIN_FAIL,
					visitor
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
}
export function checkoutById(id) {
	return async (dispatch, getState) => {
		try {
			var visitor = await VisitorServices.checkOutVisitorById(id);

			if (visitor) {
				dispatch({
					type: types.VISITOR_CHECKOUT_SUCCESS,
					visitor
				});
			} else {
				dispatch({
					type: types.VISITOR_CHECKOUT_FAIL,
					visitor
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
}

export function cancelMeetingLoad() {
	let meeting = [];
	return { type: types.CANCEL_MEETING_LOAD, meeting };
}

export function setVisitors(visitors) {
	return async (dispatch, getState) => {
		dispatch({ type: types.VISITORS_RECORDS_LOADED_SUCCESS, visitors });
	};
}

export function setCarReg(carReg) {
	return async (dispatch, getState) => {
		dispatch({ type: types.VISITOR_CAR_REG_SET, carReg });
	};
}

export function getSelectedVisitor(visitorId) {
	return async (dispatch, getState) => {
		try {
			const visitors = visitorSelectors.getVisitors(getState());
			const visitor = _.find(visitors, { id: visitorId });

			dispatch({ type: types.VISITOR_RECORD_LOADED_SUCCESS, visitor });
		} catch (error) {
			console.error(error);
		}
	};
}

export function addParkingDetails(data, success = () => {}) {
	return async (dispatch, getState) => {
		try {
			var result = await VisitorServices.addCarRegNumber(data);
			dispatch({ type: types.VISITOR_CAR_REG_UPDATED, result });
			success(true);
		} catch (error) {
			success(false);
			console.error(error);
		}
	};
}

export function setSpecialInstructions(value) {
	return (dispatch, getState) => {
		dispatch({
			type: types.VISITOR_SPECIAL_INSTRUCTIONS,
			specialInstructions: value
		});
	};
}

export function getVisitorById(id) {
	return async (dispatch, getState) => {
		try {
			const visitor = await VisitorServices.searchVisitorById(id);
			dispatch({ type: types.VISITOR_RECORD_LOADED_SUCCESS, visitor: visitor });
		} catch (error) {
			console.error(error);
		}
	};
}

export function getVisitorStauses() {
	let statuses = config.visitorStatuses;

	return statuses;
}

export function getNotifiledVisitorsByDate(date, callback = () => {}) {
	return async (dispatch, getState) => {
		try {
			const response = await VisitorServices.getVisitorsExpectedByDate(date);
			dispatch({ type: types.VISITORS_RECORDS_LOADED_SUCCESS, visitors: response });
		} catch (error) {
			callback(false);
			dispatch({ type: types.VISITORS_LOADED_FAIL, error });
			console.error(error);
		}
	};
}

export function storeVisitorSearch(search_data, callback = () => {}) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.VISITOR_SEARCH_STORED_SUCCESSFULLY, data: search_data });
		} catch (error) {}
	};
}

export function visitorSearchRedirected(val) {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.VISITOR_SEARCH_FROM_REDIRECT, status: val });
		} catch (error) {}
	};
}

export function resetVisitorCheckedIn() {
	return (dispatch, getState) => {
		try {
			dispatch({ type: types.VISITOR_CHECKEDIN_RESET_SUCCESSFULLY });
		} catch (error) {}
	};
}
