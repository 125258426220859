import React, { Component } from "react";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";
import FooterNavigation from "../../components/FooterNavigation/FooterNavigation";
import Button from "../../components/Button/Button";
import { createBrowserHistory } from 'history';

import "../../sass/containers/ErrorScreen/index.css";

const browserHistory = createBrowserHistory();
const location = browserHistory.location;
class ErrorScreen404 extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {

        this.props.route.onInit.checkForRedirect(false, { location: { pathname: document.location.pathname } });

        // Timeout to overwrite default call to setClass in App component
        setTimeout(() => {

            this.props.route.onInit.setClass(false, { location: { pathname: '-404-error-page' } });

        }, 0);
    }

    render() {

        return (
            <div>


                <div className="container transparent type-plain-header-block">
                    <div style={{ width: 100 + "%", height: 272 + "px" }}>

                    </div>
                    <h1 className="align-center enlarged">#404</h1>

                </div>

                <div className="white-background">
                    <div className="container">

                        <div className="content-wrapper">

                            <h2>It Looks Like You're Lost</h2>

                            <h3 className="brownish-grey align-center">The page you’re looking for does not exist.</h3>

                        </div>

                        <div className="align-center">
                            <Button
                                handleClick={() => { browserHistory.push("/"); }}
                                text="Back To Home"
                                size="medium" />
                        </div>



                        <FooterNavigation />
                    </div>
                </div>

            </div>


        );
    }
}

export default ErrorScreen404;
